import { Box, Checkbox, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import { useEffect, useState } from "react";
import { API_GET2, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { useCompanyId } from "../../../Context/CompanyIdContext";
let PAGE_SIZE_OPTIONS = [15];
const CustomerReviewsTab = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { companyId } = useCompanyId();
  const [IsLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);

  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowId",
      headerName: "#",
      width: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile",
      headerName: `${t("ownerProfile.review.grid.Profile")}`,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {/* Render profile image */}
          <img
            src={API_endpoints.baseURL + params?.row?.user_details?.profile_img}
            alt="Profile"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%", // Optional: display as circle
              objectFit: "cover",
            }}
          />
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: `${t("ownerProfile.review.grid.name")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => cell?.row?.user_details?.full_name,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "review",
      headerName: `${t("ownerProfile.review.grid.Review")}`,
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "is_display",
      headerName: `${t("ownerProfile.review.grid.diplay")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        // <Box
        //   variant="contained"
        //   color="primary"
        //   onClick={() => alert(`Displaying review for ${params.row.name}`)}
        //   sx={{
        //     "&:hover": {
        //       color: "#F57C00",
        //       textDecoration: "underline",
        //       transition: "all 0.1s linear",
        //     },
        //   }}
        // >
        //   View
        // </Box>{

        <Checkbox
          onChange={() =>
            ownerAction(params?.row?.id, params?.row?.is_display === 1 ? 0 : 1)
          }
          checked={params?.row?.is_display === 1 ? true : false}
        />
      ),
    },
  ];

  const fetchData = async (page = 0, pageSize = PAGE_SIZE_OPTIONS[0]) => {
    try
    {
      setIsLoading(true);
      let count = page * pageSize;
      const res = await API_GET2(
        `${API_endpoints.getOwnerRating}?count=${count}&offset=15&company_id=${companyId}`
      );
      if (res?.s)
      {
        const rowData = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowData);
        setTotalData(res?.c ?? 0);
      } else
      {
        setData([]);
        setTotalData(0);
      }
      setIsLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsLoading(false);
    }
  };

  const ownerAction = async (id, is_display) => {
    try
    {
      const res = await API_POST(API_endpoints.RatingReviewAction, {
        id,
        is_display,
      });
      res?.s && fetchData();
    } catch (error)
    {
      console.log(error);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.review.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box sx={{ height: "400px" }}>
        <Box sx={{ height: "370px" }}>
          <DataListingComponent
            data={data ?? []}
            PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
            loading={IsLoading}
            totalData={totalData}
            page={page}
            Footer={true}
            columns={column}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerReviewsTab;
