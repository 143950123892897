import { Box, Typography } from "@mui/material";
import ChatHeader from "./ChatHeader";
import MessageHandler from "./MessageHandler";
import ChatFooter from "./ChatFooter";
import selectImg from "../../../Assets/images/chat.svg";

const ChatMessageComponent = ({
  selectedChatFirebaseId,
  selectedChatUserId,
  selectedChatUserDetails,
  setSelectedChatFirebaseId,
  setSelectedChatUserId,
  setSelectedChatUserDetails,
}) => {
  const myId = JSON.parse(localStorage.getItem("Fiesta"))?.id;
  return (
    <Box
      className="flexCenter"
      sx={{
        width: "100%",

        flexDirection: "column",
        gap: 1,
        background: "#F5F5F5",
        height: "100%",
        p: 1,
        borderRadius: "7px",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          mx: 1,
          background: "white",
          borderRadius: "7px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          p: 2,
        }}
      >
        {selectedChatFirebaseId ? (
          <>
            <ChatHeader
              selectedChatUserDetails={selectedChatUserDetails}
              setSelectedChatFirebaseId={setSelectedChatFirebaseId}
              setSelectedChatUserId={setSelectedChatUserId}
              setSelectedChatUserDetails={setSelectedChatUserDetails}
              selectedChatFirebaseId={selectedChatFirebaseId}
            />
            <MessageHandler
              selectedChatFirebaseId={selectedChatFirebaseId}
              myId={Number(myId)}
              selectedChatUserDetails={selectedChatUserDetails}
            />
            <ChatFooter
              selectedChatFirebaseId={selectedChatFirebaseId}
              myId={myId}
              selectedChatUserId={selectedChatUserId}
            />
          </>
        ) : (
          <>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                flexDirection: "column",
              }}
              className="flexCenter"
            >
              <img
                src={selectImg}
                alt=""
                style={{ height: "90px", width: "90px" }}
              />
              <Typography sx={{ color: "grey" }}>
                Please select chat to continue

              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatMessageComponent;
