import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  CircularProgress,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DragandDrop from "../../../Component/ImageUploader/DragandDrop";
import { API_GET, API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import i18next from "i18next";

const Step2 = forwardRef((props, ref) => {
  let compID = localStorage.getItem("Fiesta_compID");
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [currentType, setcurrentType] = useState("0");
  const [image, setImage] = useState();
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);
  const { t } = useTranslation();
  const catererRef = useRef(null);
  const catererIdRef = useRef(null);

  const [loading, setLoading] = useState(true);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id
  compID = parseData?.companyId

  const initial1 = {
    caterer_type: Number(currentType),
    about: "",
    company_id: Number(compID),
  };

  const initial2 = {
    ...initial1,
    truck_name: "",
    address: "",
    truck_cat_id: 0,
    // specifications: "",
    meal: [],
    diet: [],
    zip_code: "",
    description: "",
    size: "",
    electricity_type: "",
    operating_mode: "",
    is_water_required: null,
    image: null,
    work_area_radius: 0,
  };

  const validationSchema1 = Yup.object({
    caterer_type: Yup.string().required("Required"),
    about: Yup.string().required("Required"),
  });

  const validationSchema2 = Yup.object({
    caterer_type: Yup.string().required(t("Login.required")),
    about: Yup.string().required(t("Login.required")),
    image: Yup.string().required(t("Login.required")),

    truck_name: Yup.string().required(t("Login.required")),

    address: Yup.string()
      .required(t("Login.required"))
      .max(200, t("Login.required") + ": Maximum 200 characters"),

    truck_cat_id: Yup.number()
      .required(t("Login.required"))
      .notOneOf([0], t("Login.required")),

    // specifications: Yup.string().required(t("Login.required")),
    meal: Yup.array()
      .min(1, t("bookNowForm.required"))
      .required(t("bookNowForm.required")),
    diet: Yup.array()
      .min(1, t("bookNowForm.required"))
      .required(t("bookNowForm.required")),
    zip_code: Yup.string().required(t("Login.required")),
    work_area_radius: Yup.number()
      .required(t("Login.required"))
      .test("not-zero", t("Login.required"), (value) => value !== 0),

    description: Yup.string()
      .required(t("Login.required"))
      .max(500, ": Maximum 500 characters"),

    size: Yup.number()
      .required(t("Login.required"))
      .min(0, t("Login.required")),

    electricity_type: Yup.string().required(t("Login.required")),

    operating_mode: Yup.string().required(t("Login.required")),

    is_water_required: Yup.number().required(t("Login.required")),
  });

  const handleUserData = async () => {
    setLoading(true);
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);

      if (res?.s && res?.r?.id)
      {
        formik.setFieldValue("company_id", res?.r?.company_details?.id);
        localStorage.setItem("Fiesta_compID", res?.r?.company_details?.id);
        // setcurrentType(res?.r?.company_details?.caterer?.caterer_type);
        catererRef.current = res?.r?.company_details?.caterer?.caterer_type;
        formik.setFieldValue(
          "caterer_type",
          res?.r?.company_details?.caterer?.caterer_type
        );

        formik.setFieldValue("id", res?.r?.company_details?.caterer?.id);
        catererIdRef.current = res?.r?.company_details?.caterer?.id;
        formik.setFieldValue("about", res?.r?.company_details?.caterer?.about);

        if (res?.r?.company_details?.caterer?.caterer_type == 2)
        {
          const FC_Details = res?.r?.company_details?.food_truck[0];
          if (FC_Details)
          {
            formik.setFieldValue("truck_name", FC_Details?.truck_name);
            formik.setFieldValue("address", FC_Details?.address);
            // formik.setFieldValue("specifications", FC_Details?.specifications);
            formik.setFieldValue("truck_cat_id", FC_Details?.truck_cat_id);
            formik.setFieldValue("zip_code", FC_Details?.zip_code);
            formik.setFieldValue(
              "meal",
              FC_Details?.meal?.map((x) => x?.status === 1 && x?.meal_id)
            );
            formik.setFieldValue(
              "diet",
              FC_Details?.diet?.map((x) => x?.status === 1 && x?.diet_id)
            );
            formik.setFieldValue(
              "image",
              FC_Details?.food_truck_image[0]?.image
            );
            formik.setFieldValue(
              "FoodTruckImageId",
              FC_Details?.food_truck_image[0]?.id
            );

            formik.setFieldValue("foodTruckId", FC_Details?.id);

            formik.setFieldValue("description", FC_Details?.description);
            formik.setFieldValue("size", FC_Details?.size);
            formik.setFieldValue(
              "electricity_type",
              FC_Details?.electricity_type
            );
            formik.setFieldValue("operating_mode", FC_Details?.operating_mode);
            formik.setFieldValue(
              "is_water_required",
              FC_Details?.is_water_required
            );
            formik.setFieldValue(
              "work_area_radius",
              FC_Details?.work_area_radius
            );
            setImage(`${FC_Details?.food_truck_image[0]}`);
          }
        }
      }
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const handleCategory = async () => {
    try
    {
      let res = await API_GET(API_endpoints.gettruckcat);
      if (res.s)
      {
        setCategories(res.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategory();
  }, []);

  const formik = useFormik({
    initialValues: currentType == 2 ? initial2 : initial1,
    validationSchema: currentType == 2 ? validationSchema2 : validationSchema1,
    enableReinitialize: true,

    onSubmit: async (values) => {
      try
      {
        let res;
        if (catererRef.current != values.caterer_type && !values?.id)
        {
          setLoading(true);
          // --------------- Add code ----------------------

          res = await API_POST(API_endpoints.addcaterer, values);
          if (res && res.r && res.r.id)
          {
            if (values.caterer_type == 2)
            {
              // Add truck API
              let res2 = await API_POST(API_endpoints?.addtruck, values);
              if (res2 && res2.r && res2?.r?.id)
              {

                const mealData = new FormData()
                mealData.append('truck_id', res2?.r?.id)
                mealData.append('meal_ids', values.meal)
                const dietData = new FormData()
                dietData.append('truck_id', res2?.r?.id)
                dietData.append('diet_ids', values.diet)
                let mealResponse = await API_POST(
                  API_endpoints?.addUpdateMealForFoodTruck, mealData
                );
                let dietRepsonse = await API_POST(
                  API_endpoints?.addUpdateDietForFoodTruck, dietData
                );
                const d = new FormData();
                d.append("truck_id", res2?.r?.id);
                d.append("image", image);
                // Add truck image API
                let dataRes = await API_POST(API_endpoints.addtruckimage, d);
                setLoading(false);
              }
            }
          }
        } else if (catererRef.current == values.caterer_type)
        {
          setLoading(true);

          // ---------------------------Update code--------------------------
          res = await API_POST(API_endpoints.updatecaterer, {
            ...values,
            id: values?.id ? values?.id : catererIdRef.current,
          });
          if (res.s && values.caterer_type == 2)
          {
            let res2 = await API_POST(API_endpoints?.updateTruck, {
              ...values,
              id: values?.foodTruckId,
            });
            const mealData = new FormData()
            mealData.append('truck_id', res2?.r?.id)
            mealData.append('meal_ids', values.meal)
            const dietData = new FormData()
            dietData.append('truck_id', res2?.r?.id)
            dietData.append('diet_ids', values.diet)
            if (res2 && res2?.r && res2?.r?.id)
            {
              let mealResponse = await API_POST(
                API_endpoints?.addUpdateMealForFoodTruck, mealData
              );
              let dietRepsonse = await API_POST(
                API_endpoints?.addUpdateDietForFoodTruck, dietData
              );
              if (image?.name)
              {
                const d = new FormData();
                d.append("id", values.FoodTruckImageId);
                d.append("image", image);
                await API_POST(API_endpoints.updatetruckimage, d);
                setLoading(false);
              }
            }
          }
          setLoading(false);
        }
        setLoading(false);
        if (res?.s)
        {
          return true;
        } else
        {
          return false;
        }
      } catch (error)
      {
        console.error("Error:", error);
        return false;
      }
    },
  });

  function handleTypeChange(value) {
    setcurrentType(value);
  }

  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  // meals and diet

  const handleCheckboxChange = (type) => (value) => (event) => {
    const { checked } = event.target;
    const updatedArray = checked
      ? [...formik.values[type], value]
      : formik.values[type].filter((item) => item !== value);

    formik.setFieldValue(type, updatedArray);
  };

  const renderCheckbox = (labelKey, value, type, key) => (
    <FormControlLabel
      key={key}
      control={
        <Checkbox
          id={labelKey}
          checked={formik?.values[type]?.includes(value)}
          onChange={handleCheckboxChange(type)(value)}
          //   onBlur={() => formik.setFieldTouched(type, true)} // Manually trigger onBlur for Formik
          sx={{
            color: theme.palette.text.secondary,
            "&.Mui-checked": {
              color: theme.palette.text.active,
            },
          }}
        />
      }
      label={labelKey}
    />
  );

  const renderMealCheckboxes = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 4 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {meal.length &&
          meal?.map((x, i) =>
            renderCheckbox(
              i18next.language === "de" ? x?.german : x?.meal_name,
              x?.id,
              "meal",
              i
            )
          )}
      </Box>
    </Box>
  );

  const renderDietCheckboxes = () => (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {diet.length &&
        diet?.map((x, i) =>
          renderCheckbox(
            i18next.language === "de" ? x?.german : x?.diet_name,
            x?.id,
            "diet",
            i
          )
        )}
    </Box>
  );

  const handlemeal = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getmealcat);
      if (res?.s)
      {
        setMeal(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const handlediet = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getdietcat);
      if (res?.s)
      {
        setDiet(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handlemeal();
    handlediet();
  }, [i18next.language]);

  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step2.title")}
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          height: loading ? "200px" : "",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "#f57c00" }} />
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              <Box sx={{ width: "100%" }}>
                <StarSVG /> {t("becomePartner.step2.types.title")}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="caterer_type"
                  id="caterer_type"
                  value={formik.values.caterer_type}
                  onChange={(e) => {
                    handleTypeChange(e.target.value);
                    formik.setFieldValue("caterer_type", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={"1"}
                    name="caterer_type"
                    // disabled={formik?.values?.id}
                    control={<Radio value={"1"} name="caterer_type" />}
                    label={`${t("becomePartner.step2.types.type1")}`}
                  />
                  <FormControlLabel
                    value={"2"}
                    name="caterer_type"
                    // disabled={formik?.values?.id}
                    control={<Radio value={"2"} name="caterer_type" />}
                    label={`${t("becomePartner.step2.types.type2")}`}
                  />
                  <FormControlLabel
                    value={"3"}
                    name="caterer_type"
                    // disabled={formik?.values?.id}
                    control={<Radio value={"3"} name="caterer_type" />}
                    label={`${t("becomePartner.step2.types.type3")}`}
                  />
                  <FormControlLabel
                    value={"4"}
                    name="caterer_type"
                    // disabled={formik?.values?.id}
                    control={<Radio value={"4"} name="caterer_type" />}
                    label={`${t("becomePartner.step2.types.type4")}`}
                  />
                </RadioGroup>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG /> {t("becomePartner.step2.yourSelf.title")}
                </Box>

                <TextField
                  type="text"
                  id="about"
                  name="about"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about}
                  error={formik.touched.about && Boolean(formik.errors.about)}
                  helperText={formik.touched.about && formik.errors.about}
                  variant="outlined"
                  placeholder={t("becomePartner.step2.yourSelf.pl")}
                  multiline
                  fullWidth
                  rows={5}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: currentType == 2 ? "block" : "none" }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "24px",
                    color: theme.palette.text.active,
                  }}
                >
                  {t("becomePartner.step2.foodTruckDetails.title")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 3 },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />
                    {t("becomePartner.step2.foodTruckDetails.image.title")}
                  </Box>
                  <DragandDrop
                    setImage={setImage}
                    setFormikImage={() => {
                      formik.setFieldValue("image", "ok");
                    }}
                    apiPreview={
                      typeof formik.values.image === "string"
                        ? API_endpoints.baseURL + formik.values.image
                        : null
                    }
                  />
                  {formik.errors.image && formik.dirty && (
                    <Typography color="error" variant="body2">
                      {formik.errors.image}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: { xs: 2, sm: 2 },
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box py={1}>
                      <StarSVG />{" "}
                      {t(
                        "becomePartner.step2.foodTruckDetails.truckName.title"
                      )}
                    </Box>

                    <TextField
                      type="text"
                      id="truck_name"
                      name="truck_name"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.truck_name}
                      error={
                        formik.touched.truck_name &&
                        Boolean(formik.errors.truck_name)
                      }
                      helperText={
                        formik.touched.truck_name && formik.errors.truck_name
                      }
                      variant="outlined"
                      placeholder={t(
                        "becomePartner.step2.foodTruckDetails.truckName.pl"
                      )}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box py={1}>
                      <StarSVG />
                      {t("becomePartner.step2.foodTruckDetails.address.title")}
                    </Box>

                    <TextField
                      type="text"
                      id="address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      variant="outlined"
                      placeholder={t(
                        "becomePartner.step2.foodTruckDetails.address.pl"
                      )}
                      multiline
                      fullWidth
                      rows={4}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 3 },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.category.title")}
                  </Box>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.truck_cat_id &&
                      Boolean(formik.errors.truck_cat_id)
                    }
                    sx={{
                      color: theme.palette.text.defaultDark,
                      textAlign: "start",
                      borderRadius: "12px",
                    }}
                  >
                    <Select
                      fullWidth
                      id="truck_cat_id"
                      name="truck_cat_id"
                      value={+formik.values.truck_cat_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: theme.palette.text.select_input,
                        borderRadius: "12px",
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                        "&:active": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                      }}
                    >
                      <MenuItem
                        style={{
                          color: theme.palette.text.defaultDark,
                          display: "none",
                        }}
                        value={0}
                      >
                        {t(
                          "becomePartner.step2.foodTruckDetails.category.title"
                        )}
                      </MenuItem>

                      {Array.isArray(categories) &&
                        categories?.length > 0 &&
                        categories?.map((e, i) => (
                          <MenuItem
                            style={{ color: theme.palette.text.defaultDark }}
                            key={i}
                            value={e.id}
                          >
                            {e.truck_category}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.truck_cat_id &&
                      formik.errors.truck_cat_id && (
                        <FormHelperText>
                          {formik.errors.truck_cat_id}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
                {/* <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t(
                      "becomePartner.step2.foodTruckDetails.specification.title"
                    )}
                  </Box>
                  <TextField
                    type="text"
                    id="specifications"
                    name="specifications"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.specifications}
                    error={
                      formik.touched.specifications &&
                      Boolean(formik.errors.specifications)
                    }
                    helperText={
                      formik.touched.specifications &&
                      formik.errors.specifications
                    }
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.specification.pl"
                    )}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 3 },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.zipCode.title")}
                  </Box>
                  <TextField
                    type="number"
                    id="zip_code"
                    name="zip_code"
                    fullWidth
                    inputProps={{ min: "0" }} // Prevent negative numbers via UI
                    onChange={(e) => {
                      const { value } = e.target;

                      // Prevent negative numbers
                      if (value && Number(value) < 0)
                      {
                        e.target.value = ""; // Clear the input if the value is negative
                        return; // Prevent further processing
                      }

                      // Update formik state
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.zip_code}
                    error={
                      formik.touched.zip_code && Boolean(formik.errors.zip_code)
                    }
                    helperText={
                      formik.touched.zip_code && formik.errors.zip_code
                    }
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.foodTruckDetails.zipCode.pl"
                    )}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.foodTruckDetails.area.title")}
                  </Box>

                  <FormControl
                    fullWidth
                    error={
                      formik.touched.work_area_radius &&
                      Boolean(formik.errors.work_area_radius)
                    }
                    sx={{
                      color: theme.palette.text.defaultDark,
                      textAlign: "start",
                      borderRadius: "12px",
                    }}
                  >
                    <Select
                      fullWidth
                      id="work_area_radius"
                      name="work_area_radius"
                      value={+formik.values.work_area_radius}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: theme.palette.text.select_input,
                        borderRadius: "12px",
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                        "&:active": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                      }}
                    >
                      <MenuItem
                        style={{
                          color: theme.palette.text.defaultDark,
                          display: "none",
                        }}
                        value={0}
                      >
                        {t("becomePartner.step2.foodTruckDetails.area.pl")}
                      </MenuItem>

                      {[
                        {
                          label: "50Km",
                          value: 50,
                        },
                        {
                          label: "100Km",
                          value: 100,
                        },
                        {
                          label: "250Km",
                          value: 250,
                        },
                        {
                          label: "500Km",
                          value: 500,
                        },
                        {
                          label: "Germany-wide",
                          value: -1,
                        },
                      ]?.map((e, i) => (
                        <MenuItem
                          style={{ color: theme.palette.text.defaultDark }}
                          key={i}
                          value={e.value}
                        >
                          {e.label}
                        </MenuItem>
                      ))}
                    </Select>

                    {formik.touched.work_area_radius &&
                      formik.errors.work_area_radius && (
                        <FormHelperText>
                          {formik.errors.work_area_radius}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.desc.title")}
                </Box>

                <TextField
                  type="text"
                  id="description"
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.desc.pl"
                  )}
                  multiline
                  fullWidth
                  rows={4}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>

              {/* Meals and diet section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 1,
                }}
              >
                {/* Meals Section */}
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG /> {t("bookNowForm.catering_Type")}
                  </Box>
                  <FormGroup row>{renderMealCheckboxes()}</FormGroup>
                  {formik.touched.meal && formik.errors.meal && (
                    <FormHelperText error>
                      {formik.errors.meal}
                    </FormHelperText>
                  )}
                </Box>

                {/* Special Diets Section */}
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG /> {t("becomePartner.step3.spDiet.title")}
                  </Box>
                  <FormGroup row>{renderDietCheckboxes()}</FormGroup>
                  {formik.touched.diet && formik.errors.diet && (
                    <FormHelperText error>
                      {formik.errors.diet}
                    </FormHelperText>
                  )}
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "24px",
                    color: theme.palette.text.active,
                    mt: 2,
                  }}
                >
                  {t("becomePartner.step2.questions.title")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 3 },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.questions.truckSize.title")}
                  </Box>
                  <TextField
                    type="number"
                    id="size"
                    name="size"
                    fullWidth
                    inputProps={{ min: "0" }}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (value && Number(value) < 0)
                      {
                        e.target.value = "";
                        return;
                      }

                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.size}
                    error={formik.touched.size && Boolean(formik.errors.size)}
                    helperText={formik.touched.size && formik.errors.size}
                    variant="outlined"
                    placeholder={t(
                      "becomePartner.step2.questions.truckSize.pl"
                    )}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.questions.electricity.title")}
                  </Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="electricity_type"
                    id="electricity_type"
                    row
                    value={formik.values.electricity_type}
                    onChange={(e) =>
                      formik.setFieldValue("electricity_type", e.target.value)
                    }
                  >
                    <FormControlLabel
                      id="electricity_type_16A"
                      value="16A"
                      control={<Radio />}
                      label={"16A"}
                      checked={formik.values.electricity_type === "16A"}
                    />
                    <FormControlLabel
                      id="electricity_type_32A"
                      value="32A"
                      control={<Radio />}
                      label={"32A"}
                      checked={formik.values.electricity_type === "32A"}
                    />
                    <FormControlLabel
                      id="electricity_type_230V"
                      value="230V"
                      control={<Radio />}
                      label={"230V"}
                      checked={formik.values.electricity_type === "230V"}
                    />
                    <FormControlLabel
                      id="electricity_type_other"
                      value="Other"
                      control={<Radio />}
                      label={t(
                        "becomePartner.step2.questions.electricity.other"
                      )}
                      checked={formik.values.electricity_type === "Other"}
                    />
                  </RadioGroup>

                  {formik.touched.electricity_type &&
                    formik.errors.electricity_type && (
                      <Typography sx={{ fontSize: "12px" }} color="error">
                        {formik.errors.electricity_type}
                      </Typography>
                    )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 3 },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG />{" "}
                    {t("becomePartner.step2.questions.operatingMode.title")}{" "}
                  </Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    row
                    value={formik.values.operating_mode}
                    onChange={(e) => {
                      formik.setFieldValue("operating_mode", e.target.value);
                    }}
                    name="operating_mode"
                  >
                    <FormControlLabel
                      value="KWH"
                      control={<Radio />}
                      label={`${t(
                        "becomePartner.step2.questions.operatingMode.op1"
                      )}`}
                      checked={formik.values.operating_mode === "KWH"}
                    />
                    <FormControlLabel
                      value="GAS"
                      control={<Radio />}
                      label={`${t(
                        "becomePartner.step2.questions.operatingMode.op2"
                      )}`}
                      checked={formik.values.operating_mode === "GAS"}
                    />
                  </RadioGroup>
                  {formik.touched.operating_mode &&
                    formik.errors.operating_mode && (
                      <Typography sx={{ fontSize: "12px" }} color="error">
                        {formik.errors.operating_mode}
                      </Typography>
                    )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1}>
                    <StarSVG /> {t("becomePartner.step2.questions.water.title")}{" "}
                  </Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    row
                    value={+formik.values.is_water_required}
                    onChange={formik.handleChange}
                    name="is_water_required"
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={`${t("becomePartner.step2.questions.water.op1")}`}
                      checked={formik.values.is_water_required == 1}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={`${t("becomePartner.step2.questions.water.op2")}`}
                      checked={formik.values.is_water_required == 0}
                    />
                  </RadioGroup>
                  {formik.touched.is_water_required &&
                    formik.errors.is_water_required && (
                      <Typography sx={{ fontSize: "12px" }} color="error">
                        {formik.errors.is_water_required}
                      </Typography>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default Step2;
