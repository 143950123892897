import { Box, Typography, useTheme, Grid } from "@mui/material";
import { API_endpoints } from "../../../../API/API_Endpoints";
import demo from "../../../../Assets/images/contact.webp";
import { useCompanyId } from "../../../../Context/CompanyIdContext";
import ButtonComponent from "../../../../Component/Button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";
import { useTranslation } from "react-i18next";
import CatererBookNowModal from "../CatererBookingModal/CatererBookingModal";

const CatServiceCard = ({ data }) => {
  const theme = useTheme();
  const { companyId } = useCompanyId();
  const isOwner = data?.company_id == companyId ?? false;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const truncateCompanyName = (name, length) => {
    if (!name) return "";
    return name?.length > length ? name?.slice(0, length) + "..." : name;
  };

  const redirect = () => {
    toast.warn("Login required.");
    navigate(NavRoutes.LOGIN);
  };


  return (
    <Grid
      container
      sx={{
        boxShadow: 3,
        borderRadius: "8px",
        mt: 2,
        flexDirection: { xs: "column", sm: "row" },
        height: {
          xs: "auto",
          sm: "auto",
          md: "260px",
        },
        overflow: "hidden",
        backgroundColor: theme.palette.background.main,
        // width: { xs: "100%", sm: "100%", md: "100%" },
        // maxWidth: { sm: "600px", md: "100%" },
        // m: "auto",
        // justifyContent: "space-between"
        // alignItems:"center"
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={4.5}
        sx={{
          width: { xs: "100%", sm: "100%", md: "200px", xl: "200px" },
          height: { xs: "200px", sm: "150px", md: "100%" },
          overflow: "hidden",
          borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
        }}
      >
        <img
          src={
            data?.image?.length
              ? API_endpoints.baseURL + data?.image[0]?.image
              : demo
          }
          className="scaleImg"
          alt={data?.title || "Catering Service Image"}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={7.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          p: { xs: 2, sm: 2, xl: 3 },
          backgroundColor: theme.palette.background.main,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              mb: 1,
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {truncateCompanyName(data?.company_name, 17)}
          </Typography>

          <Typography
            sx={{
              color: theme.palette.text.darkOrange,
              fontWeight: 500,
              mb: 1,
            }}
          >
            {data?.caterer_type === 1
              ? "Bussiness Caterer"
              : data?.caterer_type === 3
                ? "Barista/Bartender"
                : "Other"}
          </Typography>

          {/* Categories */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              height: {
                xs: "auto",
                sm: "auto",
                md: "70px",
              },
              overflow: "auto",
            }}
          >
            {data?.meal?.map((x, i) => {
              return (
                <Typography
                  key={i}
                  sx={{
                    backgroundColor: theme.palette.background.label,
                    padding: "4px 8px",
                    borderRadius: "8px",
                    color: theme.palette.text.label,
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "fit-content",
                  }}
                >
                  {x?.meal_name}
                </Typography>
              );
            })}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            // pb: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            alignItems: "end",
            // border: "solid red",
          }}
        >
          <Box className="flexCenter" sx={{ width: "100%" }}>
            {isOwner ? (
              <ButtonComponent
                type="button"
                label={`${t("catSer.company.view")}`}
                onClick={() =>
                  companyId ? navigate(NavRoutes.MY_COMPANY) : redirect()
                }
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  background: theme.palette.background.btn,
                  color: theme.palette.text.primary,
                  fontSize: "16px",
                }}
              />
            ) : (
              <CatererBookNowModal company_id={data?.company_id} />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CatServiceCard;
