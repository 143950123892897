import React from "react";
import { Box } from "@mui/material";
import MyBookings from "./MyBookings";
import MyProfile from "./MyProfile";
import BannerImage from "../../Component/BannerImage/BannerImage";
import { useTranslation } from "react-i18next";
const User = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <BannerImage
        path={{
          title: t("contact.fiesta"),
          home: t("addtruck.MyFoodTruck.home"),
          nav: t("myProfile.title"),
        }}
      />
      <Box
        sx={{
          background: `linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)`,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MyProfile />
        <MyBookings />
      </Box>
    </Box>
  );
};

export default User;
