import { Box, Typography, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import bg from "../../../Assets/images/orange.webp";
import CatServiceCard from "./Cards/CatServiceCard";
import ScrollAnimation from "react-animate-on-scroll";
import { API_GET } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { Link } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
import { useCompanyId } from "../../../Context/CompanyIdContext";

const CateringServiceSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [cateringData, setCateringData] = useState([]);
  const {  renderTrigger } = useCompanyId();

  const getData = async () => {
    try
    {
      let res = await API_GET(
        `${API_endpoints.getallcaterers}?count=0&offset=3`
      );
      res?.s && setCateringData(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [renderTrigger]);

  return (
    <Box
      sx={{
        width: "100%",
        background: `url(${bg}) center center / cover`,
        position: "relative",
        flexDirection: "column",
        paddingY: { xs: 2, md: 5 },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "85%", md: "70%", lg: "50%", xl: "50%" },
          p: 1,
          m: "auto",
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
            textAlign: "center",
          }}
        >
          {t("catSer.company.title")}
        </Typography>
        <Typography
          variant="h6"
          // className="fontSnugle"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "18px", md: "20px" },
            textAlign: "center",
            mt: "0.5rem",
          }}
        >
          {t("catSer.company.subtitle")}
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ width: "90%", margin: "auto", pb: 2 }}>
        {/* Catering Data Section */}
        <Grid
          item
          container
          xs={12}
          justifyContent={{ xs: "space-between", sm: "center" }}
          alignItems="center"
          spacing={3}
          className="removeDefault"
        >
          {cateringData &&
            cateringData?.map((x, i) => {
              let animationType;
              if (i === 0)
              {
                animationType = "animate__fadeInLeft";
              } else if (i === 1)
              {
                animationType = "animate__zoomIn";
              } else if (i === 2)
              {
                animationType = "animate__fadeInRight";
              }
              return (
                <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={i}>
                  <ScrollAnimation animateIn={animationType} key={i}>
                    <Box sx={{ width: "100%", m: "auto" }}>
                      <CatServiceCard data={x} />
                    </Box>
                  </ScrollAnimation>
                </Grid>
              );
            })}
        </Grid>

        {/* Button Section */}
        <Grid
          item
          xs={12}
          className="flexCenter"
          sx={{ p: 1 }}
        >
          <Link to={NavRoutes.CATERING_LIST}>
            <span
              style={{
                fontWeight: "600",
                color: theme.palette.text.active,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="veiwMore"
            >
              {t("explore.more")}
            </span>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CateringServiceSection;
