import { Box, useTheme, Typography } from "@mui/material";
import BannerImageForTruckDetails from "./BannerImageForTruckDetails";
import Slider from "./Slider";
import DetailsSideBar from "./DetailsSideBar";
import Review from "./Review";
import RatingStar from "../../Component/Rating";
// import img from "../../Assets/images/add_profile_pic.png";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { API_GET, API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const FoodTruckDetailScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  let truck_id = location.state.id;
  let company_id = location.state.company_id;
  const truckIdRef = useRef(location.state.id);

  const [data, setData] = useState({});
  const [ratingData, setRatingData] = useState([]);
  const [userID, setuserID] = useState(null);

  const [companydata, setCompanydata] = useState({});
  const [isCertificateAvailble, setIsCertificateAvailble] = useState(false);

  const getData = async () => {
    setLoading(true);
    try
    {
      let url = `${API_endpoints.getcompanybyid}?company_id=${company_id}`;
      let res = await API_GET(url);
      if (res?.s)
      {
        setCompanydata(res?.r);
        let result = res?.r?.food_truck?.find((e) => e.id === truck_id);
        setData(result);
        setuserID(res?.r?.user_id);
        setIsCertificateAvailble(res?.r?.image.includes((x) => x?.type === 2));
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);

      console.log(error);
    }
  };

  const getRating = async () => {
    setLoading(true);
    try
    {
      let url = `${API_endpoints.getrating}?company_id=${company_id}`;
      let res = await API_GET_OPEN(url);
      if (res?.s)
      {
        setRatingData(res.r);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (company_id)
    {
      getData();
      getRating();
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <Box sx={{ background: theme.palette.background.gd }}>
      <BannerImageForTruckDetails />

      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#f57c00" }} />
        </Box>
      ) : (
        <Box
          sx={{
            width: { xs: "100%", sm: "90%" },

            m: "auto",
            maxWidth: "1400px",
            // height: "100vh",
            py: { xs: 2, sm: 3 },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "65%", lg: "75%" },
              px: 2,
            }}
          >
            {/* --- Slider --- */}
            <Slider data={data?.food_truck_image} />
            {/* --- Details --- */}
            <Box sx={{ my: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "22px", md: "30px", xl: "34px" },
                  my: 1,
                }}
              >
                {data?.truck_name}
              </Typography>
              <Typography sx={{ color: `${theme.palette.text.main}80` }}>
                {data?.description}
              </Typography>
            </Box>

            {/* category section */}
            {/* <Box sx={{ my: 2 }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "20px", md: "24px", xl: "28px" },
                  color: theme.palette.text.brown,
                }}
              >
                {" "}
                {t("explore.foodcategories")}
              </Typography>
              <Box sx={{ border: " 1px solid #823D0C24", my: 1 }} />

              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
               
                {companydata && companydata?.food_truck?.length > 0 && (
                  <CategoryCard
                    data={
                      companydata?.food_truck?.find((e) => e.id === truck_id)
                        ?.truck_category
                    }
                  />
                )}
              </Box>
            </Box> */}
            {/* category section */}
            <Box sx={{ my: 2 }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "20px", md: "24px", xl: "28px" },
                  color: theme.palette.text.brown,
                }}
              >
                {" "}
                {t("explore.foodcategories")}
              </Typography>
              <Box sx={{ border: " 1px solid #823D0C24", my: 1 }} />

              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {companydata &&
                  companydata?.food_truck?.length > 0 &&
                  (() => {
                    const d = companydata?.food_truck
                      .find((e) => e?.id === truck_id)
                      .meal?.map((x) => x?.meal_name);
                    return (
                      d && d?.map((x) => <CategoryCard data={x} key={x} />)
                    );
                  })()}
              </Box>
            </Box>

            {/* Certificate section */}
            <Box
              sx={{ my: 2, display: isCertificateAvailble ? "block" : "none" }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "20px", md: "24px", xl: "28px" },
                  color: theme.palette.text.brown,
                }}
              >
                {t("explore.certificate")}
              </Typography>
              <Box sx={{ border: " 1px solid #823D0C24", my: 1 }} />
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {companydata &&
                  companydata?.image?.map((e, i) =>
                    e.type === 2 ? (
                      <Box
                        key={i}
                        sx={{
                          height: "150px",
                          width: "175px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 0.5,
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={API_endpoints.baseURL + "/" + e.image}
                          alt=""
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "14px",
                            objectFit: "contain",
                          }}
                        />
                        {e?.name}
                      </Box>
                    ) : null
                  )}
              </Box>
            </Box>

            <Box
              sx={{
                border: " 1px solid #823D0C24",
                my: 1,
                display: { xs: "block", md: "none" },
              }}
            />

            {/* book Now Button */}
            <Box
              sx={{
                width: { xs: "100%", md: "35%", lg: "25%" },
                py: 2,
                display: { xs: "block", md: "none" },
              }}
            >
              <DetailsSideBar
                data={data}
                userID={userID}
                myId={id}
                truck_id={(truckIdRef.current = truck_id)}
              />
              <Box sx={{ mt: 3 }}>
                <Review />
              </Box>
            </Box>

            {/* review section */}
            <Box sx={{ my: 2, display: ratingData?.length ? "block" : "none" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: { xs: "20px", md: "24px", xl: "28px" },
                  color: theme.palette.text.brown,
                }}
              >
                {t("explore.clientreviews")}
              </Typography>
              <Box sx={{ border: " 1px solid #823D0C24", my: 1 }} />
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                {ratingData?.length > 0 &&
                  ratingData.map((e, i) =>
                    e.user_details?.id !== id && e.is_display ? (
                      <ClientReviewCard key={i} data={e} />
                    ) : null
                  )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "100%", md: "35%", lg: "25%" },
              px: 2,
              display: { xs: "none", md: "block" },
            }}
          >
            <DetailsSideBar data={data} userID={userID} myId={id} />
            <Box sx={{ mt: 2 }}>
              <Review />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    // <Work />
  );
};

export default FoodTruckDetailScreen;

const CategoryCard = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        gap: "0.5rem",
        background: theme.palette.background.main,
        padding: "12px 18px",
        borderRadius: "50px",
      }}
    >
      {/* <img
        src={API_endpoints?.baseURL + "/" + data?.image}
        style={{
          height: "32px",
          width: "32px",
          borderRadius: "12px",
        }}
        alt=""
      /> */}
      <Typography
        sx={{
          color: `${theme.palette.text.main}`,
          fontWeight: "600",
        }}
      >
        {data}
      </Typography>
    </Box>
  );
};

const ClientReviewCard = ({ data }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            height: { xs: "45px", sm: "60px" },
            width: { xs: "45px", sm: "60px" },
          }}
        >
          <img
            src={API_endpoints.baseURL + "/" + data?.user_details?.profile_img}
            style={{ height: "100%", width: "100%", borderRadius: "5px" }}
            alt=""
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            sx={{
              color: theme.palette.text.main,
              fontWeight: "600",
              fontSize: { xs: "18px", lg: "22px" },
            }}
          >
            {data?.user_details?.full_name}
          </Typography>
          <RatingStar rating={data?.rating} />
        </Box>
        <Box>
          <Typography sx={{ color: `${theme.palette.text.main}90` }}>
            {data?.review}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
