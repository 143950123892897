import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../Component/Button/Button";
import BookingCard from "./BookingCard";
import { API_endpoints } from "../../API/API_Endpoints";
import { API_GET, API_GET_USERID, API_POST } from "../../API/config";
const MyBookings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState(5);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleButtonClick = (value) => {
    setSelected(value);
  };

  const handleBookingData = async () => {
    try {
      let url;
      if (selected !== 5) {
        url = `${API_endpoints.mybookings}?user_id=${id}&booking_status=${selected}`;
      } else {
        url = `${API_endpoints.mybookings}?user_id=${id}`;
      }
      let res = await API_GET_USERID(url);
      setData(res.r);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleBookingData();
  }, [selected]);

  return (
    <Box sx={{ width: "90%", m: "auto", maxWidth: "1400px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          py: { xs: 1, md: 5 },
          gap: 1,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "24px", md: "30px" },
          }}
        >
          {`${t("myBooking.title")}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          m: "auto",
          pt: 1,
          width: { xs: "100%", md: "60%" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(5)}
          label={`${t("myBooking.all")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight: "700",
            border:
              selected === 5
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === 5 ? theme.palette.background.main : "transparent",
            color:
              selected === 5
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(0)}
          label={`${t("myBooking.pending")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight: "700",
            border:
              selected === 0
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === 0 ? theme.palette.background.main : "transparent",
            color:
              selected === 0
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(-1)} // Set selected to 3 for 'Cancelled'
          label={`${t("myBooking.cancelled")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight: "700",
            border:
              selected === -1
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === -1 ? theme.palette.background.main : "transparent",
            color:
              selected === -1
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
      </Box>
      <Box sx={{ py: 5, display: "flex", flexDirection: "column", gap: 1 }}>
        {data?.length ? (
          data?.map((e, i) => <BookingCard key={i} data={e}  />)
        ) : (
          <Typography sx={{ textAlign: "center" }}>No Record found</Typography>
        )}
      </Box>
    </Box>
  );
};

export default MyBookings;
