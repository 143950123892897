import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET2 } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";

const ProfileCard = ({
  setSelectedChatUserId,
  setSelectedChatFirebaseId,
  selectedChatFirebaseId,
  data,
  userId,
  myId,
  setSelectedChatUserDetails,
}) => {
  const theme = useTheme();
  const [userDetails, setuserDetails] = useState({});

  const FetchUserDetailsById = async () => {
    try
    {
      const res = await API_GET2(API_endpoints.lightUserDetails + userId);
      if (res?.s)
      {
        setuserDetails(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserDetailsById();
  }, [userId]);

  function formatTimestamp(timestamp) {
    if (!timestamp || !timestamp.toDate)
    {
      return "";
    }
    const now = new Date();
    const timeDifference = Math.floor((now - timestamp.toDate()) / 1000); // Convert to seconds

    if (timeDifference < 60)
    {
      return `now`;
    } else if (timeDifference < 3600)
    {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} m`;
    } else if (timeDifference < 86400)
    {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} h`;
    } else
    {
      const days = Math.floor(timeDifference / 86400);
      return `${days} d`;
    }
  }

  const truncatedMessage = (e) => {
    return e.length > 20 ? `${e.slice(0, 20)}...` : e;
  };

  const handleChatClick = async () => {
    setSelectedChatFirebaseId(data?.id);
    setSelectedChatUserId(userId);
    setSelectedChatUserDetails(userDetails);
    const aa = doc(db, "chat", data?.id);
    const dd = (await getDoc(aa)).data();

    await updateDoc(aa, {
      unReadCount: {
        ...dd.unReadCount,
        [myId]: 0,
      },
    });
  };

  return (
    <Box
      onClick={handleChatClick}
      sx={{
        width: "100%",
        p: 1,
        py: 1.5,
        borderRadius: "7px",
        display: "flex",
        gap: 1,
        background: selectedChatFirebaseId === data?.id ? "#FFF6ED" : "#fff",
        outline: selectedChatFirebaseId === data?.id && "1px solid #F57C00",
        "&:hover": {
          background: "#FFF6ED",
          cursor: "pointer",
          outline: "1px solid #F57C00",
        },
      }}
    >
      <Box sx={{ height: "100%", width: "fit-content" }} className="flexCenter">
        <img
          src={API_endpoints.baseURL + userDetails?.profile_img}
          style={{ height: "50px", width: "50px", borderRadius: "50%" }}
          alt={data?.name}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "calc(100% - 40px)",
          display: "flex",
          gap: 0.5,
          justifyContent: "space-between",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            gap: 0.5,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "600", textTransform: "capitalize" }}>
            {userDetails?.full_name}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {formatTimestamp(data?.sentAt)}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            gap: 0.5,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#000000B2", fontSize: "14px" }}>
            {truncatedMessage(data?.last_msg)}
          </Typography>
          {data.unReadCount[myId] > 0 && (
            <Box
              sx={{
                fontSize: "12px",
                color: theme.palette.text.defaultLight,
                background: theme.palette.background.orange,
                borderRadius: "50%",
                px: 0.5,
              }}
              className="flexCenter"
            >
              {data.unReadCount[myId]}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCard;
