import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  colors,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import SingleImage from "../../Component/ImageUploader/SingleImage";
import { API_endpoints } from "../../API/API_Endpoints";
import { API_GET, API_GET_USERID, API_POST } from "../../API/config";
import { useFormik } from "formik";
import CustomPhoneNumber from "../../Component/Input/PhoneInput";
import { toast } from "react-toastify";
import ForgotPasswordDialogue from "../Auth/Login/ForgotPassword";
import Loader from "../../Component/Loader/Loader";
import ConfirmationModal from "../../Component/Modal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";

const MyProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const BaseUrl = API_endpoints?.baseURL;
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    const event = new CustomEvent("authUpdate", {
      detail: { isLoggedIn: false },
    });
    window.dispatchEvent(event);
    toast.success("Logout Successfully");
    navigate(NavRoutes.HOME);

  };

  const handleCountry = async () => {
    try
    {
      let res = await API_GET(API_endpoints.countries);
      res?.s && setCountries(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleUserData = async () => {
    try
    {
      setIsLoading(true);
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      formik.setValues({
        fullname: res.r?.full_name || "",
        dob: res.r?.dob || "",
        cc: res.r?.phno_cc || "",
        phno: res.r?.phno || "",
        profile: res.r?.profile_img || null,
        country: res.r?.country_id || 0,
      });
      setLoading(false);
      setIsLoading(false);
    } catch (error)
    {
      setLoading(false);
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
    handleCountry();
  }, []);

  const formik = useFormik({
    initialValues: {
      profile: null,
      country: 0,
      fullname: "",
      dob: "",
      cc: "",
      phno: "",
    },
    validationSchema: Yup.object({
      profile: Yup.string().required(t("myProfile.profile")),
      country: Yup.string()
        .required(t("myProfile.country.required"))
        .notOneOf(["0"], t("myProfile.country.invalid")),
      fullname: Yup.string().required(t("myProfile.fullname")),
      dob: Yup.date().required(t("myProfile.country.dob")).nullable(),
      phno: Yup.string()
        .required(t("myProfile.phno.required"))
        .matches(/^[0-9]+$/, t("myProfile.phno.invalid")),
    }),
    onSubmit: async (values) => {
      const f = new FormData();
      f.append("full_name", formik.values.fullname);
      f.append("country_id", formik.values.country);
      f.append("profile_img", formik.values.profile);
      f.append("dob", formik.values.dob);
      f.append("phno_cc", formik.values.cc);
      f.append("phno", formik.values.phno);
      f.append("user_id", id);

      try
      {
        const res = await API_POST(API_endpoints.updateuser, f);
        if (res?.s)
        {
          toast.success(res?.m);
          handleUserData();
        } else
        {
          toast.error(res?.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      }
    },
  });
  return (
    <Box sx={{ width: "90%", m: "auto", maxWidth: "1400px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          py: { xs: 3, md: 5 },
          gap: 1,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "24px", md: "30px" },
          }}
        >
          {`${t("myProfile.title")}`}
        </Typography>
        {/* <ProfileMenu /> */}
        <Box sx={{ gap: 1, display: "flex" }}>
          <ButtonComponent
            onClick={() => {
              navigate(NavRoutes.PARTNER);
            }}
            label={`${t("Navbar.BecomeAPartner")}`}
            sx={{
              border: `1px solid ${theme.palette.background.btn}`,
              // color: theme.palette.text.active,
            }}
          />

          <ButtonComponent
            onClick={() => {
              setOpen(true);
            }}
            label={`${t("myProfile.logout")}`}
            sx={{
              border: `1px solid ${theme.palette.background.orange}`,
              color: theme.palette.text.active,
            }}
          />
        </Box>
      </Box>
      <Box
        className={loading ? "flexCenter" : ""}
        sx={{
          bgcolor: "#fff",
          borderRadius: "20px",
          minHeight: "300px",
          p: { xs: 2, md: 5 },
        }}
      >
        <Box>
          {loading ? (
            <Box>
              <Loader />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  m: "auto",
                  width: "120px",
                  height: "120px",
                  cursor: "pointer",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SingleImage
                  apiPreview={
                    typeof formik.values.profile === "string"
                      ? BaseUrl + formik.values.profile
                      : null
                  }
                  setImage={formik.setFieldValue}
                  name="profile"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1,
                  mt: 1,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    type="text"
                    id="fullname"
                    name="fullname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullname}
                    error={
                      formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                    helperText={
                      formik.touched.fullname && formik.errors.fullname
                    }
                    variant="outlined"
                    placeholder={t("Register.fullname")}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>
                <Box width={"100%"}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    sx={{
                      color: theme.palette.text.defaultDark,
                      textAlign: "start",
                      borderRadius: "12px",
                    }}
                  >
                    <Select
                      fullWidth
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        color: theme.palette.text.select_input,
                        borderRadius: "12px",
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                        "&:active": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                        },
                      }}
                    >
                      <MenuItem
                        style={{
                          display: "none",
                          color: theme.palette.text.defaultDark,
                        }}
                        value="0"
                      >
                        {t("Register.selectcountry")}
                      </MenuItem>
                      {Array.isArray(countries) &&
                        countries.length > 0 &&
                        countries.map((e, i) => (
                          <MenuItem
                            style={{ color: theme.palette.text.defaultDark }}
                            key={i}
                            value={e?.id || "0"} // Use fallback value for safety
                          >
                            {e?.country_name || "Unknown Country"}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.country && formik.errors.country && (
                      <FormHelperText>{formik.errors.country}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 1,
                  mt: 1,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <InputWrapper>
                    <input
                      type="date"
                      value={formik.values.dob}
                      onChange={(e) =>
                        formik.setFieldValue("dob", e.target.value)
                      }
                      onBlur={formik.handleBlur} // Handle blur to trigger validation
                    />
                  </InputWrapper>{" "} */}
                  <input
                    type="date"
                    required
                    id="dob"
                    name="dob"
                    inputMode="numeric"
                    placeholder="yyyy/mm/dd"
                    pattern="/d{2}-/d{2}-/d{4}"
                    value={formik.values.dob}
                    onChange={(e) =>
                      formik.setFieldValue("dob", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    style={{
                      width: "100%",
                      padding: "18px",
                      borderRadius: "12px",
                      border:
                        formik.touched.dob && formik.errors.dob
                          ? "1px solid red"
                          : "1px solid #1A130C3D",
                      backgroundColor: "transparent",
                      color: theme.palette.text.defaultDark,
                      // color: "#000",
                      margin: "0",
                      outline: "none",
                    }}
                  />
                  {formik.touched.dob && formik.errors.dob && (
                    <div
                      style={{
                        color: theme.palette.text.error,
                        fontSize: "12px",
                        textAlign: "start",
                      }}
                    >
                      {formik.errors.dob}
                    </div>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <CustomPhoneNumber
                    name="phno"
                    theme={theme}
                    country={"us"}
                    placeholder="Enter your Number"
                    value={formik.values.cc + formik.values.phno}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phon_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phno", phno);
                      formik.setFieldValue("cc", phon_cc);
                    }}
                    inputClass="inputClassCountry"
                  />

                  {formik.touched.phno && formik.errors.phno && (
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {formik.errors.phno}
                    </div>
                  )}
                </Box>
              </Box>
              <Box>
                <Box sx={{ mt: 1 }}>
                  <ForgotPasswordDialogue title={t("myProfile.reset")} />
                </Box>
              </Box>
              <Box sx={{ mt: 1 }} className="flexCenter">
                <ButtonComponent
                  type="button"
                  onClick={formik.handleSubmit}
                  // label={}
                  label={
                    isLoading ? (
                      <CircularProgress style={{ color: "#f57c00" }} />
                    ) : (
                      `${t("myProfile.update")}`
                    )
                  }
                  sx={{
                    color: theme.palette.text.primary,
                    bgcolor: theme.palette.background.btn,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        // btnImg={}
        open={open}
        setOpen={setOpen}
        handleClick={logout}
        cnfBtnTitle={t("myProfile.logout")}
      // status={""}
      />
    </Box>
  );
};

export default MyProfile;
