export const BecomeAPartner_EN_Trans = {
  title1: "Become a Partner",
  title2: "Register your company",
  prev: "Previous",
  next: "Next",
  step1: {
    title: "1. Company Details",
    company_name: "Enter catering company name",
    city: "Enter city name",
    post_Code: "Enter postal code",
    weblink: "Enter your website link",
    pl_company_name: "Enter company name",
    pl_city: "Enter city name",
    pl_post_Code: "Enter postal code",
    pl_weblink: "Enter weblink",
    vali: {
      Required: "Required",
      company_name: "Invalid company name",
      city: "Invalid city name",
      post_Code: "Invalid postal code",
      weblink: "Invalid weblink",
      InvalidURL: "Invalid URL ",
    },
  },
  step2: {
    title: "2. What types of caterers?",
    types: {
      title: "What types of caterers?",
      type1: "Bussiness Caterer",
      type2: "Food Trucks",
      type3: "Barista/Bartender",
      type4: "Other",
    },
    yourSelf: {
      title: "More about yourself",
      pl: "About yourself",
      required: "Required",
    },
    foodTruckDetails: {
      title: "Food Truck Details",
      image: {
        title: "Upload Food Truck Featured Image",
        drag: "Click here to upload image or Drag & Drop",
      },
      category: {
        title: "Enter food truck category",
        pl: "Enter category",
      },
      zipCode: {
        title: "Zip Code",
        pl: "Enter zip code",
      },
      area: {
        title: "Radius work area",
        pl: "Enter radius area",
      },
      desc: {
        title: "Enter Description",
        pl: "Enter description",
      },
      truckName: {
        title: "Food Truck Name",
        pl: "Enter food truck name",
      },
      address: {
        title: "Food Truck Address",
        pl: "Enter food truck address",
      },
      specification: {
        title: "Food Truck Specification",
        pl: "Enter food truck specification",
      },
      state: {
        title: "State",
        pl: "Enter state name",
      },
      time: {
        title: "Select Timing",
        pl1: "Opening hour",
        pl2: "Closing hour",
      },
    },
    questions: {
      title: "Additional Questions",
      truckSize: {
        title: " Size of the food truck in meter",
        pl: "Enter size of the food truck(meter)",
      },
      electricity: {
        title: "Electricity requirement",
        other: "Other",
      },
      operatingMode: {
        title: "The operating mode of the truck",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "Is a water connection required?",
        op1: "Yes",
        op2: "No",
      },
    },
  },
  step3: {
    title: "3. Meals & Diet",
    meals: {
      title: "Categorical classification of meals",
      1: "Pizza C Pasta",
      2: "Burger C BBQ",
      3: "Seafood",
      4: "African",
      5: "Mexican",
      6: "Coffee",
      7: "Asian C BBQ",
      8: "Asian C Sushi",
      9: "Arabic",
      10: "Indian",
      11: "Cocktails C Drinks",
      12: "Other",
    },
    spDiet: {
      title: "Do you offer a special diet?",
      1: "Vegetarian",
      2: "Vegan",
      3: "Gluten-free",
    },
    Atonemeal: "At least one meal must be selected.",
    Atonediet: "At least one diet must be selected.",
  },
  step4: {
    title: "4. Image upload",
    image: {
      title: "Upload Company Image",
      note: "3 images required",
    },
    certi: {
      title: "Upload Essential Certificates",
      note: "You can upload essential certificate like Food Service Establishment Permit, Seller’s Permit, Fire Certificate etc...",
      pl: "Enter certificate name",
      Required: "Required",
    },
  },
  step5: {
    title: "5. Membership choice",
    member: {
      title: "Please select the membership which is given below.",
      6: "Monthly",
      12: "Yearly",
    },
    discount: {
      title: "Discount Code",
      pl: "Enter Discount Code",
    },
    total: "Total",
    button: "Submit",
    required: "Required",
    subscribe: "Subscribe",
    congrats: "Congratulations! You've successfully become a partner.",
    error: "Payment failed",
  },
  editModal: {
    title: "Edit Food Truck",
    save: "Update",
  },
};

export const BecomeAPartner_NL_Trans = {
  title1: "Word een partner",
  title2: "Registreer uw bedrijf",
  prev: "Vorige",
  next: "Volgende",

  step1: {
    title: "1. Bedrijfsgegevens",
    company_name: "Voer de naam van het cateringbedrijf in",
    city: "Voer de naam van de stad in",
    post_Code: "Voer de postcode in",
    weblink: "Voer uw website link in",
    pl_company_name: "Voer bedrijfsnaam in",
    pl_city: "Voer stadsnaam in",
    pl_post_Code: "Voer postcode in",
    pl_weblink: "Voer website link in",
    vali: {
      Required: "Verplicht",
      company_name: "Ongeldige bedrijfsnaam",
      city: "Ongeldige stadsnaam",
      post_Code: "Ongeldige postcode",
      weblink: "Ongeldige website link",
    },
  },
  step2: {
    title: "2. Welke soorten cateraars?",
    types: {
      title: "Welke soorten cateraars?",
      type1: "Zakelijke Cateraar",
      type2: "Foodtrucks",
      type3: "Barista/Barman",
      type4: "Anders",
    },
    yourSelf: {
      title: "Meer over uzelf",
      pl: "over uzelf",
      required: "Verplicht",
    },
    foodTruckDetails: {
      title: "Foodtruck Details",
      image: {
        title: "Upload een afbeelding van de foodtruck",
        drag: "Klik hier om een afbeelding te uploaden of sleep deze hierheen",
      },
      category: {
        title: "Voer de categorie van de foodtruck in",
        pl: "Voer categorie in",
      },
      zipCode: {
        title: "Postcode",
        pl: "Voer postcode in",
      },
      area: {
        title: "Werkgebied straal",
        pl: "Voer straal in",
      },
      desc: {
        title: "Voer een beschrijving in",
        pl: "Voer beschrijving in",
      },
      truckName: {
        title: "Naam van de foodtruck",
        pl: "Voer foodtruck naam in",
      },
      address: {
        title: "Adres van de foodtruck",
        pl: "Voer foodtruck adres in",
      },
      specification: {
        title: "Specificatie van de foodtruck",
        pl: "Voer specificatie in",
      },
      state: {
        title: "Provincie",
        pl: "Voer provincienaam in",
      },
      time: {
        title: "Selecteer openingstijden",
        pl1: "Openingstijd",
        pl2: "Sluitingstijd",
      },
    },
    questions: {
      title: "Aanvullende vragen",
      truckSize: {
        title: "Grootte van de foodtruck",
        pl: "Voer de grootte van de foodtruck in (meter)",
      },
      electricity: {
        title: "Stroomvereiste",
        other: "Anders",
      },
      operatingMode: {
        title: "De bedrijfsmodus van de truck",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "Is een wateraansluiting vereist?",
        op1: "Ja",
        op2: "Nee",
      },
    },
  },
  step3: {
    title: "3. Maaltijden & Dieet",
    meals: {
      title: "Categorische indeling van maaltijden",
    },
    spDiet: {
      title: "Biedt u een speciaal dieet aan?",
    },
    Atonemeal: "Mindestens eine Mahlzeit muss ausgewählt werden.",
    Atonediet: "Mindestens eine Diät muss ausgewählt werden.",
  },
  step4: {
    title: "4. Afbeelding uploaden",
    image: {
      title: "Upload een bedrijfsafbeelding",
      note: "3 afbeeldingen vereist",
    },
    certi: {
      title: "Upload noodzakelijke certificaten",
      note: "u kunt noodzakelijke certificaten uploaden zoals voedselvergunning, verkoopvergunning, brandveiligheidscertificaat, enz.",
      pl: "Voer certificaatnaam in",
      Required: "Verplicht",
    },
  },
  step5: {
    title: "5. Lidmaatschapskeuze",
    member: {
      title: "Selecteer hieronder het lidmaatschap.",
      6: "6 maanden",
      12: "12 maanden",
    },
    discount: {
      title: "Voer kortingscode",
      pl: "Voer kortingscode in",
    },
    total: "Totaal",
    button: "Versturen",
    required: "Verplicht",
    subscribe: "abonneren",
    congrats: "Herzlichen Glückwunsch! Sie sind erfolgreich Partner geworden.",
    error: "Betaling mislukt",
  },
  editModal: {
    title: "Foodtruck Bewerken",
    save: "Bijwerken",
  },
};

export const BecomeAPartner_DE_Trans = {
  title1: "Partner werden",
  title2: "Registrieren Sie Ihr Unternehmen",
  prev: "Zurück",
  next: "Weiter",
  step1: {
    title: "1. Unternehmensdetails",
    company_name: "Geben Sie den Namen des Catering-Unternehmens ein",
    city: "Geben Sie den Stadtnamen ein",
    post_Code: "Geben Sie die Postleitzahl ein",
    weblink: "Geben Sie den Link zu Ihrer Website ein",
    pl_company_name: "Geben Sie den Firmennamen ein",
    pl_city: "Geben Sie den Stadtnamen ein",
    pl_post_Code: "Geben Sie die Postleitzahl ein",
    pl_weblink: "Geben Sie den Weblink ein",
    vali: {
      Required: "Erforderlich",
      company_name: "Ungültiger Firmenname",
      city: "Ungültiger Stadtname",
      post_Code: "Ungültige Postleitzahl",
      weblink: "Ungültiger Weblink",
      InvalidURL: "Ungültige URL",
    },
  },
  step2: {
    title: "2. Welche Arten von Caterern?",
    types: {
      title: "Welche Arten von Caterern?",
      type1: "Unternehmens-Caterer",
      type2: "Food Trucks",
      type3: "Barista/Barkeeper",
      type4: "Andere",
    },
    yourSelf: {
      title: "Mehr über Sie",
      pl: "Über Sie",
      required: "Erforderlich",
    },
    foodTruckDetails: {
      title: "Food Truck Details",
      image: {
        title: "Laden Sie ein hervorgehobenes Bild des Food Trucks hoch",
        drag: "Klicken Sie hier, um das Bild hochzuladen oder ziehen Sie es hierher",
      },
      category: {
        title: "Geben Sie die Kategorie des Food Trucks ein",
        pl: "Kategorie eingeben",
      },
      zipCode: {
        title: "Postleitzahl",
        pl: "Postleitzahl eingeben",
      },
      area: {
        title: "Arbeitsradius",
        pl: "Geben Sie den Arbeitsradius ein",
      },
      desc: {
        title: "Geben Sie eine Beschreibung ein",
        pl: "Beschreibung eingeben",
      },
      truckName: {
        title: "Name des Food Trucks",
        pl: "Geben Sie den Namen des Food Trucks ein",
      },
      address: {
        title: "Adresse des Food Trucks",
        pl: "Geben Sie die Adresse des Food Trucks ein",
      },
      specification: {
        title: "Spezifikation des Food Trucks",
        pl: "Geben Sie die Spezifikation des Food Trucks ein",
      },
      state: {
        title: "Bundesland",
        pl: "Geben Sie den Namen des Bundeslandes ein",
      },
      time: {
        title: "Wählen Sie die Öffnungszeiten",
        pl1: "Öffnungszeit",
        pl2: "Schließzeit",
      },
    },
    questions: {
      title: "Zusätzliche Fragen",
      truckSize: {
        title: "Größe des Foodtrucks in Metern",
        pl: "Geben Sie die Größe des Food Trucks (Meter) ein",
      },
      electricity: {
        title: "Strombedarf",
        other: "Andere",
      },
      operatingMode: {
        title: "Betriebsmodus des Trucks",
        op1: "Kwh",
        op2: "Gas",
      },
      water: {
        title: "Ist ein Wasseranschluss erforderlich?",
        op1: "Ja",
        op2: "Nein",
      },
    },
  },
  step3: {
    title: "3. Mahlzeiten & Diät",
    meals: {
      title: "Kategorische Klassifizierung der Mahlzeiten",
      1: "Pizza & Pasta",
      2: "Burger & BBQ",
      3: "Meeresfrüchte",
      4: "Afrikanisch",
      5: "Mexikanisch",
      6: "Kaffee",
      7: "Asiatisch & BBQ",
      8: "Asiatisch & Sushi",
      9: "Arabisch",
      10: "Indisch",
      11: "Cocktails & Getränke",
      12: "Andere",
    },
    spDiet: {
      title: "Bieten Sie eine spezielle Diät an?",
      1: "Vegetarisch",
      2: "Vegan",
      3: "Glutenfrei",
    },
    Atonemeal: "Mindestens eine Mahlzeit muss ausgewählt werden.",
    Atonediet: "Mindestens eine Diät muss ausgewählt werden.",
  },
  step4: {
    title: "4. Bild hochladen",
    image: {
      title: "Unternehmensbild hochladen",
      note: "3 Bilder erforderlich",
    },
    certi: {
      title: "Laden Sie wichtige Zertifikate hoch",
      note: "Sie können wichtige Zertifikate wie Genehmigungen für Lebensmittelbetriebe, Verkäufergenehmigungen, Feuerzertifikate usw. hochladen.",
      pl: "Geben Sie den Zertifikatsnamen ein",
      Required: "Erforderlich",
    },
  },
  step5: {
    title: "5. Mitgliedschaft wählen",
    member: {
      title: "Bitte wählen Sie die unten angegebene Mitgliedschaft aus.",
      6: "Monatlich",
      12: "Jährlich",
    },
    discount: {
      title: "Rabattcode",
      pl: "Geben Sie den Rabattcode ein",
    },
    total: "Gesamt",
    button: "Einreichen",
    required: "Erforderlich",
    subscribe: "Abonnieren",
    congrats: "Herzlichen Glückwunsch! Sie sind erfolgreich Partner geworden.",
    error: "Zahlung fehlgeschlagen",
  },
  editModal: {
    title: "Food Truck bearbeiten",
    save: "Aktualisieren",
  },
};

export const BecomeAPartner_ES_Trans = {
  title1: "Conviértete en Socio",
  title2: "Registra tu empresa",
  prev: "Anterior",
  next: "Siguiente",
  step1: {
    title: "1. Detalles de la Empresa",
    company_name: "Ingrese el nombre de la empresa de catering",
    city: "Ingrese el nombre de la ciudad",
    post_Code: "Ingrese el código postal",
    weblink: "Ingrese el enlace de su sitio web",
    pl_company_name: "Ingrese el nombre de la empresa",
    pl_city: "Ingrese el nombre de la ciudad",
    pl_post_Code: "Ingrese el código postal",
    pl_weblink: "Ingrese el enlace",
    vali: {
      Required: "Requerido",
      company_name: "Nombre de empresa no válido",
      city: "Nombre de ciudad no válido",
      post_Code: "Código postal no válido",
      weblink: "Enlace no válido",
      InvalidURL: "URL no válida",
    },
  },
  step2: {
    title: "2. ¿Qué tipos de catering?",
    types: {
      title: "¿Qué tipos de catering?",
      type1: "Catering Corporativo",
      type2: "Camiones de Comida",
      type3: "Barista/Bartender",
      type4: "Otro",
    },
    yourSelf: {
      title: "Más sobre ti",
      pl: "Sobre ti",
      required: "Requerido",
    },
    foodTruckDetails: {
      title: "Detalles del Camión de Comida",
      image: {
        title: "Subir Imagen Destacada del Camión de Comida",
        drag: "Haga clic aquí para subir imagen o Arrastre y Suelte",
      },
      category: {
        title: "Ingrese la categoría del camión de comida",
        pl: "Ingrese categoría",
      },
      zipCode: {
        title: "Código Postal",
        pl: "Ingrese código postal",
      },
      area: {
        title: "Área de trabajo en radio",
        pl: "Ingrese área en radio",
      },
      desc: {
        title: "Ingrese Descripción",
        pl: "Ingrese descripción",
      },
      truckName: {
        title: "Nombre del Camión de Comida",
        pl: "Ingrese nombre del camión de comida",
      },
      address: {
        title: "Dirección del Camión de Comida",
        pl: "Ingrese dirección del camión de comida",
      },
      specification: {
        title: "Especificación del Camión de Comida",
        pl: "Ingrese especificación del camión de comida",
      },
      state: {
        title: "Estado",
        pl: "Ingrese el nombre del estado",
      },
      time: {
        title: "Seleccionar Horario",
        pl1: "Hora de apertura",
        pl2: "Hora de cierre",
      },
    },
    questions: {
      title: "Preguntas Adicionales",
      truckSize: {
        title: "Tamaño del camión de comida",
        pl: "Ingrese el tamaño del camión de comida (metros)",
      },
      electricity: {
        title: "Requisito de electricidad",
        other: "Otro",
      },
      operatingMode: {
        title: "Modo de operación del camión",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "¿Se requiere conexión de agua?",
        op1: "Sí",
        op2: "No",
      },
    },
  },
  step3: {
    title: "3. Comidas y Dietas",
    meals: {
      title: "Clasificación de comidas por categoría",
      1: "Pizza y Pasta",
      2: "Hamburguesas y BBQ",
      3: "Mariscos",
      4: "Africana",
      5: "Mexicana",
      6: "Café",
      7: "Asiática y BBQ",
      8: "Asiática y Sushi",
      9: "Árabe",
      10: "India",
      11: "Cócteles y Bebidas",
      12: "Otro",
    },
    spDiet: {
      title: "¿Ofrece una dieta especial?",
      1: "Vegetariana",
      2: "Vegana",
      3: "Sin gluten",
    },
    Atonemeal: "Debe seleccionar al menos una comida.",
    Atonediet: "Debe seleccionar al menos una dieta.",
  },
  step4: {
    title: "4. Subir Imágenes",
    image: {
      title: "Subir Imagen de la Empresa",
      note: "Se requieren 3 imágenes",
    },
    certi: {
      title: "Subir Certificados Esenciales",
      note: "Puede subir certificados esenciales como Permiso de Establecimiento de Servicios de Alimentos, Permiso de Venta, Certificado de Incendios, etc...",
      pl: "Ingrese el nombre del certificado",
      Required: "Requerido",
    },
  },
  step5: {
    title: "5. Elección de Membresía",
    member: {
      title: "Seleccione la membresía a continuación.",
      6: "6 meses",
      12: "12 meses",
    },
    discount: {
      title: "Código de Descuento",
      pl: "Ingrese código de descuento",
    },
    total: "Total",
    button: "Enviar",
    required: "Requerido",
    subscribe: "Suscribirse",
    congrats: "¡Felicidades! Te has convertido en socio con éxito.",
    error: "Error en el pago",
  },
};
