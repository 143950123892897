import React, { forwardRef, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  useTheme,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import { useImperativeHandle } from "react";
import { API_GET, API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const Step3 = forwardRef((props, ref) => {
  const compID = localStorage.getItem("Fiesta_compID");
  const theme = useTheme();
  const { t } = useTranslation();
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);
  const [loading, setLoading] = useState(true);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const formik = useFormik({
    initialValues: {
      selectedMeals: [],
      selectedDiets: [],
    },
    validationSchema: Yup.object({
      selectedMeals: Yup.array().min(1, t("becomePartner.step3.Atonemeal")),
      selectedDiets: Yup.array().min(1, t("becomePartner.step3.Atonediet")),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let f = new FormData();
        f.append("meal_ids", values.selectedMeals);
        f.append("company_id", compID);
        const res = await API_POST(API_endpoints.addMealData, f);

        // add diet
        let d = new FormData();
        d.append("diet_ids", values.selectedDiets);
        d.append("company_id", compID);
        const resDiet = await API_POST(API_endpoints.addDietData, d);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
  });

  const handleCheckboxChange = (type) => (value) => (event) => {
    const { checked } = event.target;
    const updatedArray = checked
      ? [...formik.values[type], value]
      : formik.values[type].filter((item) => item !== value);

    formik.setFieldValue(type, updatedArray);
  };

  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  // useEffect(() => {
  //   const parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  //   const companyId = parsedData?.company_id;
  //   formik.setFieldValue("company_id", companyId);
  // }, []);

  const renderCheckbox = (labelKey, value, type) => (
    <FormControlLabel
      control={
        <Checkbox
          id={labelKey}
          checked={formik.values[type].includes(value)}
          onChange={handleCheckboxChange(type)(value)}
          sx={{
            color: theme.palette.text.secondary,
            "&.Mui-checked": {
              color: theme.palette.text.active,
            },
          }}
        />
      }
      label={labelKey}
    />
  );

  const renderMealCheckboxes = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 4 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {meal.map(({ meal_name, id }) =>
          renderCheckbox(meal_name, id, "selectedMeals")
        )}
      </Box>
    </Box>
  );

  const renderDietCheckboxes = () => (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {diet.map(({ diet_name, id }) =>
        renderCheckbox(diet_name, id, "selectedDiets")
      )}
    </Box>
  );

  const handlemeal = async () => {
    try {
      let res = await API_GET(API_endpoints.getmealcat);
      if (res.s) setMeal(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  const handlediet = async () => {
    try {
      let res = await API_GET(API_endpoints.getdietcat);
      if (res.s) {
        setDiet(res.r);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlemeal();
    handlediet();
  }, []);

  const handleUserData = async () => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s) {
        setLoading(false);
        formik.setFieldValue("company_id", res?.r?.company_details?.id);
        localStorage.setItem("Fiesta_compID", res?.r?.company_details?.id);
        formik.setFieldValue(
          "meal_ids",
          res?.r?.company_details?.meal?.map((e) => e?.meal_id)
        );
        formik.setFieldValue(
          "selectedMeals",
          res?.r?.company_details?.meal?.map((e) => e?.meal_id)
        );
        formik.setFieldValue(
          "diet_ids",
          res?.r?.company_details?.diet?.map((e) => e?.diet_id)
        );
        formik.setFieldValue(
          "selectedDiets",
          res?.r?.company_details?.diet?.map((e) => e?.diet_id)
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  return (
    <Box>
      <Box sx={{ fontSize: { xs: "24px" }, fontWeight: 600, padding: "1rem" }}>
        {t("becomePartner.step3.title")}
      </Box>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: loading ? "200px" : "",
        }}
      >
        {" "}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "#f57c00" }} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
            }}
          >
            {/* Meals Section */}
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step3.meals.title")}
              </Box>
              <FormGroup row>{renderMealCheckboxes()}</FormGroup>
              {formik.touched.selectedMeals && formik.errors.selectedMeals && (
                <FormHelperText error>
                  {formik.errors.selectedMeals}
                </FormHelperText>
              )}
            </Box>

            {/* Special Diets Section */}
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step3.spDiet.title")}
              </Box>
              <FormGroup row>{renderDietCheckboxes()}</FormGroup>
              {formik.touched.selectedDiets && formik.errors.selectedDiets && (
                <FormHelperText error>
                  {formik.errors.selectedDiets}
                </FormHelperText>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default Step3;
