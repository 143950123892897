import { Box, Grid, Typography, useTheme } from "@mui/material";
import sideimg1 from "../../../Assets/images/strip1.png";
import left from "../../../Assets/images/leftSub.svg";
import right from "../../../Assets/images/rightSub.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { API_GET_USERID } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import moment from "moment";
import { useCompanyId } from "../../../Context/CompanyIdContext";

const Subscription = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  const { renderTrigger } = useCompanyId();
  let UserID = parseData?.id;

  const handleUserData = async () => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, UserID);
      res?.s && setData(res?.r?.company_details?.subscription);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, [renderTrigger]);
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        flexDirection: "column",
        py: { xs: 4, sm: 5, md: 6 }, // Responsive padding
        gap: 5,
      }}
    >
      {/* Background Image */}
      <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
        <img src={sideimg1} alt="" height={40} />
      </Box>

      {/* Subscription Title */}
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" },
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "24px", sm: "30px", md: "35px", lg: "40px" },
            mb: 1,
          }}
        >
          {t("subscription.Headline")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            mt: "0.5rem",
          }}
        >
          {t("subscription.SubHeadline")}
        </Typography>
      </Box>

      {/* Subscription Info Card */}
      <Box
        sx={{
          width: { xs: "95%", sm: "90%", md: "85%", xl: "80%" },
          marginX: "auto",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ mt: 2 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} xl={10}>
            <Box
              sx={{
                background: `linear-gradient(90deg,  #EAEFF7 0%, rgba(234, 239, 246, 0.3) 48.91%, #EAEFF6 100%)`,
                borderWidth: "0px 2px 2px 0px",
                borderRadius: "22px",
                borderStyle: "solid",
                borderColor: "#164FA1",
                position: "relative",
                overflow: "hidden",
                padding: { xs: 2, sm: 3, lg: 4 },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "flex-start", md: "space-around" },
                alignItems: "center",
                gap: { xs: 3, md: 4 },
              }}
            >
              {/* Plan Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "start", md: "center" },
                  width: { xs: "100%", md: "25%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "20px" },
                    mb: 1,
                  }}
                >
                  {t("subscription.plan")}
                </Typography>
                <Typography
                  sx={{
                    color: "#F57C00",
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "24px" },
                  }}
                >
                  {data?.month} Months
                </Typography>
              </Box>

              {/* Divider */}
              <Box
                sx={{
                  border: "1px dashed #164FA1",
                  height: { xs: "0px", md: "150px" },
                  width: { xs: "100%", md: "1px" },
                  display: { xs: "none", md: "block" },
                }}
              />

              {/* Description Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "start", md: "center" },
                  width: { xs: "100%", md: "25%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "20px" },
                    mb: 1,
                  }}
                >
                  {t("subscription.desc")}
                </Typography>
                <Typography
                  sx={{
                    color: "#1A130CCC",
                    textAlign: { xs: "left", md: "center" },
                  }}
                >
                  {data?.description?.length > 100
                    ? data?.description?.slice(0, 100) + "..."
                    : data?.description}
                </Typography>
              </Box>

              {/* Divider */}
              <Box
                sx={{
                  border: "1px dashed #164FA1",
                  height: { xs: "0px", md: "150px" },
                  width: { xs: "100%", md: "1px" },
                  display: { xs: "none", md: "block" },
                }}
              />

              {/* Start Date Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "start", md: "center" },
                  width: { xs: "100%", md: "25%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "20px" },
                    mb: 1,
                  }}
                >
                  {t("subscription.sDate")}
                </Typography>
                <Typography
                  sx={{
                    color: "#F57C00",
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "22px" },
                  }}
                >
                  {moment(data?.start_time).format("DD/MM/YYYY")}
                </Typography>
              </Box>

              {/* Divider */}
              <Box
                sx={{
                  border: "1px dashed #164FA1",
                  height: { xs: "0px", md: "150px" },
                  width: { xs: "100%", md: "1px" },
                  display: { xs: "none", md: "block" },
                }}
              />

              {/* End Date Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "start", md: "center" },
                  width: { xs: "100%", md: "25%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "20px" },
                    mb: 1,
                  }}
                >
                  {t("subscription.eDate")}
                </Typography>
                <Typography
                  sx={{
                    color: "#F57C00",
                    fontWeight: 600,
                    fontSize: { xs: "16px", md: "18px", xl: "22px" },
                  }}
                >
                  {moment(data?.end_time).format("DD/MM/YYYY")}
                </Typography>
              </Box>

              {/* Decorative Images */}
              <img
                src={left}
                alt=""
                style={{ position: "absolute", left: 0, bottom: 0 }}
              />
              <img
                src={right}
                alt=""
                style={{ position: "absolute", right: 0, top: 0 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Subscription;
