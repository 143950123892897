import { Avatar, Box, Typography } from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db, firebaseStorage } from "../../../Firebase/Firebase";
import { API_endpoints } from "../../../API/API_Endpoints";
import DeleteMsgMenu from "./DeleteMsgMenu";
import { deleteObject, ref } from "firebase/storage";

const MessageHandler = ({
  selectedChatFirebaseId,
  myId,
  selectedChatUserDetails,
}) => {
  const [messages, setMessages] = useState([]);
  const myProfile = JSON.parse(localStorage.getItem("Fiesta"))?.profile_img;
  const containerRef = useRef(null);
  useEffect(() => {
    const chatPath = collection(
      db,
      "chat",
      selectedChatFirebaseId,
      "message_chat"
    );

    const chatListUnsubscribe = onSnapshot(
      query(chatPath, orderBy("sentAt", "asc")),
      async (querySnapshot) => {
        const updatedData = [];
        let currentDate = null;
        querySnapshot.forEach((a) => {
          const messageData = { id: a.id, ...a.data() };

          const messageDate = messageData.sentAt?.toDate().toDateString();
          if (currentDate !== messageDate)
          {
            currentDate = messageDate;
            updatedData.push({
              id: `separator_${currentDate}`,
              dateSeparator: true,
              sentAt: messageData.sentAt
            });
          }
          updatedData.push(messageData);
        });
        console.log(updatedData, 'updatedData')
        setMessages(updatedData);
      }
    );

    return () => {
      chatListUnsubscribe();
    };
  }, [selectedChatFirebaseId]);

  function formatMessageTime(timestamp) {
    if (!timestamp || !timestamp.toDate)
    {
      return "Loading..";
    }

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      timestamp.toDate()
    );
    return formattedTime;
  }

  const handleDelete = async (data) => {
    try
    {
      if (data?.msgType === 2 && data?.path)
      {
        const imageRef = ref(firebaseStorage, `chat-media/${data?.path}`);
        await deleteObject(imageRef);
      }
      await deleteDoc(
        doc(db, "chat", selectedChatFirebaseId, "message_chat", data?.id)
      );
    } catch (error)
    {
      console.error("Error deleting message and media:", error);
    }
  };

  const scrollToBottom = () => {
    if (containerRef.current)
    {
      const chatContainer = containerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function formatDate(date) {
    if (!date || !(date instanceof Date))
    {
      return "";
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    if (date.toDateString() === today.toDateString())
    {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString())
    {
      return "Yesterday";
    } else
    {
      return `${day}/${month}/${year}`;
    }
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        py: 1,
        overflow: "scroll",
        my: 1,
        // scrollBehavior: "smooth",
      }}
      ref={containerRef}
    >

      {/* id
      last_msg
      last_msg_by
      msg
      msgType
      sentAt
      Timestamp
      sentById */}
      {messages?.map((message) => {

        return (
          <>
            {message.dateSeparator ? (
              <Box sx={{ textAlign: "center", my: 1 }}>
                <Typography variant="caption" >
                  {/* Format and show the date */}
                  {message.sentAt?.toDate()
                    ? formatDate(message.sentAt.toDate())
                    : ""}
                </Typography>
              </Box>
            ) : <Box
              className="menuHover"
              key={message?.id}
              sx={{
                display: "flex",
                flexDirection:
                  message?.sentById === myId ? "row-reverse" : "row", // Align messages based on sender
                mb: 2,
              }}
            >
              <Avatar
                src={
                  message?.sentById === myId
                    ? API_endpoints.baseURL + myProfile
                    : API_endpoints.baseURL +
                    selectedChatUserDetails?.profile_img
                }
                alt={message?.senderName}
                sx={{ width: 32, height: 32, margin: "0 10px" }}
              >
                {message?.senderName?.charAt(0)}
              </Avatar>
              <Box
                sx={{
                  display: message.sentById === myId ? "block" : "none",
                  position: "relative",

                  mt: 1,
                  ml: -1,
                }}
              >
                <DeleteMsgMenu data={message} handleDelete={handleDelete} />
              </Box>
              <Box>
                {message.msgType === 1 ? (
                  // Text message
                  <Typography
                    sx={{
                      fontWeight: "400",
                      borderRadius:
                        message?.sentById === myId
                          ? "15px 15px 0 15px"
                          : "15px 15px 15px 0",
                      backgroundColor:
                        message?.sentById === myId ? "#F57C0038" : "#DEE6F2",
                      padding: "12px",
                      fontSize: "17px",
                      letterSpacing: "0.36px",
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                      wordBreak: "break-word",
                      textAlign: "left", // Keep the alignment consistent
                      maxWidth: { md: "300px", xl: "500px" },
                      my: 1, // Add margin to space out messages
                    }}
                  >
                    {message?.msg}
                  </Typography>
                ) : message.msgType === 2 ? (
                  // Image message
                  <Box
                    sx={{
                      width: {
                        xs: "120px",
                        sm: "200px",
                        md: "250px",
                        xl: "300px",
                      },
                      my: 1, // Add margin to space out messages
                    }}
                  >
                    <img
                      src={message.content}
                      alt="preview"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                ) : null}

                {/* Message time */}
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#999",
                    textAlign: message?.sentById === myId ? "right" : "left",
                    mt: "4px",
                  }}
                >
                  {formatMessageTime(message?.sentAt)}
                </Typography>
              </Box>
            </Box>}

          </>
        );
      })}
    </Box>
  );
};

export default MessageHandler;
