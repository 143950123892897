import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import { useEffect, useState } from "react";
import { API_endpoints } from "../../../API/API_Endpoints";
import { API_GET2, API_GET_USERID, API_POST2 } from "../../../API/config";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
let PAGE_SIZE_OPTIONS = [15];
const MyPaymentTab = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [IsLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const userLocalData = JSON.parse(localStorage.getItem("Fiesta"));
  const [userSubscriptionStatus, setUserSubscriptionStatus] = useState("");
  const navigate = useNavigate();

  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "Sr.",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return page * PAGE_SIZE_OPTIONS[0] + cell?.row?.rowId;
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "subscription_id",
      headerName: `${t("ownerProfile.payment.grid.subId")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "plan_id",
      headerName: `${t("ownerProfile.payment.grid.pID")}`,
      minWidth: 270,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: `${t("ownerProfile.payment.grid.status")}`,
      flex: 1,
      minWidth: 130,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color:
                  cell.value === "ACTIVE"
                    ? "green"
                    : cell.value === "APPROVAL_PENDING"
                    ? "grey"
                    : "red",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              {cell.value === "APPROVAL_PENDING"
                ? "Approval Pending"
                : cell.value === "ACTIVE"
                ? "Active"
                : cell?.value.toLowerCase()}
            </span>
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "created_at",
      headerName: `${t("ownerProfile.payment.grid.Date")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "action",
      headerName: `${t("ownerProfile.payment.grid.action")}`,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        const { status, subscription_id } = cell?.row;
        // const currentIndex = data.findIndex((row) => row.id === cell.id);
        const isLastPendingEntry =
          status === "APPROVAL_PENDING" &&
          data[0]?.id === cell.id &&
          data[0]?.status !== "CANCELLED";
        const isCancelled = data[0]?.status === "CANCELLED";
        const isActive = data[0]?.status === "ACTIVE";
        const isElegibleForRenew = userSubscriptionStatus !== "ACTIVE";

        return (
          <Box>
            {status === "ACTIVE" && !isCancelled && isActive ? (
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => handleCancelClick(subscription_id)}
              >
                {t("ownerProfile.payment.cancel")}
              </Button>
            ) : isLastPendingEntry && !isCancelled ? (
              <Button
                sx={{ textTransform: "none" }}
                onClick={() =>
                  handleVerifySubscriptionPaymentStatus(subscription_id)
                }
              >
                {t("ownerProfile.payment.verify")}
              </Button>
            ) : isElegibleForRenew ? (
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => navigate(NavRoutes.Renew)}
              >
                {t("ownerProfile.payment.renew")}
              </Button>
            ) : (
              "-"
            )}
          </Box>
        );
      },
    },
  ];

  const handleVerifySubscriptionPaymentStatus = async (sub_id) => {
    try {
      const res = await API_GET2(
        `${API_endpoints.verifySubscription}?subscription_id=${sub_id}`
      );
      if (res?.s) {
        if (res?.r?.status === "APPROVAL_PENDING") {
          window.open(res?.r?.links[0]?.href);
        } else {
          toast.success(res?.r?.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancelPlan = async (sub_id) => {
    try {
      const res = await API_POST2(
        `${API_endpoints.CancelSubscription}?subscription_id=${sub_id}`
      );
      if (res?.s) {
        await handleVerifySubscriptionPaymentStatus(sub_id);
        fetchData(userLocalData?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelClick = async (sub_id) => {
    try {
      Swal.fire({
        title: t("ownerProfile.payment.cancelPlan"),
        text: t("ownerProfile.payment.cancelPlanText"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("ownerProfile.payment.Confirm"),
        cancelButtonText: t("ownerProfile.payment.cancel"),
        reverseButtons: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: "#F57C00",
        preConfirm: async () => {
          try {
            await handleCancelPlan(sub_id);
            Swal.fire({
              title: t("ownerProfile.payment.successTitle"),
              text: t("ownerProfile.payment.successMessage"),
              icon: "success",
              confirmButtonText: "OK",
            });
          } catch (error) {
            Swal.fire({
              title: t("ownerProfile.payment.errorTitle"),
              text: t("ownerProfile.payment.errorMessage"),
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (
    userId,
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0]
  ) => {
    try {
      setIsLoading(true);
      let count = page * pageSize;
      let res = await API_GET2(
        `${API_endpoints.getOwnerSubscriptionList}?count=${count}&offset=15&user_id=${userId}&type=1`
      );
      if (res?.s) {
        const rowId = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowId);
        setTotalData(res?.c ?? 0);
      } else {
        setData([]);
        setTotalData(0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const getUserDetailsById = async (id) => {
    try {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s) {
        setUserSubscriptionStatus(
          res?.r?.company_details?.subscription?.status
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userId = userLocalData?.id;
    fetchData(userId);
    getUserDetailsById(userId);
  }, [userLocalData?.id]);

  console.log(userSubscriptionStatus);

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.payment.note")}
        </Typography>
      </Box>

      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box sx={{ height: "300px" }}>
        <DataListingComponent
          data={data ?? []}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={IsLoading}
          totalData={totalData}
          page={page}
          Footer={true}
          hidefooter={true}
          columns={column}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default MyPaymentTab;
