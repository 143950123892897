import {
  Box,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  API_GET_OPEN,
  API_GET_USERID,
  API_POST,
} from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { toast } from "react-toastify";

const CompanyDetailsTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [Isloading, setIsLoading] = useState(true);
  const [companyID, setcompanyID] = useState('')
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;

  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s)
      {
        const id = res?.r?.company_details?.id;
        getData(id);
        setcompanyID(id)
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  const getData = async (id) => {
    try
    {
      setIsLoading(true)
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );
      formik.setValues({
        company_name: res?.r?.company_name,
        city: res?.r?.city,
        postal_code: res?.r?.postal_code,
        website_link: res?.r?.website_link,
        user_id: res?.r?.user_id,
        id: res?.r?.id,
      });
      setIsLoading(false)
    } catch (error)
    {
      setIsLoading(false)
      console.log(error);
    }
  };



  const formik = useFormik({
    initialValues: {
      company_name: "",
      city: "",
      postal_code: "",
      website_link: "",
      user_id: id,
      id: companyID,
    },

    validationSchema: Yup.object({
      company_name: Yup.string().required(
        t("becomePartner.step1.vali.Required")
      ),
      city: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      postal_code: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      website_link: Yup.string()
        .trim()
        .url(t("becomePartner.step1.vali.InvalidURL"))
        .required(t("becomePartner.step1.vali.Required")),
    }),

    onSubmit: async (values) => {
      try
      {
        setLoading(true);
        let res;
        if (values.user_id)
        {
          res = await API_POST(API_endpoints.updatecompany, values);
        }
        if (res?.s)
        {
          toast.success(res?.m);
          setLoading(false);
        }
        setLoading(false);
      } catch (error)
      {
        setLoading(false);
        console.error("Error:", error);
      }
    },
  });





  return (
    <Box
      sx={{
        background: theme.palette.background.main,
        borderRadius: "25px",
        // padding: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      {Isloading ? <Box sx={{ minHeight: "300px" }} className="flexCenter">
        <CircularProgress style={{ color: "#f57c00" }} />
      </Box> : <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          // padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              type="text"
              id="company_name"
              name="company_name"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                )
                {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.company_name}
              error={
                formik.touched.company_name &&
                Boolean(formik.errors.company_name)
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_company_name")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              type="text"
              id="city"
              name="city"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                )
                {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_city")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              type="number"
              id="postal_code"
              name="postal_code"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;

                // Prevent negative numbers
                if (value && Number(value) < 0)
                {
                  e.target.value = ""; // Clear the input if the value is negative
                  return; // Prevent further processing
                }

                // Trim whitespace
                e.target.value = value.trim();

                // Update formik state
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.postal_code}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }
              helperText={
                formik.touched.postal_code && formik.errors.postal_code
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_post_Code")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              type="text"
              id="website_link"
              name="website_link"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                if (
                  value.trimStart() !== value &&
                  value.trimStart() !== value
                )
                {
                  e.target.value = value.trimStart();
                  e.target.value = value.trimEnd();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.website_link}
              error={
                formik.touched.website_link &&
                Boolean(formik.errors.website_link)
              }
              helperText={
                formik.touched.website_link && formik.errors.website_link
              }
              variant="outlined"
              placeholder={t("becomePartner.step1.pl_weblink")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box className="flexCenter" sx={{ mt: 5 }}>
          <ButtonComponent
            disabled={loading}
            type="button"
            label={
              loading ? <Loader /> : `${t("ownerProfile.profile.update")}`
            }
            onClick={formik.handleSubmit}
            sx={{
              width: { xs: "80%", sm: "130px" },
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
            }}
          />
        </Box>
      </Box>}
    </Box>
  );
};

export default CompanyDetailsTab;
