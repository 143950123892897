import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AllRoutes from "./Routes/AllRoutes";
import "animate.css/animate.min.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
