import { Box, useTheme, Typography } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// import profile_banner from "../../Assets/images/Profile/profile_banner.webp";
import profile_banner from "../../Assets/images/about_Homepage/searchbanner.webp";

const BannerImage = ({ path }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        height: { xs: "200px", sm: "300px", md: "40vh" },
      }}
    >
      <Box sx={{ height: "100%" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            // filter: "blur(2px)",
            // WebkitFilter: "blur(2px)",
          }}
          src={profile_banner}
          alt="preview"
        />{" "}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "inherit",
          }}
        />
      </Box>
      <Box
        sx={{
          color: theme.palette.text.primary,
          position: "absolute",
          zIndex: 1,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // fontSize: { xs: "24px", lg: "34px", md: "48px" },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: { xs: "24px", lg: "34px", md: "48px" },
            textAlign: "center",
            textTransform: "uppercase"
          }}
          className="fontSnugle"
        >
          {path.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: theme.palette.text.primary,
              fontSize: { xs: "14px", lg: "24px", md: "28px" },
            }}
          >
            {path.home}
          </Typography>
          <ArrowForwardIcon sx={{ color: theme.palette.text.primary }} />{" "}
          <Typography
            sx={{
              textAlign: "center",
              color: theme.palette.text.primary,
              fontSize: { xs: "14px", lg: "24px", md: "28px" },
            }}
          >
            {path.nav}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerImage;
