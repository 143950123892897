import { Box } from "@mui/material";
import ChatListComponent from "./ChatList/ChatListComponent";
import ChatMessageComponent from "./ChatMessage/ChatMessageComponent";
import { useState } from "react";

const ChatScreen = () => {
  const [selectedChatFirebaseId, setSelectedChatFirebaseId] = useState(null);
  const [selectedChatUserId, setSelectedChatUserId] = useState("");
  const [selectedChatUserDetails, setSelectedChatUserDetails] = useState({});
  return (
    <Box
      sx={{
        display: "flex",
        borderTop: "1px solid #0000003D ",
        height: "calc(100vh - 76px)",
        background: "#F5F5F5",
      }}
    >

      {/* user Listing component */}
      <Box
        sx={{
          display: {
            xs: selectedChatFirebaseId ? "none" : "block",
            md: "block",
          },
          width: { xs: "100%", md: "280px" },
        }}
      >
        <ChatListComponent
          selectedChatFirebaseId={selectedChatFirebaseId}
          setSelectedChatFirebaseId={setSelectedChatFirebaseId}
          setSelectedChatUserId={setSelectedChatUserId}
          setSelectedChatUserDetails={setSelectedChatUserDetails}
        />
      </Box>
      {/* user message handler */}
      <Box
        sx={{
          display: {
            xs: selectedChatFirebaseId ? "block" : "none",
            md: "block",
          },
          width: { xs: "100%", md: `calc(100% - 280px)` },
        }}
      >
        <ChatMessageComponent
          selectedChatFirebaseId={selectedChatFirebaseId}
          selectedChatUserId={selectedChatUserId}
          selectedChatUserDetails={selectedChatUserDetails}
          setSelectedChatFirebaseId={setSelectedChatFirebaseId}
          setSelectedChatUserId={setSelectedChatUserId}
          setSelectedChatUserDetails={setSelectedChatUserDetails}
        />
      </Box>

    </Box>
  );
};

export default ChatScreen;
