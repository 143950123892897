import { Box, IconButton } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

const ImageUpload = ({ value, onImageChange = () => { } }) => {
    const handleFileChange = (e) => {
        const file = e.target.files?.[0];

        if (file)
        {
            const validTypes = ["image/jpeg", "image/png"];
            if (!validTypes.includes(file.type))
            {
                toast.error("Only JPG and PNG images are allowed.");
                e.target.value = null;
                return;
            }

            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (file.size > maxSizeInBytes)
            {
                toast.error(
                    `File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
                );
                e.target.value = null;
                return;
            }

            onImageChange(file);
        }

        e.target.value = null;
    };

    return (
        <>
            <Box sx={{ flex: 1, display: "flex", gap: 3, alignItems: "center" }}>
                <label
                    style={{ padding: "0.8rem", cursor: "pointer" }}
                    id="image-upload"
                >
                    <span
                        style={{
                            background: "#DEE6F2",
                            padding: "15px",
                            borderRadius: "50%",
                        }}
                        className="flexCenter"
                    >
                        <AddIcon sx={{ color: "#000" }} />
                    </span>
                    <input
                        type="file"
                        hidden
                        id="image-upload"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </label>

                {value && (
                    <Box sx={{ position: "relative", width: "60px", height: "100%" }}>
                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "-18px",
                                left: "65%",
                                color: "red",
                            }}
                            onClick={() => {
                                onImageChange(null);
                            }}
                        >
                            <img src="../../../Utils/Images/lightpin.png" alt="" />
                        </IconButton>
                        <img
                            src={URL.createObjectURL(value)}
                            alt="preview"
                            style={{ width: "100%", height: "100%", maxHeight: "60px" }}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ImageUpload;
