import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import elecIcon from "../../../../Assets/images/electric.png";
import disIcon from "../../../../Assets/images/distance.png";
import estricIcon from "../../../../Assets/images/estrik.png";
import ButtonComponent from "../../../../Component/Button/Button";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { useTranslation } from "react-i18next";
import RatingStar from "../../../../Component/Rating";
import { useCompanyId } from "../../../../Context/CompanyIdContext";

const TruckCard = ({ data, title, openModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useCompanyId();
  const isOwner = companyId === data?.company_id;

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: 3,
        boxShadow: 3,
        position: "relative",
        mt: 2,
        display: "flex",
        flexDirection: "column",
        // gap: 0.3,
      }}
    >
      <Box
        sx={{
          height: { md: 220 },
          overflow: "hidden",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <img
          src={
            data?.food_truck_image &&
            API_endpoints.baseURL + "/" + data?.food_truck_image[0]?.image
          }
          alt={data?.title || "Truck Image"}
          className="scaleImg"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "fill",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          px: 2,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.dark,
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          {data?.truck_name}
        </Typography>

        <Box
          sx={{
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            display: "- webkit - box",
          }}
        >
          <RatingStar rating={data?.avg_rating} />
        </Box>
        <Box
          sx={{
            height: {
              xs: "80px",
              sm: "80px",
              md: "80px",
            },
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "17px",
            }}
          >
            {data?.description}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          // display: title === "Edit" ? "none" : "flex",
          display: "flex",
          flexDirection: { xs: "row", md: "column", lg: "row" },
          gap: 1,
          px: 2,
          backgroundColor: theme.palette.background.main,
          fontWeight: 600,
          justifyContent: "start",
          alignItems: { xs: "start", lg: "center" },
          fontSize: "17px",
          pt: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <img src={elecIcon} height={18} width={18} alt="Electricity Icon" />
          <Typography
            sx={{ color: theme.palette.text.active, fontWeight: "600" }}
          >
            {data?.electricity_type}
          </Typography>
          {t("myBooking.Electricity")}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <img src={disIcon} height={18} alt="Distance Icon" />
          <Typography
            sx={{ color: theme.palette.text.active, fontWeight: "600" }}
          >
            {data?.size}
          </Typography>{" "}
          {t("myBooking.Meters")}
        </Box>
      </Box>
      <Box
        sx={{
          // display: title === "Edit" ? "none" : "flex",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          //   flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.main,
          fontWeight: 500,
        }}
      >
        <img src={estricIcon} height={13} alt="Water & Electricity Icon" />
        <Typography
          sx={{
            color: theme.palette.text.blue,
            fontWeight: "600",
            fontSize: "17px",
          }}
        >
          Water & Electricity available
        </Typography>
      </Box>
      <Box
        sx={{
          // display: title === "Edit" ? "flex" : "none",
          // display: title === "Edit" ? "flex" : "none",
          display: data?.meal?.length ? "flex" : "none",
          justifyContent: "start",
          alignItems: "center",
          //   flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.main,
          fontWeight: 500,
        }}
      >
        <Typography sx={{ color: theme.palette.text.blue, fontWeight: "600" }}>
          Meals
        </Typography>
      </Box>

      {/* Category Badge */}

      <Box
        sx={{
          display: data?.meal?.length ? "flex" : "none",
          justifyContent: "start",
          // py: 1,
          gap: 1,
          px: 2,
          pb: 2,
          flexWrap: "wrap",
          backgroundColor: theme.palette.background.main,
          height: "100px",
          overflow: "auto",
        }}
      >
        {data?.meal?.map(
          (x, i) =>
            x?.status === 1 && (
              <Typography
                key={i}
                sx={{
                  backgroundColor: theme.palette.background.label,
                  padding: 1,
                  borderRadius: 1,
                  color: theme.palette.text.label,
                  fontWeight: 400,
                  height: "fit-content",
                }}
              >
                {x?.meal_name}
              </Typography>
            )
        )}
      </Box>
      <Box
        className="flexCenter"
        sx={{
          p: 2,
          pt: 0,
          backgroundColor: theme.palette.background.main,
          borderRadius: 3,
        }}
      >
        <Box
          className="flexCenter"
          sx={{
            p: 2,
            pt: 0,
            backgroundColor: theme.palette.background.main,
            borderRadius: 3,
            width: { xs: "100%", sm: "80%", md: "100%" },
            m: "auto",
          }}
        >
          <ButtonComponent
            onClick={() =>
              isOwner
                ? openModal(data)
                : navigate(`${NavRoutes.TRUCK_DETAILS}/${data?.id}`, {
                  state: { id: data?.id, company_id: data?.company_id },
                })
            }
            // label={t("myBooking.booknow")}
            label={title}
            sx={{
              width: "100%",
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TruckCard;
