import { Box, useTheme, TextField, Typography } from "@mui/material";
import BannerImage from "../../Component/BannerImage/BannerImage";
import call from "../../Assets/images/contact/call.png";
import loc from "../../Assets/images/contact/location.png";
import mail from "../../Assets/images/contact/mail.png";
import Img from "../../Assets/images/1.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import sideimg1 from "../../Assets/images/strip1.png";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../Component/Button/Button";
import { useEffect, useState } from "react";
import { API_GET, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { toast } from "react-toastify";
import Loader from "../../Component/Loader/Loader";

const ReachUsScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [footerData, setFooterData] = useState({});

  const getData = async () => {
    try {
      const res = await API_GET(API_endpoints.getFooterContent);
      res?.s && setFooterData(res?.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      number: "",
      subject: "",
      description: "",
    },

    validationSchema: Yup.object({
      description: Yup.string()
        .required(t("bookNowForm.required"))
        .min(5, t("bookNowForm.inValid")),
      subject: Yup.string()
        .required(t("bookNowForm.required"))
        .min(5, t("bookNowForm.inValid")),

      full_name: Yup.string()
        .required(t("bookNowForm.required"))
        .min(3, t("bookNowForm.inValid")),
      email: Yup.string()
        .required(t("bookNowForm.required"))
        .email(t("bookNowForm.inValid")),
      number: Yup.string()
        .required(t("bookNowForm.required"))
        .matches(
          /^(\+?\d{1,4}|\d{1,4})?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
          t("bookNowForm.inValid")
        ),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("full_name", values.full_name);
        formdata.append("email", values.email);
        formdata.append("phno", values.number);
        formdata.append("subject", values.subject);
        formdata.append("message", values.description);
        let res = await API_POST(API_endpoints.sendcontactus, formdata);
        if (res?.s) {
          toast.success(res.m);
          formik.resetForm();
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        return false;
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  return (
    // <Work />
    <Box>
      <BannerImage
        path={{
          title: t("contact.fiesta"),
          home: t("addtruck.MyFoodTruck.home"),
          nav: t("contact.contactUs"),
        }}
      />
      <Box sx={{ background: theme.palette.background.gd }}>
        <Box
          sx={{
            m: "auto",
            position: "relative",
            py: { xs: 3, md: 8 },
          }}
        >
          {/* Form  */}
          <Box
            sx={{
              width: { xs: "100%", md: "95%" },

              display: "flex",
              justifyContent: { xs: "center", md: "end" },
              alignItems: "center",
              position: { md: "absolute" },
              top: "auto",
              height: "calc(100vh - 80px)",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                borderRadius: "32px",
                background: theme.palette.background.main,
                width: { xs: "90%", md: "calc(50%)", xl: "calc(40%)" },
                p: { xs: "22px", sm: "40px" },
                height: {
                  md: "calc(100vh - 180px)",
                  xl: "calc(100vh - 250px)",
                },
                position: { md: "absolute" },
                overflow: { md: "scroll" },
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="fontSnugle"
                sx={{
                  textAlign: "center",
                  pb: 2,
                  color: theme.palette.text.brown,
                  fontSize: { xs: "22px", md: "22px", lg: "26px" },
                }}
              >
                {t("contact.contactUs")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      type="text"
                      id="full_name"
                      name="full_name"
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;
                        e.target.value = value.trimStart().trimEnd();
                        formik.handleChange(e);
                      }}
                      // onBlur={formik.handleBlur}
                      value={formik.values.full_name}
                      error={
                        formik.touched.full_name &&
                        Boolean(formik.errors.full_name)
                      }
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                      }
                      variant="outlined"
                      placeholder={t("contact.fullname")}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      type="email"
                      id="email"
                      name="email"
                      fullWidth
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      variant="outlined"
                      placeholder={t("contact.email")}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      type="number"
                      id="number"
                      name="number"
                      fullWidth
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      value={formik.values.number}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                      variant="outlined"
                      placeholder={t("contact.number")}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <TextField
                      type="text"
                      id="subject"
                      name="subject"
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;
                        e.target.value = value.trimStart().trimEnd();
                        formik.handleChange(e);
                      }}
                      // onBlur={formik.handleBlur}
                      value={formik.values.subject}
                      error={
                        formik.touched.subject && Boolean(formik.errors.subject)
                      }
                      helperText={
                        formik.touched.subject && formik.errors.subject
                      }
                      variant="outlined"
                      placeholder={t("contact.subject")}
                      InputProps={{
                        sx: {
                          borderRadius: "12px",
                          color: theme.palette.text.defaultDark,
                          "&:hover": {
                            color: theme.palette.text.active,
                            bgcolor: theme.palette.background.inputBg,
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    // onBlur={formik.values.description}
                    value={formik.values.description}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    variant="outlined"
                    placeholder={t("contact.desc")}
                    multiline
                    fullWidth
                    rows={5}
                    InputProps={{
                      sx: {
                        borderRadius: "12px",
                        color: theme.palette.text.defaultDark,
                        "&:hover": {
                          color: theme.palette.text.active,
                          bgcolor: theme.palette.background.inputBg,
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </Box>

                <Box className="flexCenter" sx={{ width: "100%" }}>
                  <ButtonComponent
                    type="button"
                    disabled={loading}
                    onClick={formik.handleSubmit}
                    label={loading ? <Loader /> : `${t("contact.btn")}`}
                    sx={{
                      width: { xs: "100%", sm: "60%", md: "250px" },
                      background: theme.palette.background.btn,
                      color: theme.palette.text.primary,
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* main img */}
          <Box
            sx={{
              width: { xs: "65%" },
              display: { xs: "none", md: "block" },
              height: "calc(100vh - 100px)",
            }}
          >
            <img src={Img} height={"100%"} width={"100%"} alt="" />
          </Box>
          {/* side strip img */}
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: { xs: "20px", md: "50px" },
              zIndex: 0,
            }}
          >
            <img src={sideimg1} alt="" height={40} />
          </Box>
        </Box>
        {/* cards */}
        <Box
          sx={{
            padding: "0 30px 50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            gap: 5,
          }}
        >
          {/* call */}
          <Box
            sx={{
              borderRadius: "20px",
              background: theme.palette.background.main,
              p: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 2,
              height: { md: "180px" },
              textWrap: "wrap",
              width: { xs: "100%", sm: "85%", md: "350px" },
            }}
          >
            <Box className="flexCenter">
              <Box
                sx={{
                  height: { xs: "50px", md: "50px" },
                  width: { xs: "50px", md: "50px" },
                }}
              >
                <img src={call} height={"100%"} width={"100%"} alt="" />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {footerData?.phno}
              </Typography>
            </Box>
          </Box>
          {/* location */}
          <Box
            sx={{
              borderRadius: "20px",
              background: theme.palette.background.main,
              p: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 2,
              height: { md: "180px" },
              textWrap: "wrap",
              width: { xs: "100%", sm: "85%", md: "350px" },
            }}
          >
            <Box className="flexCenter">
              <Box
                sx={{
                  height: { xs: "50px", md: "50px" },
                  width: { xs: "50px", md: "50px" },
                }}
              >
                <img src={loc} height={"100%"} width={"100%"} alt="" />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {footerData?.address}
              </Typography>
            </Box>
          </Box>
          {/* mail */}
          <Box
            sx={{
              borderRadius: "20px",
              background: theme.palette.background.main,
              p: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 2,
              height: { md: "180px" },
              textWrap: "wrap",
              width: { xs: "100%", sm: "85%", md: "350px" },
            }}
          >
            <Box className="flexCenter">
              <Box
                sx={{
                  height: { xs: "50px", md: "50px" },
                  width: { xs: "50px", md: "50px" },
                }}
              >
                <img src={mail} height={"100%"} width={"100%"} alt="" />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "20px" },
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {footerData?.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReachUsScreen;
