import {
    Box,
    Modal,
    Typography,
    CircularProgress,
    Button,
    useTheme,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const SendImageModal = ({
    openSendImage,
    uploadedImage,
    sendImageClose,
    handleSendMessage,
    loading,
}) => {
    const theme = useTheme()
    return (
        <>
            <Modal
                open={openSendImage}
                onClose={sendImageClose}
                sx={{
                    width: { xs: "90%", sm: "100%" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // p: "10px",
                    "&:focus-visible": {
                        outline: "none",
                        border: "none",
                    },
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        borderRadius: "15px",
                        background: "#FFFFFF",
                        color: "#fff",
                        position: "relative",
                        "&:focus-visible": {
                            outline: "none",
                            border: "none",
                        },
                    }}
                >
                    {/* <Box
                        sx={{
                            position: "absolute",
                            right: { xs: 3, sm: 3 },
                            top: { xs: 3, sm: 3 },
                            p: "5px",
                        }}
                        onClick={sendImageClose}
                    >
                        <CloseIcon sx={{ color: "#FFF", cursor: "pointer" }} />
                    </Box> */}
                    <Box
                        sx={{
                            mx: "auto",
                            width: { xs: "90%", sm: "300px" },
                        }}
                    >
                        {uploadedImage && (
                            <img
                                src={URL.createObjectURL(uploadedImage)}
                                alt="Preview"
                                style={{ width: "300px", height: "300px", marginTop: "10px", borderRadius: "12px" }}
                            />
                        )}
                    </Box>
                    <Box className="flexCenter" style={{ gap: 2, justifyContent: "space-between", width: "100%", }}>
                        <Button

                            sx={{
                                background: theme.palette.background.btn,
                                mt: "10px",
                                borderRadius: "10px",
                                p: "10px",
                                paddingX: "30px",
                                color: "white",
                                textTransform: "none",
                            }}
                            onClick={sendImageClose}
                        >

                            <Typography sx={{ fontSize: "16px !important" }}>
                                Cancel
                            </Typography>

                        </Button>
                        <Button
                            bgColor="#1B4DC0"
                            hoverColor="#1B4DC0"
                            sx={{
                                background: theme.palette.background.orange,
                                mt: "10px",
                                borderRadius: "10px",
                                p: "10px",
                                paddingX: "30px",
                                color: "#fff"
                            }}
                            onClick={handleSendMessage}
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{ color: "white", m: "auto" }}
                                    size={20}
                                />
                            ) : (
                                <>
                                    <Typography sx={{ fontSize: "16px !important" }}>
                                        Send
                                    </Typography>{" "}
                                    <SendRoundedIcon sx={{ ml: "10px" }} />
                                </>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default SendImageModal;
