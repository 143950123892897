export const myCompanyDetailsScreen_EN_Trans = {
  company: "Company Name",
  city: "Company Name",
  code: "Postal Code",
  link: "Web-link",
  about: "More about company",
  meals: "Types of meals",
  diets: "Types of diets",
  certificate: "Certificate",
  myTrucks: "My Food Trucks",
  add: "Add Truck",
  noData: "No Truck found",
  edit: "Edit",
};

export const myCompanyDetailsScreen_DE_Trans = {
  company: "Unternehmensname",
  city: "Stadt",
  code: "Postleitzahl",
  link: "Web-Link",
  about: "Mehr über das Unternehmen",
  meals: "Art der Mahlzeiten",
  diets: "Arten von Diäten",
  certificate: "Zertifikat",
  myTrucks: "Meine Caterer",
  add: "Truck hinzufügen",
  noData: "Kein Truck gefunden",
  edit: "Bearbeiten",
};

export const myCompanyDetailsScreen_NL_Trans = {
  company: "Bedrijfsnaam",
  city: "Stad",
  code: "Postcode",
  link: "Web-link",
  about: "Meer over het bedrijf",
  meals: "Soorten maaltijden",
  diets: "Soorten diëten",
  certificate: "Certificaat",
  myTrucks: "Mijn Foodtrucks",
  add: "Truck toevoegen",
  noData: "Geen truck gevonden",
  edit: "bewerken",
};

export const myCompanyDetailsScreen_ES_Trans = {
  company: "Nombre de la empresa",
  city: "Ciudad de la empresa",
  code: "Código postal",
  link: "Enlace web",
  about: "Más sobre la empresa",
  meals: "Tipos de comidas",
  diets: "Tipos de dietas",
  certificate: "Certificado",
  myTrucks: "Mis Food Trucks",
  add: "Agregar camión",
  noData: "No se encontró ningún camión",
  edit: "Editar",
};
