import { Box, Typography, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/images/drag.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_endpoints } from "../../API/API_Endpoints";
import { API_POST } from "../../API/config";

export default function MultiImageUpload({
  setImages,
  handleUserData,
  error,
  imgTitle,
  max,
  formik,
  apiPreviews = [],
}) {
  const [previews, setPreviews] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [titles, setTitles] = useState({});
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const validFiles = acceptedFiles.filter(
        (file) => file.size <= 5 * 1024 * 1024
      );

      if (validFiles.length !== acceptedFiles.length)
      {
        toast.error("Some files were too large. Max size is 5MB.");
      }

      const totalImages = formik.values.images.length + validFiles.length;
      if (totalImages > max)
      {
        toast.error(`You can only upload a maximum of ${max} images.`);
        return;
      }

      const newPreviews = validFiles.map((file) => {
        const url = URL.createObjectURL(file);
        return { url, file, title: "" };
      });

      // Update previews and uploaded images
      setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      const combinedImages = [...formik.values.images, ...validFiles];
      setUploadedImages(combinedImages); // Update local state

      // Update Formik field with the new combined images
      formik.setFieldValue("images", combinedImages);
    },
  });

  const handleRemoveImage = (index) => {
    const updatedPreviews = previews.filter((_, i) => i !== index);
    const updatedImages = uploadedImages.filter((_, i) => i !== index);

    console.log(updatedImages, "updatedImages")
    console.log("uploadedImages", uploadedImages)

    setPreviews(updatedPreviews);
    setUploadedImages(updatedImages);

    if (setImages)
    {
      setImages(updatedImages);
    }
    formik.setFieldValue("images", updatedImages);
  };

  const handleRemoveImagefromApi = async (data) => {
    const f = new FormData();
    f.append("id", data.id);
    f.append("type", data.type);
    f.append("status", 0);
    try
    {
      let res = await API_POST(API_endpoints.deleteImage, f);
      if (res?.s === 1)
      {
        handleRemoveImage(data.id)
        handleUserData();
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleTitleChange = (e, index) => {
    const { value } = e.target;
    setTitles((prevTitles) => ({
      ...prevTitles,
      [index]: value,
    }));

    // Update the Formik state with the new title
    formik.setFieldValue(`certificates[${index}].title`, value);

    // Update previews to reflect the new title
    setPreviews((prevPreviews) =>
      prevPreviews.map((preview, i) =>
        i === index ? { ...preview, title: value } : preview
      )
    );
  };

  return (
    <div>
      <Box
        p={2}
        m={"auto"}
        width={"100%"}
        maxWidth={"540px"}
        border={error ? "1px dashed #d32f2f" : "1px dashed #F57C00"}
        borderRadius="10px"
        sx={{
          background: `#FFFAF5`,
        }}
      >
        <div
          {...getRootProps()}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <input
            {...getInputProps()}
            multiple
            disabled={uploadedImages.length >= max}
          />
          <img
            src={upload}
            alt="upload-placeholder"
            width="100px"
            height="100px"
          />
          <Typography variant="body1" textAlign="center">
            {t("becomePartner.step2.foodTruckDetails.image.drag")}
          </Typography>
        </div>
      </Box>

      {(previews.length > 0 || apiPreviews.length > 0) && (
        <Box mt={2} display="flex" flexWrap="wrap" gap={2} justifyContent={"center"}>
          {/* Render API Previews */}
          {apiPreviews?.map((apiPreview, index) => (
            <Box key={`api-${index}`} width="100px" height="150px" position="relative">
              <img
                src={`${API_endpoints.baseURL}/${apiPreview.image}`}
                alt={`api-preview-${index}`}
                style={{
                  width: "100%",
                  height: "90px",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
              {imgTitle && (
                <TextField
                  InputProps={{
                    style: { color: "black" },
                  }}
                  value={titles[`api-${index}`] || apiPreview.name || ""}
                  onChange={(e) => handleTitleChange(e, `api-${index}`)}
                  placeholder={t("becomePartner.step4.certi.pl")}
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%", mt: 1, color: "black" }}
                />
              )}
              <IconButton
                onClick={() => handleRemoveImagefromApi(apiPreview)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "-10px",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <DeleteIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          ))}

          {/* Render Uploaded Previews */}
          {previews.map((preview, index) => (
            <Box key={index} width="100px" height="150px" position="relative">
              <img
                src={preview.url}
                alt={`preview-${index}`}
                style={{
                  width: "100%",
                  height: "90px",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />

              <IconButton
                onClick={() => handleRemoveImage(index)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "-10px",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <DeleteIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
}
