import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import del from "../../../../Assets/images/delete.svg";
import add from "../../../../Assets/images/addImage.svg";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { useFormik } from "formik";
import { API_GET_OPEN, API_GET_USERID, API_POST } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { toast } from "react-toastify";
import * as Yup from "yup";
const OwnerImageTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isHovered, setisHovered] = useState(null);
  const [imageType, setImageType] = useState(0);
  const [newImageName, setNewImageName] = useState("");
  const [newCertificate, setNewCertificate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [companyID, setCompanyID] = useState('')
  const userData = JSON.parse(localStorage.getItem("Fiesta"));


  const fileInputRef = useRef(null);
  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (imageType === 1)
    {
      const updatedImages = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        isLocal: true,
      }));
      formik.setFieldValue("images", [
        ...formik.values.images,
        ...updatedImages,
      ]);
    } else
    {
      // const updatedCertificates = files.map((file) => ({
      //   file,
      //   name: newImageName,
      //   preview: URL.createObjectURL(file),
      //   isLocal: true,
      // }));

      const file = files[0];
      if (file)
      {
        setNewCertificate({
          file,
          preview: URL.createObjectURL(file),
          isLocal: true,
        });
      }
    }
  };

  const handleDeleteImage = async (index, type) => {
    let valuesKey = type === 1 ? "images" : "certificates";
    let items = formik.values[valuesKey];

    if (items[index]?.id)
    {
      try
      {
        setLoading(true);
        const f = new FormData();
        f.append("id", items[index]?.id);
        f.append("type", type);
        f.append("status", 0);
        let res = await API_POST(API_endpoints.deleteImage, f);
        res?.s && toast.success(res.m);
      } catch (error)
      {
        console.error("Failed to delete image:", error);
      } finally
      {
        setLoading(false);
      }
    }

    // Remove the image from the local state
    const updatedItems = items.filter((_, i) => i !== index);
    formik.setFieldValue(valuesKey, updatedItems);
  };
  // Add the certificate to Formik state
  const handleCertificatechange = async () => {
    if (newCertificate && newImageName)
    {
      const updatedCertificate = {
        ...newCertificate,
        name: newImageName,
      };
      try
      {
        let formData = new FormData();
        formData.append("company_id", companyID);
        formData.append("type", 2);
        formData.append("image", updatedCertificate.file);
        formData.append("name", updatedCertificate.name);
        await API_POST(API_endpoints.addIandC, formData);

        formik.setFieldValue("certificates", [
          ...formik.values.certificates,
          updatedCertificate,
        ]);
        getData(companyID);
        setNewCertificate(null);
        setNewImageName("");
      } catch (error)
      {
        console.log(error);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      user_id: "",
      images: [],
      certificates: [],
    },
    validationSchema: Yup.object({
      images: Yup.array().min(1, t("Login.required")),
      // certificates: Yup.array()
      //   //   .of(
      //   //     Yup.object().shape({
      //   //       file: Yup.mixed().required(t("Login.required")),
      //   //       // name: Yup.string()
      //   //       //   .required(t("Login.required"))
      //   //       //   .min(6, "Required at least 6 digits.")
      //   //       //   .max(20, "Maximum 20 digits allowed"),
      //   //       preview: Yup.string().required(t("Login.required")),
      //   //     })
      //   //   )
      //   .min(1, t("Login.required")),
    }),
    onSubmit: async (values) => {
      let isSubmitted = false;
      try
      {
        setLoading(true);
        for (let i = 0; i < values.images.length; i++)
        {
          const image = values.images[i];
          if (image.isLocal)
          {
            let formData = new FormData();
            formData.append("company_id", companyID);
            formData.append("type", 1);
            formData.append("image", image.file);
            await API_POST(API_endpoints.addIandC, formData);
          }
        }
        // for (let i = 0; i < values.certificates.length; i++) {

        // }
        isSubmitted = true;
        if (isSubmitted)
        {
          toast.success("Submitted successfully");
        }
      } catch (error)
      {
        console.error("Error:", error);
      } finally
      {
        setLoading(false);
      }
    },
  });

  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res.s)
      {
        const id = res?.r?.company_details?.id;
        setCompanyID(id);
        getData(id)
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  const getData = async (id) => {
    try
    {
      setIsLoading(true);
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );
      if (res?.s)
      {
        formik.setValues({
          images: res?.r?.image
            .filter((item) => item.type === 1)
            .map((item) => ({ ...item, isLocal: false })),
          certificates: res?.r?.image
            .filter((item) => item.type === 2)
            .map((item) => ({ ...item, isLocal: false })),
          user_id: res?.r?.user_id,
          id: res?.r?.id,
        });
        setIsLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  return isLoading ? (
    <Box sx={{ minHeight: "300px" }} className="flexCenter">
      <CircularProgress style={{ color: "#f57c00" }} />
    </Box>
  ) : (
    <>
      <Box sx={{ my: 2 }}>
        <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
          {t("ownerProfile.profile.company")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            p: 2,
            my: 1,
            borderRadius: "12px",
            border: "1px solid #1A130C28",
            flexWrap: "wrap",
          }}
        >
          {formik?.values?.images?.map((x, i) => (
            <Box
              key={i}
              onMouseEnter={() => setisHovered("i-" + i)}
              onMouseLeave={() => setisHovered(null)}
              sx={{
                height: "100px",
                width: "175px",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <img
                src={x.isLocal ? x.preview : API_endpoints.baseURL + x.image}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "14px",
                  objectFit: "cover",
                }}
              />
              {/* Show delete button only if there's more than one image */}
              {formik?.values?.images?.length > 1 && (
                <img
                  src={del}
                  alt="Delete"
                  onClick={() => handleDeleteImage(i, 1)}
                  style={{
                    display: isHovered === "i-" + i ? "block" : "none",
                    height: "35px",
                    width: "35px",
                    position: "absolute",
                    right: "-12px",
                    top: "-12px",
                    borderRadius: "14px",
                    cursor: "pointer",
                  }}
                />
              )}
            </Box>
          ))}
          <Box
            sx={{
              height: "100px",
              width: "175px",
              borderRadius: "12px",
              position: "relative",
            }}
            onClick={() => {
              handleBoxClick();
              setImageType(1);
            }}
          >
            <img
              src={add}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "14px",
              }}
            />{" "}
          </Box>{" "}
        </Box>
        {formik.touched.images && formik.errors.images ? (
          <div style={{ color: "red" }}>
            {formik.errors && <div> {formik.errors.images} </div>}
          </div>
        ) : null}
      </Box>

      <Box className="flexCenter" sx={{ my: 5 }}>
        {/* images uploade button */}
        <ButtonComponent
          disabled={loading}
          type="button"
          label={loading ? <Loader /> : `${t("ownerProfile.profile.update")}`}
          onClick={formik.handleSubmit}
          sx={{
            width: { xs: "80%", sm: "130px" },
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography sx={{ fontSize: { xs: "18px" }, fontWeight: "500" }}>
          {t("ownerProfile.profile.certi")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            p: 2,
            my: 1,
            borderRadius: "12px",
            border: "1px solid #1A130C28",
            flexWrap: "wrap",
          }}
        >
          {formik?.values?.certificates?.map((x, i) => (
            <Box
              key={i}
              onMouseEnter={() => setisHovered("c-" + i)}
              onMouseLeave={() => setisHovered(null)}
              sx={{
                height: "100px",
                width: "175px",
                borderRadius: "12px",
                position: "relative",
              }}
            >
              <img
                src={x.isLocal ? x.preview : API_endpoints.baseURL + x.image}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "14px",
                  objectFit: "cover",
                }}
              />
              <Typography sx={{ textAlign: "center" }}>{x?.name}</Typography>{" "}
              <img
                src={del}
                alt=""
                onClick={() => handleDeleteImage(i, 2)}
                style={{
                  display:
                    formik?.values?.certificates.length > 1 &&
                      isHovered === "c-" + i
                      ? "block"
                      : "none",
                  height: "35px",
                  width: "35px",
                  position: "absolute",
                  right: "-12px",
                  top: "-12px",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
              />
            </Box>
          ))}

          <Box
            sx={{
              height: "200px",
              width: "175px",
              borderRadius: "12px",
              position: "relative",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {newCertificate ? (
              <img
                src={newCertificate.preview}
                alt="Preview"
                style={{
                  height: "100px",
                  width: "100%",
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                src={add}
                alt="Upload"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "12px",
                }}
                onClick={handleBoxClick}
              />
            )}

            <TextField
              fullWidth
              sx={{ color: "#000", maxWidth: "540px" }}
              value={newImageName}
              onChange={(e) => setNewImageName(e.target.value)}
              placeholder={t("becomePartner.step4.certi.pl")}
              variant="outlined"
              size="small"
              InputProps={{
                sx: {
                  borderRadius: "8px",
                  color: theme.palette.text.defaultDark,
                },
              }}
            />

            <ButtonComponent
              disabled={loading}
              type="button"
              label={loading ? <Loader /> : `${t("ownerProfile.profile.update")}`}
              onClick={handleCertificatechange}
              sx={{
                width: "100%",
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />

            <input
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
            />
          </Box>
        </Box>

        {formik.touched.certificates && formik.errors.certificates ? (
          <div style={{ color: "red" }}>
            {formik.errors && <div>{formik.errors.certificates}</div>}
          </div>
        ) : null}
      </Box>
    </>
  );
};

export default OwnerImageTab;
