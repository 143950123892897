import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import BannerImage from "../../Component/BannerImage/BannerImage";
import DashboardTab from "./Dashboard/DashboardTab";
import { useTranslation } from "react-i18next";
import MyProfileTab from "./MyProfile/MyProfileTab";
import MyPaymentTab from "./MyPayment/MyPaymentTab";
import CustomerReviewsTab from "./CustomerReviews/CustomerReviewsTab";
import MyBookingTab from "./MyBookings/MyBookingTab";
import { useEffect, useState } from "react";
import MyCompanyTab from "./MyCompany/MyCompanyTab";
import ButtonComponent from "../../Component/Button/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import SingleImage from "../../Component/ImageUploader/SingleImage";
import { API_GET_USERID, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useFormik } from "formik";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import MyOrderBookingTab from "./OwnerOrder/OwnerOrderTab";
import { useCompanyId } from "../../Context/CompanyIdContext";
import ConfirmationModal from "../../Component/Modal/ConfirmModal";

const TruckOwnerProfileScreen = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const [Loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const nav = useNavigate();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let UserID = parseData?.id;
  const location = useLocation();
  const { toggleRender, setCompanyId } = useCompanyId();

  const handleUserData = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, UserID);
      formik.setFieldValue("profile", res.r?.profile_img);
      formik.setFieldValue("fullname", res.r?.full_name);
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const tabList = [
    {
      id: 0,
      label: t("ownerProfile.dash.title"),
      data: <DashboardTab />,
    },
    {
      id: 1,
      label: t("ownerProfile.profile.title"),
      data: <MyProfileTab handleUserDataUpdate={handleUserData} />,
    },
    {
      id: 2,
      label: t("ownerProfile.company.title"),
      data: <MyCompanyTab />,
    },
    {
      id: 3,
      label: t("ownerProfile.payment.title"),
      data: <MyPaymentTab />,
    },
    {
      id: 4,
      label: t("ownerProfile.review.title"),
      data: <CustomerReviewsTab />,
    },
    {
      id: 5,
      label: t("ownerProfile.booking.title"),
      data: <MyBookingTab />,
    },
    {
      id: 6,
      label: t("ownerProfile.order.title"),
      data: <MyOrderBookingTab />,
    },
  ];

  const handleLogout = () => {
    const event = new CustomEvent("authUpdate", {
      detail: { isLoggedIn: false },
    });
    window.dispatchEvent(
      new CustomEvent("userUpdate", { detail: { role: 1 } })
    );
    window.dispatchEvent(event);
    localStorage.clear();
    toast.success(t("Logout Successfully"));
    nav(NavRoutes.HOME);
    toggleRender()
    setCompanyId(null)

  };

  const formik = useFormik({
    initialValues: { profile: null, fullname: "" },
    onSubmit: async (values) => {
      if (values?.profile?.name)
      {
        const f = new FormData();
        f.append("user_id", UserID);
        f.append("profile_img", values.profile);

        setTimeout(() => {
          console.log(values.profile, "ccc");
        }, 300);

        try
        {
          const res = await API_POST(API_endpoints.updateuser, f);
          if (res.s)
          {
            handleUserData();
          } else
          {
            toast.error(res.m);
          }
        } catch (error)
        {
          console.error("Error:", error);
        }
      }
    },
  });

  useEffect(() => {
    handleUserData();
  }, []);

  useEffect(() => {
    location.state?.editCompany && setCurrentTab(location.state?.editCompany);
  }, [location.state?.editCompany]);

  return (
    <Box>
      <BannerImage
        path={{
          title: t("contact.fiesta"),
          home: t("addtruck.MyFoodTruck.home"),
          nav: t("ownerProfile.myProfile"),
        }}
      />

      <Box sx={{ background: theme.palette.background.gd, width: "100%" }}>
        {Loading ? (
          <Box sx={{ minHeight: "300px" }} className="flexCenter">
            <CircularProgress style={{ color: "#f57c00" }} />
          </Box>
        ) : (
          <Box sx={{ width: "90%", m: "auto", maxWidth: "1400px" }}>
            {/* Profile Image, Full Name, and Partner Info */}
            <Box
              sx={{
                background: theme.palette.background.main,
                width: "100%",
                m: "auto",
                height: { xs: "200px", md: "160px" },
                borderBottomLeftRadius: "35px",
                borderBottomRightRadius: "35px",
                position: "relative",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "-60px", md: "-60px" },
                  }}
                >
                  <Box
                    sx={{
                      width: "120px",
                      height: "120px",
                      cursor: "pointer",
                      // overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: "auto",
                    }}
                  >
                    <SingleImage
                      apiPreview={
                        typeof formik.values.profile === "string"
                          ? API_endpoints?.baseURL + formik.values.profile
                          : null
                      }
                      setImage={formik.setFieldValue}
                      name="profile"
                      autoSubmit={formik.handleSubmit}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        background: theme.palette.background.orange,
                        padding: { xs: "8px 12px", md: "10px 20px" },
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: theme.palette.text.defaultLight,
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                          fontSize: { xs: "16px", md: "18px" },
                        }}
                      >
                        {formik.values.fullname}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Logout Button */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "18px", md: "22px" },
                      fontWeight: 700,
                    }}
                  >
                    {t("ownerProfile.title")}
                  </Typography>

                  <Box>
                    <ButtonComponent
                      label={`${t("Navbar.mytrucks")}`}
                      onClick={() => nav(NavRoutes.MY_COMPANY)}
                      sx={{
                        // border: `1px solid ${theme.palette.background.btn}`,
                        padding: { xs: "5px 15px", md: "8px 20px" },
                        fontSize: { xs: "12px", md: "14px" },
                        bgcolor: "#164FA114",
                        color: "#164FA1",
                      }}
                    />{" "}
                    <ButtonComponent
                      label={`${t("Navbar.Logout")}`}
                      onClick={() => {
                        setOpen(true);
                      }}
                      sx={{
                        border: `1px solid ${theme.palette.background.orange}`,
                        padding: { xs: "5px 15px", md: "8px 20px" },
                        fontSize: { xs: "12px", md: "14px" },
                        color: theme.palette.text.active,
                      }}
                    />
                  </Box>
                  {/* <ProfileMenu /> */}
                </Box>
              </Box>
            </Box>

            {/* Tab Section */}
            <Box>
              {/* Tab Headers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: { xs: 3, md: 5 },
                  py: { xs: 2, md: 3 },
                  px: 4,
                  overflow: "auto",
                }}
              >
                {tabList.map((e, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color:
                        e.id === currentTab
                          ? theme.palette.text.active
                          : "inherit",
                      fontWeight: e.id === currentTab ? 600 : "inherit",
                      fontSize: { xs: "18px", md: "20px" },
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      "&:hover": {
                        color: theme.palette.text.active,
                      },
                    }}
                    onClick={() => setCurrentTab(e.id)}
                  >
                    {e.label}
                  </Box>
                ))}
              </Box>

              {/* Tab Content */}
              <Box sx={{ pb: 5 }}>
                <Box
                  sx={{
                    background: theme.palette.background.main,
                    width: "100%",
                    borderRadius: "35px",
                    p: { xs: 2, md: 4 },
                  }}
                >
                  {tabList[currentTab].data}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmationModal
        // btnImg={}
        open={open}
        setOpen={setOpen}
        handleClick={handleLogout}
        cnfBtnTitle={t("myProfile.logout")}
      // status={""}
      />
    </Box>
  );
};

export default TruckOwnerProfileScreen;
