import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { useTranslation } from "react-i18next";
import {  API_GET2 } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  maxHeight: "calc(100vh - 100px)",
  overflow: "scroll",
};

export default function FoodTruckDetailsModal({
  open,
  setOpen,
  SelectedBookingId,
}) {
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const res = await API_GET2(
        API_endpoints.getBookingDetailsById + SelectedBookingId
      );
      res.s && setData(res?.r);
    } catch (error) {
      console.log(error);
    }
  };

  const dataBoxStyle = {
    background: "#DEE6F252",
    width: "100%",
    height: "Hug(55px)px",
    padding: "14px",
    gap: "10px",
    borderRadius: "12px",
    color: "#164FA1",
    fontWeight: "600",
  };

  useEffect(() => {
    fetchData();
  }, [SelectedBookingId]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{ ...style, width: { xs: "90%", md: "calc(100% - 50px)" }, p: 5 }}
        >
          <Box>
            <Box className="flexBetween">
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { xs: "18px", md: "22px", lg: "26px" },
                  color: theme.palette.text.active,
                }}
              >
                {t("ownerProfile.booking.myBooking.title")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 5 },
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 5 },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: { xs: 2, sm: 2 },
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("ownerProfile.booking.myBooking.city")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.city_name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("ownerProfile.booking.myBooking.postal")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.zip_code}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1} sx={{ fontWeight: "500" }}>
                    {t("ownerProfile.booking.myBooking.add")}
                  </Box>
                  <Box
                    sx={{
                      ...dataBoxStyle,
                      height: "160px",
                      overflow: "scroll",
                    }}
                  >
                    <Typography>{data?.address}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 5 },
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 5 },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1} sx={{ fontWeight: "500" }}>
                    {t("ownerProfile.booking.myBooking.meal")}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1.5,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {data &&
                      data?.booked_meal?.map((x, i) => (
                        <Box
                          key={i}
                          sx={{ ...dataBoxStyle, width: "fit-content" }}
                        >
                          <Typography>{x?.meal_name}</Typography>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1} sx={{ fontWeight: "500" }}>
                    {t("ownerProfile.booking.myBooking.Type")}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1.5,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {data?.booked_diet?.map((x, i) => (
                      <Box
                        key={i}
                        sx={{ ...dataBoxStyle, width: "fit-content" }}
                      >
                        <Typography>{x?.diet_name}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 5 },
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 5 },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box py={1} sx={{ fontWeight: "500" }}>
                    {t("ownerProfile.booking.myBooking.date")}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1.5,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {[data?.from_date, data?.to_date].map((x, i) => (
                      <Box
                        key={i}
                        sx={{
                          ...dataBoxStyle,
                          width: "fit-content",
                          display: "flex",
                        }}
                      >
                        <Typography>{x}</Typography>
                        <CalendarMonthRoundedIcon
                          style={{ color: "#F57C00" }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: { xs: 2, sm: 2 },
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("ownerProfile.booking.myBooking.Contact")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.city_name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.email}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.phno}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 2, sm: 5 },
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: 2, sm: 5 },
                  flexDirection: "row",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1.5,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Typography>
                        {t("ownerProfile.booking.myBooking.person")}
                      </Typography>
                      <Box
                        sx={{ ...dataBoxStyle, width: "fit-content", mt: 1 }}
                      >
                        <Typography>
                          {data?.no_of_person}{" "}
                          {t("ownerProfile.booking.myBooking.person")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "flex-end",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1.5,
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Typography>
                        {t("ownerProfile.booking.myBooking.budget")}
                      </Typography>
                      <Box
                        sx={{ ...dataBoxStyle, width: "fit-content", mt: 1 }}
                      >
                        <Typography>
                          {data?.budget_per_person} Persons
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                borderRadius: "18px",
                my: 2,
                py: 1,
                px: 1.5,
                border: "1px solid #1A130C24",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>{t("ownerProfile.booking.myBooking.amount")}</Box>
                <Box
                  className="flexCenter"
                  sx={{
                    background: "#FFF6ED",
                    padding: "18px 24px",
                    borderRadius: "62px",
                    height: "50px",
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#F57C00",
                  }}
                >
                  ${data?.no_of_person * data?.budget_per_person}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
