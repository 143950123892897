export const Search_En_trans = {
  validate: {
    food: "Food is required",
    codeType: "Must be a number",
    code: "Postal code is required",
    veg: "At least one option is required",
  },
  input1: "Choose Food Concept",
  input2: "Postal Code",
  input3: "Choose Type of meals",
  input4: "Choose Type of special diets",
  input5: "Gluten-Free",
  btn: "Search",
  atleastone: "at least one",
  clear: 'Clear'
};

export const Search_Nl_trans = {
  validate: {
    food: "Voedsel is verplicht",
    codeType: "Moet een getal zijn",
    code: "Postcode is verplicht",
    veg: "Minstens één optie is verplicht",
  },
  input1: "Food-Konzept wählen",
  input2: "Postleitzahl",
  input3: "Veggie",
  input4: "Vegan",
  input5: "Glutenfrei",
  btn: "Suchen",
  atleastone: "minstens één",
};
export const Search_DE_Trans = {
  validate: {
    food: "Essen ist erforderlich",
    codeType: "Muss eine Zahl sein",
    code: "Postleitzahl ist erforderlich",
    veg: "Mindestens eine Option ist erforderlich",
  },
  input1: "Food-Konzept wählen",
  input2: "Postleitzahl",
  input3: "Wählen Sie die Art der Mahlzeiten",
  input4: "Wählen Sie die Art der speziellen Diät",
  input5: "Glutenfrei",
  btn: "Suchen",
  atleastone: "mindestens eine",
  clear: 'Klar'
};

export const Search_ES_trans = {
  validate: {
    food: "La comida es obligatoria",
    codeType: "Debe ser un número",
    code: "El código postal es obligatorio",
    veg: "Al menos una opción es obligatoria",
  },
  input1: "Elegir concepto de comida",
  input2: "Código postal",
  input3: "Veggie",
  input4: "Vegano",
  input5: "Sin gluten",
  btn: "Buscar",
  atleastone: "al menos una",
};
