import {
  Box,
  Grid,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import Noresult from "../../Component/Noresultfound/Noresult";
import BannerImage from "../../Component/BannerImage/BannerImage";
import { useInView } from "react-intersection-observer";
import CatServiceCard from "../HomePage/Sections/Cards/CatServiceCard";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useCompanyId } from "../../Context/CompanyIdContext";
import SearchSection from "../HomePage/Sections/SearchSection";
import { useLocation } from "react-router-dom";

const PAGE_SIZE = 15;

const CateringListScreen = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { renderTrigger } = useCompanyId();
  const location = useLocation();

  console.log(location.state)

  const getData = async (
    count = 0,
    meal_ids = [],
    mealRef = [],
    diet_ids = [],
    dietRef = [],
    cat_ids = [],
    catRef = [],
    zip_code = "",
    zipRef = ""
  ) => {
    try
    {
      setLoading(true);
      if (
        meal_ids !== mealRef ||
        diet_ids !== dietRef ||
        cat_ids !== catRef ||
        zip_code !== zipRef
      )
      {
        setCount(0);
        setData([]);
      }
      const params = new URLSearchParams({
        count: count,
        offset: PAGE_SIZE,
      });
      if (meal_ids?.length > 0) params.append("meal_ids", meal_ids);
      if (diet_ids?.length > 0) params.append("diet_ids", diet_ids);
      if (cat_ids?.length > 0) params.append("cat_ids", cat_ids);
      if (zip_code !== "") params.append("zip_code", zip_code);
      // all truck api
      let res = await API_GET_OPEN(
        `${API_endpoints.getallcaterers}?${params.toString()}`
      );

      if (res?.s && res?.c)
      {
        setData((prev) => (count === 0 ? res?.r : [...prev, ...res?.r]));
        setCount(res?.c ?? 0);
      } else
      {
        setData([]);
        setCount(0);
      }
    } catch (error)
    {
      console.log(error);
    } finally
    {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo({ behavior: "instant", top: 0 });
  }, [i18next.language, renderTrigger]);

  useEffect(() => {
    if (inView && !loading && data.length < count)
    {
      getData(data.length);
    }
  }, [inView, loading, count, data.length]);

  return (
    <Box>
      {/* Banner Section */}
      <BannerImage
        path={{
          title: t("contact.fiesta"),
          home: t("Navbar.Home"),
          nav: t("cateringList.bannerTitle"),
        }}
      />
      <Box
        sx={{
          background: theme.palette.background.gd,
          py: 4,
        }}
      >
        <SearchSection
          count={count}
          values={location?.state?.formValues}
          getData={getData}
        />
        <Box
          sx={{
            width: "90%",
            maxWidth: "1440px",
            m: "auto",
          }}
        >
          <Box sx={{ pb: 2 }}>
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
              {data?.length ? (
                <>
                  {data.map((x, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      key={i}
                      sx={{
                        transition: "transform 0.3s ease-in-out",
                      }}
                    >
                      <CatServiceCard data={x} />
                    </Grid>
                  ))}
                  <Box sx={{ width: "100%", textAlign: "center" }} ref={ref}>
                    {loading ? (
                      <CircularProgress sx={{ color: "#f57c00" }} />
                    ) : (
                      <Typography sx={{ color: "#f57c0088", mt: 1 }}>
                        {t("cateringList.noMoreData")}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : (
                <Box>
                  <Typography
                    className="fontSnugle"
                    sx={{ textAlign: "center", fontSize: "22px" }}
                  >
                    {t("cateringList.noData")}
                  </Typography>
                  <Noresult />
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CateringListScreen;
