import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
    useTheme,
    TextField,
    FormGroup,
    FormHelperText,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { StarSVG } from "../../../../Component/SVG";
import { API_GET, API_POST } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import * as Yup from "yup";
import { useFormik } from "formik";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";
import ButtonComponent from "../../../../Component/Button/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1400px",
    bgcolor: "background.paper",
    //   border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
    maxHeight: "calc(100vh - 100px)",
    overflow: "scroll",
};

export default function CatererBookNowModal({ company_id }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [meal, setMeal] = useState([]);
    const [diet, setDiet] = useState([]);
    const { t } = useTranslation();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    const parsedData = JSON.parse(localStorage.getItem("Fiesta"));
    let user_id = parsedData?.id;

    const navigate = useNavigate();

    const redirect = () => {
        toast.warn("Login required.");
        navigate(NavRoutes.LOGIN);
    };

    const formik = useFormik({
        initialValues: {
            city: "",
            postal_code: "",
            address: "",
            catering_Type: [],
            diet: [],
            start_date: "",
            end_date: "",
            full_name: "",
            email: "",
            number: "",
            person: 1,
            budgetPerPerson: 1,
        },

        validationSchema: Yup.object({
            city: Yup.string()
                .required(t("bookNowForm.required"))
                .min(2, t("bookNowForm.inValid")),
            postal_code: Yup.number().required(t("bookNowForm.required")),
            address: Yup.string()
                .required(t("bookNowForm.required"))
                .min(5, t("bookNowForm.inValid")),
            catering_Type: Yup.array()
                .min(1, t("bookNowForm.required"))
                .required(t("bookNowForm.required")),
            diet: Yup.array()
                .min(1, t("bookNowForm.required"))
                .required(t("bookNowForm.required")),
            start_date: Yup.date().required(t("bookNowForm.required")).nullable(),
            end_date: Yup.date()
                .required(t("bookNowForm.required"))
                .nullable()
                .min(Yup.ref("start_date"), t("bookNowForm.Vali_endDate")),
            full_name: Yup.string()
                .required(t("bookNowForm.required"))
                .min(3, t("bookNowForm.inValid")),
            email: Yup.string()
                .required(t("bookNowForm.required"))
                .email(t("bookNowForm.inValid")),
            number: Yup.string()
                .required(t("bookNowForm.required"))
                .matches(
                    /^(\+?\d{1,4}|\d{1,4})?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
                    t("bookNowForm.inValid")
                ),
            person: Yup.number().required(t("bookNowForm.required")),
            budgetPerPerson: Yup.number().required(t("bookNowForm.required")),
        }),

        onSubmit: async (values) => {
            const formdata = new FormData();
            formdata.append("user_id", user_id);
            formdata.append("full_name", values.full_name);
            formdata.append("email", values.email);
            formdata.append("phno", values.number);
            formdata.append("company_id", company_id);
            formdata.append("city_name", values.city);
            formdata.append("zip_code", values.postal_code);
            formdata.append("address", values.address);
            formdata.append("meal_ids", values.catering_Type);
            formdata.append("diet_ids", values.diet);
            formdata.append("from_date", values.start_date);
            formdata.append("to_date", values.end_date);
            formdata.append("no_of_person", values.person);
            formdata.append("budget_per_person", values.budgetPerPerson);
            formdata.append("total_budget", values.person * values.budgetPerPerson);
            setLoading(true);
            try
            {
                let res = await API_POST(API_endpoints.bookcatering, formdata);
                if (res.s)
                {
                    toast.success("Request sent");
                    setLoading(false);
                    handleClose();
                    formik.resetForm();
                } else
                {
                    setLoading(false);
                    toast.error(res.m);
                }
            } catch (error)
            {
                console.log(error);
                return false;
            }
        },
    });

    const handleCheckboxChange = (type) => (value) => (event) => {
        const { checked } = event.target;
        const updatedArray = checked
            ? [...formik.values[type], value]
            : formik.values[type].filter((item) => item !== value);

        formik.setFieldValue(type, updatedArray);
    };

    const renderCheckbox = (labelKey, value, type) => (
        <FormControlLabel
            control={
                <Checkbox
                    id={labelKey}
                    checked={formik.values[type].includes(value)}
                    onChange={handleCheckboxChange(type)(value)}
                    //   onBlur={() => formik.setFieldTouched(type, true)} // Manually trigger onBlur for Formik
                    sx={{
                        color: theme.palette.text.secondary,
                        "&.Mui-checked": {
                            color: theme.palette.text.active,
                        },
                    }}
                />
            }
            label={labelKey}
        />
    );

    const renderMealCheckboxes = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { md: 4 },
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {meal.length &&
                    meal?.map((x, i) =>
                        renderCheckbox(x?.meal_name, x?.meal_id, "catering_Type")
                    )}
            </Box>
        </Box>
    );

    const renderDietCheckboxes = () => (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {diet.length &&
                diet?.map((x) => renderCheckbox(x?.diet_name, x?.diet_id, "diet"))}
        </Box>
    );

    const handlemeal = async (company_id) => {
        try
        {
            let res = await API_GET(
                `${API_endpoints.getcompanybyid}?company_id=${company_id}`
            );
            const mealsData = res?.r?.meal;
            const dietData = res?.r?.diet;
            if (res?.s)
            {
                setMeal(mealsData);
                setDiet(dietData);
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    useEffect(() => {
        handlemeal(company_id);
    }, [company_id]);

    return (
        <>
            <Box className="flexCenter" sx={{ width: "100%" }}>
                <ButtonComponent
                    type="button"
                    label={`${t("bookNowForm.button")}`}
                    onClick={() => (user_id ? handleOpen() : redirect())}
                    sx={{
                        width: { xs: "100%", sm: "100%", md: "100%" },
                        background: theme.palette.background.btn,
                        color: theme.palette.text.primary,
                        fontSize: "16px",
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        ...style,
                        width: { xs: "90%", md: "calc(100% - 50px)" },
                        position: "relative",
                    }}
                >
                    <Box
                        onClick={() => handleClose()}
                        sx={{ position: "absolute", right: 8, top: 8, cursor: "pointer" }}
                    >
                        <CloseIcon />
                    </Box>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            color: theme.palette.text.brown,
                            fontSize: { xs: "18px", md: "22px", lg: "26px" },
                        }}
                    >
                        {t("bookNowForm.title")}
                    </Typography>
                    <Box
                        sx={{
                            background: theme.palette.background.main,
                            borderRadius: "25px",
                            padding: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    width: "100%",
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Box pb={0.5}>
                                        <StarSVG /> {t("bookNowForm.city.title")}
                                    </Box>

                                    <TextField
                                        type="text"
                                        id="city"
                                        name="city"
                                        fullWidth
                                        {...formik.getFieldProps("city")}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            e.target.value = value.trimStart().trimEnd();
                                            formik.handleChange(e);
                                        }}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={formik.touched.city && formik.errors.city}
                                        variant="outlined"
                                        placeholder={t("bookNowForm.city.pl")}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "12px",
                                                color: theme.palette.text.defaultDark,
                                                "&:hover": {
                                                    color: theme.palette.text.active,
                                                    bgcolor: theme.palette.background.inputBg,
                                                    borderRadius: "12px",
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                    <Box pb={0.5}>
                                        <StarSVG /> {t("bookNowForm.postal_code.title")}
                                    </Box>

                                    <TextField
                                        type="text"
                                        id="postal_code"
                                        name="postal_code"
                                        fullWidth
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            formik.setFieldValue("postal_code", trimmedValue);
                                        }}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.postal_code}
                                        error={
                                            formik.touched.postal_code &&
                                            Boolean(formik.errors.postal_code)
                                        }
                                        helperText={
                                            formik.touched.postal_code && formik.errors.postal_code
                                        }
                                        variant="outlined"
                                        placeholder={t("bookNowForm.postal_code.pl")}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "12px",
                                                color: theme.palette.text.defaultDark,
                                                "&:hover": {
                                                    color: theme.palette.text.active,
                                                    bgcolor: theme.palette.background.inputBg,
                                                    borderRadius: "12px",
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box pb={0.5}>
                                    <StarSVG /> {t("bookNowForm.address.title")}
                                </Box>

                                <TextField
                                    type="text"
                                    id="address"
                                    name="address"
                                    onChange={formik.handleChange}
                                    // onBlur={formik.values.address}
                                    value={formik.values.address}
                                    error={
                                        formik.touched.address && Boolean(formik.errors.address)
                                    }
                                    helperText={formik.touched.address && formik.errors.address}
                                    variant="outlined"
                                    placeholder={t("bookNowForm.address.pl")}
                                    multiline
                                    fullWidth
                                    rows={5}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "12px",
                                            color: theme.palette.text.defaultDark,
                                            "&:hover": {
                                                color: theme.palette.text.active,
                                                bgcolor: theme.palette.background.inputBg,
                                                borderRadius: "12px",
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                gap: 1,
                            }}
                        >
                            {/* Meals Section */}
                            <Box sx={{ width: "100%" }}>
                                <Box py={1}>
                                    <StarSVG /> {t("bookNowForm.catering_Type")}
                                </Box>
                                <FormGroup row>{renderMealCheckboxes()}</FormGroup>
                                {formik.touched.catering_Type &&
                                    formik.errors.catering_Type && (
                                        <FormHelperText error>
                                            {formik.errors.catering_Type}
                                        </FormHelperText>
                                    )}
                            </Box>

                            {/* Special Diets Section */}
                            <Box sx={{ width: "100%" }}>
                                <Box py={1}>
                                    <StarSVG /> {t("bookNowForm.diet")}
                                </Box>
                                <FormGroup row>{renderDietCheckboxes()}</FormGroup>
                                {formik.touched.diet && formik.errors.diet && (
                                    <FormHelperText error>{formik.errors.diet}</FormHelperText>
                                )}
                            </Box>
                        </Box>
                        {/* time */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: { xs: 2, sm: 3 },
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row" },
                                        gap: { xs: 2, sm: 3 },
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <Box
                                            py={1}
                                            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                                        >
                                            <StarSVG />{" "}
                                            <Typography noWrap>{t("bookNowForm.date")}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <input
                                                type="date"
                                                required
                                                id="start_date"
                                                name="start_date"
                                                inputMode="numeric"
                                                placeholder="yyyy/mm/dd"
                                                pattern="/d{2}-/d{2}-/d{4}"
                                                value={formik.values.start_date}
                                                onChange={(e) =>
                                                    formik.setFieldValue("start_date", e.target.value)
                                                }
                                                onBlur={formik.handleBlur}
                                                style={{
                                                    width: "100%",
                                                    padding: "18px",
                                                    borderRadius: "12px",
                                                    border:
                                                        formik.touched.start_date &&
                                                            formik.errors.start_date
                                                            ? "1px solid red"
                                                            : "1px solid #1A130C3D",
                                                    backgroundColor: "transparent",
                                                    color: theme.palette.text.defaultDark,
                                                    // color: "#000",
                                                    margin: "0",
                                                    outline: "none",
                                                }}
                                            />
                                            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                                                {formik.touched.start_date &&
                                                    formik.errors.start_date && (
                                                        <Typography color="error" sx={{ fontSize: "12px" }}>
                                                            {formik.errors.start_date}
                                                        </Typography>
                                                    )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "end",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <input
                                                type="date"
                                                required
                                                id="end_date"
                                                name="end_date"
                                                inputMode="numeric"
                                                placeholder="yyyy/mm/dd"
                                                pattern="/d{2}-/d{2}-/d{4}"
                                                value={formik.values.end_date}
                                                onChange={(e) =>
                                                    formik.setFieldValue("end_date", e.target.value)
                                                }
                                                onBlur={formik.handleBlur}
                                                style={{
                                                    width: "100%",
                                                    padding: "18px",
                                                    borderRadius: "12px",
                                                    border:
                                                        formik.touched.end_date && formik.errors.end_date
                                                            ? "1px solid red"
                                                            : "1px solid #1A130C3D",
                                                    backgroundColor: "transparent",
                                                    color: theme.palette.text.defaultDark,
                                                    // color: "#000",
                                                    margin: "0",
                                                    outline: "none",
                                                }}
                                            />
                                            <Box sx={{ display: { xs: "block", sm: "none" } }}>
                                                {formik.touched.end_date && formik.errors.end_date && (
                                                    <Typography color="error" sx={{ fontSize: "12px" }}>
                                                        {formik.errors.end_date}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: { xs: "none", sm: "flex" },
                                        gap: { xs: 2, sm: 3 },
                                    }}
                                >
                                    <Box sx={{ flex: 1 }}>
                                        {formik.touched.start_date && formik.errors.start_date && (
                                            <Typography color="error" sx={{ fontSize: "12px" }}>
                                                {formik.errors.start_date}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        {formik.touched.end_date && formik.errors.end_date && (
                                            <Typography color="error" sx={{ fontSize: "12px" }}>
                                                {formik.errors.end_date}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    width: "100%",
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Box pb={0.5}>
                                        <StarSVG /> {t("bookNowForm.contact")}
                                    </Box>

                                    <TextField
                                        type="text"
                                        id="full_name"
                                        name="full_name"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.full_name}
                                        error={
                                            formik.touched.full_name &&
                                            Boolean(formik.errors.full_name)
                                        }
                                        helperText={
                                            formik.touched.full_name && formik.errors.full_name
                                        }
                                        variant="outlined"
                                        placeholder={t("bookNowForm.full_name")}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "12px",
                                                color: theme.palette.text.defaultDark,
                                                "&:hover": {
                                                    color: theme.palette.text.active,
                                                    bgcolor: theme.palette.background.inputBg,
                                                    borderRadius: "12px",
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                    <TextField
                                        type="email"
                                        id="email"
                                        name="email"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        variant="outlined"
                                        placeholder={t("bookNowForm.email")}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "12px",
                                                color: theme.palette.text.defaultDark,
                                                "&:hover": {
                                                    color: theme.palette.text.active,
                                                    bgcolor: theme.palette.background.inputBg,
                                                    borderRadius: "12px",
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <TextField
                                        type="number"
                                        id="number"
                                        name="number"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.number}
                                        error={
                                            formik.touched.number && Boolean(formik.errors.number)
                                        }
                                        helperText={formik.touched.number && formik.errors.number}
                                        variant="outlined"
                                        placeholder={t("bookNowForm.number")}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "12px",
                                                color: theme.palette.text.defaultDark,
                                                "&:hover": {
                                                    color: theme.palette.text.active,
                                                    bgcolor: theme.palette.background.inputBg,
                                                    borderRadius: "12px",
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {/*  Person */}

                        <Box sx={{ width: { xs: "100%" } }}>
                            <Box>
                                <Box py={1}>
                                    <StarSVG />
                                    {t("bookNowForm.person")}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "space-between",
                                        gap: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: { xs: "start", md: "center" },
                                            flexDirection: { xs: "column", md: "row" },
                                            gap: 2,
                                            border: "1px solid lightgrey",
                                            p: 1,
                                            borderRadius: "12px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: theme.palette.text.subHead,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("bookNowForm.persons")}
                                        </Typography>

                                        <Box className="flexCenter" sx={{ gap: 1 }}>
                                            <RemoveCircleRoundedIcon
                                                style={{
                                                    color: theme.palette.text.active,
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    const updatedValue = Math.max(
                                                        0,
                                                        formik.values.person - 1
                                                    );
                                                    formik.setFieldValue("person", updatedValue);
                                                }}
                                            />

                                            <TextField
                                                type="number"
                                                id="person"
                                                name="person"
                                                fullWidth
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    formik.setFieldValue(
                                                        "person",
                                                        value >= 0 ? value : 0
                                                    ); // Prevent value less than 0
                                                }}
                                                // onBlur={formik.handleBlur}
                                                value={formik.values.person}
                                                error={
                                                    formik.touched.person && Boolean(formik.errors.person)
                                                }
                                                helperText={
                                                    formik.touched.person && formik.errors.person
                                                }
                                                variant="outlined"
                                                placeholder={t("bookNowForm.persons")}
                                                InputProps={{
                                                    sx: {
                                                        borderRadius: "12px",
                                                        color: theme.palette.text.defaultDark,
                                                        "&:hover": {
                                                            color: theme.palette.text.active,
                                                            bgcolor: theme.palette.background.inputBg,
                                                            borderRadius: "12px",
                                                        },
                                                    },
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                }}
                                            />

                                            <AddCircleRoundedIcon
                                                style={{
                                                    color: theme.palette.text.active,
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    const updatedValue =
                                                        parseInt(formik.values.person, 10) + 1;
                                                    formik.setFieldValue("person", updatedValue);
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            gap: 1,
                                            my: 1,
                                        }}
                                    >
                                        {[10, 20, 50, 100].map((x, i) => {
                                            return (
                                                <Box
                                                    onClick={() => {
                                                        formik.setFieldValue("person", x);
                                                    }}
                                                    sx={{
                                                        background: theme.palette.background.lightorange,
                                                        p: 1,
                                                        fontWeight: "500",
                                                        borderRadius: "8px",
                                                        cursor: "pointer",
                                                        color: theme.palette.text.active,
                                                        "&:hover": {
                                                            background: theme.palette.background.brown,
                                                            color: theme.palette.text.defaultLight,
                                                        },
                                                    }}
                                                    key={i}
                                                >
                                                    {x} {t("bookNowForm.persons")}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: { xs: "start", md: "center" },
                                        flexDirection: { xs: "column", md: "row" },
                                        gap: 2,
                                        mt: 1,
                                        border: "1px solid lightgrey",
                                        p: 1,
                                        borderRadius: "12px",
                                    }}
                                >
                                    <Typography
                                        sx={{ color: theme.palette.text.subHead, fontWeight: 600 }}
                                    >
                                        {t("bookNowForm.budgetPerPerson")}
                                    </Typography>

                                    <Box className="flexCenter" sx={{ gap: 1 }}>
                                        <RemoveCircleRoundedIcon
                                            onClick={() => {
                                                const updatedValue = Math.max(
                                                    0,
                                                    formik.values.budgetPerPerson - 1
                                                );
                                                formik.setFieldValue("budgetPerPerson", updatedValue);
                                            }}
                                            style={{
                                                color: theme.palette.text.brown,
                                                cursor: "pointer",
                                            }}
                                        />
                                        <TextField
                                            type="number"
                                            id="budgetPerPerson"
                                            name="budgetPerPerson"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            value={formik.values.budgetPerPerson}
                                            error={
                                                formik.touched.budgetPerPerson &&
                                                Boolean(formik.errors.budgetPerPerson)
                                            }
                                            helperText={
                                                formik.touched.budgetPerPerson &&
                                                formik.errors.budgetPerPerson
                                            }
                                            variant="outlined"
                                            placeholder={t("bookNowForm.budgetPerPerson")}
                                            InputProps={{
                                                sx: {
                                                    borderRadius: "12px",
                                                    color: theme.palette.text.defaultDark,
                                                    "&:hover": {
                                                        color: theme.palette.text.active,
                                                        bgcolor: theme.palette.background.inputBg,
                                                        borderRadius: "12px",
                                                    },
                                                },
                                                inputProps: {
                                                    min: 0,
                                                },
                                            }}
                                        />
                                        <AddCircleRoundedIcon
                                            onClick={() => {
                                                const updatedValue =
                                                    parseInt(formik.values.budgetPerPerson, 10) + 1;
                                                formik.setFieldValue("budgetPerPerson", updatedValue);
                                            }}
                                            style={{
                                                color: theme.palette.text.brown,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: { xs: "100%" } }}>
                            <Box sx={{ border: " 1px solid #823D0C24", my: 2 }} />
                            <Box className="flexBetween" sx={{}}>
                                <Typography sx={{ fontWeight: 700 }}>
                                    {" "}
                                    {t("bookNowForm.total")}
                                </Typography>
                                <Box
                                    className="flexCenter"
                                    sx={{
                                        background: theme.palette.background.lightorange,
                                        p: 1.5,
                                        fontWeight: "600",
                                        borderRadius: "50px",
                                        color: theme.palette.text.active,
                                        minWidth: "120px",
                                    }}
                                >
                                    €{formik.values.budgetPerPerson * formik.values.person || 0}
                                </Box>
                            </Box>
                        </Box>

                        <Box className="flexCenter" sx={{ my: 2 }}>
                            <ButtonComponent
                                type="button"
                                disabled={loading}
                                label={loading ? <Loader /> : `${t("bookNowForm.button")}`}
                                onClick={formik.handleSubmit}
                                sx={{
                                    width: { xs: "100%", sm: "60%", md: "200px" },
                                    background: theme.palette.background.btn,
                                    color: theme.palette.text.primary,
                                    fontSize: "16px",
                                    overflow: "hidden",
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
