import { Box, Button, TextField, useTheme } from "@mui/material";
import FloatActionButton from "./FloatingActionButton";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, firebaseStorage } from "../../../Firebase/Firebase";
import ImageUpload from "./ImageUploadModal";
import SendImageModal from "./SendImageModal";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const ChatFooter = ({ selectedChatFirebaseId, myId, selectedChatUserId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [typedMessage, setTypedMessage] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [openSendImage, setOpenSendImage] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [media, setMedia] = useState(null);

  const handleSendMessage = async () => {
    try
    {
      setSendLoading(true);

      if (media)
      {
        const path = Date.now()
        const storageRef = ref(firebaseStorage, `chat-media/${path}`);
        const profileImageRef = await uploadBytes(storageRef, media);

        const downloadURL = await getDownloadURL(profileImageRef.ref);

        const docRef = await addDoc(
          collection(db, "chat", selectedChatFirebaseId, "message_chat"),
          {
            content: downloadURL,
            path: path,
            sentById: myId,
            sentAt: serverTimestamp(),
            last_msg_by: 0,
            last_msg: typedMessage,
            msgType: 2,
            // sender: {
            //   name: logUser.user_name || logUser.b_name,
            //   avtar: logUser.b_logo || logUser.profile_img,
            // },
          }
        );
        setMedia(null);
        setOpenSendImage(null);
      } else
      {
        if (typedMessage?.trim() !== "")
        {
          const docRef = await addDoc(
            collection(db, "chat", selectedChatFirebaseId, "message_chat"),
            {
              msg: typedMessage,
              sentById: myId,
              sentAt: serverTimestamp(),
              last_msg_by: 0,
              last_msg: typedMessage,
              msgType: 1,
            }
          );
          setTypedMessage("");
          const aa = doc(db, "chat", selectedChatFirebaseId);
          const dd = (await getDoc(aa)).data();
          await updateDoc(aa, {
            sentAt: serverTimestamp(),
            last_msg: typedMessage,
            unReadCount: {
              [selectedChatUserId]: dd.unReadCount[selectedChatUserId]
                ? dd.unReadCount[selectedChatUserId] + 1
                : 1,
              [myId]: 0,
            },
          });
        }
      }
    } catch (error)
    {
      console.error("Error adding/updating document: ", error);
    } finally
    {
      setSendLoading(false);
    }
  };

  const handleSendImageClose = () => {
    setUploadedImage(null);
    setOpenSendImage(false);
  };

  const handleImageChange = (image) => {
    setMedia(image);
    setUploadedImage(image);
    setOpenSendImage(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1.5,
      }}
    >
      {/* <FloatActionButton /> */}
      <Box sx={{}}>

        <ImageUpload onImageChange={(image) => handleImageChange(image)} />
        <SendImageModal
          openSendImage={openSendImage}
          sendImageClose={handleSendImageClose}
          uploadedImage={uploadedImage}
          handleSendMessage={handleSendMessage}
          loading={sendLoading}
        />
      </Box>
      <TextField
        fullWidth
        type="text"
        id="fullname"
        name="fullname"
        onChange={(e) => setTypedMessage(e.target.value)}
        value={typedMessage}
        variant="outlined"
        placeholder={t("Type message here...")}
        onKeyDown={(e) => e?.code === "Enter" && !sendLoading && handleSendMessage()}
        InputProps={{
          sx: {
            borderRadius: "12px",
            color: theme.palette.text.defaultDark,
            "&:hover": {
              color: theme.palette.text.active,
              bgcolor: theme.palette.background.inputBg,
              borderRadius: "12px",
            },
          },
        }}
        sx={{
          width: "calc(100% - 150px)",
        }}
      />
      <Box sx={{ "&:hover": { cursor: "pointer" } }}>
        <span
          style={{
            background: theme.palette.background.orange,
            padding: "15px",
            borderRadius: "50%",
          }}
          className="flexCenter"
          onClick={handleSendMessage}
        >
          <SendIcon sx={{ rotate: "-40deg", color: "white" }} />
        </span>
      </Box>
    </Box>
  );
};

export default ChatFooter;
