import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import { API_GET } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";

const CounterSction = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [getData, setGetData] = useState({});
  const [loading, setLoading] = useState(true);
  const cards = [
    {
      count: getData?.event - 1,
      title: t("about.count.card1"),
    },
    {
      count: getData?.caterer - 1,
      title: t("about.count.card2"),
    },
    {
      count: getData?.food - 1,
      title: t("about.count.card3"),
    },
  ];

  const fetchData = async () => {
    try
    {
      setLoading(true);
      const res = await API_GET(API_endpoints.GetStatistics);
      setGetData(res?.r);
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  function formatNumber(num) {
    if (num >= 1_000_000_000)
    {
      return (num / 1_000_000_000)?.toFixed(1) + "B";
    } else if (num >= 1_000_000)
    {
      return (num / 1_000_000)?.toFixed(1) + "M";
    } else if (num >= 1_000)
    {
      return (num / 1_000)?.toFixed(1) + "K";
    } else
    {
      return num?.toString();
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ display: getData?.status === 0 ? 'none' : 'block' }}>
      <Box
        sx={{
          width: { xs: "100%", sm: "90%" },
          m: "auto",
          py: { xs: 3, md: 5, lg: 7 },
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "80%", md: "50%", lg: "50%" },
            m: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant="h3"
            className="fontSnugle"
            sx={{
              color: theme.palette.text.dark,
              fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
              textAlign: "center",
            }}
          >
            {t("about.count.title")}
          </Typography>
          <Typography
            variant="h6"
            // className="fontSnugle"
            sx={{
              color: theme.palette.text.subHead,
              fontSize: { xs: "18px", md: "20px" },
              textAlign: "center",
              mt: "0.5rem",
            }}
          >
            {t("about.count.note")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            flexWrap: "wrap",
            gap: 2,
            mt: 3,
          }}
        >
          {cards.map((x, i) => {
            let animationType;
            if (i === 0)
            {
              animationType = "animate__fadeInLeft";
            } else if (i === 1)
            {
              animationType = "animate__zoomIn";
            } else if (i === 2)
            {
              animationType = "animate__fadeInRight";
            }

            return (
              <ScrollAnimation animateIn={animationType} key={i}>
                <Box
                  sx={{
                    background: theme.palette.background.carda,
                    p: 2,
                    borderRadius: 5,
                    borderWidth: "0px 3px 3px 0px",
                    borderStyle: "solid",
                    borderColor: "#F57C004D",
                    width: { xs: "100%", md: "300px", lg: "350px" },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "30px",
                          sm: "33px",
                          md: "40px",
                          xl: "45px",
                        },
                        fontWeight: 700,
                        textAlign: "left",
                        color: theme.palette.text.active,
                      }}
                    >
                      {loading ? (
                        <CircularProgress style={{ color: "#f57c00" }} />
                      ) : (
                        formatNumber(x?.count)
                      )}
                      +
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "16px",
                          md: "20px",
                          xl: "22px",
                        },
                        textAlign: "left",
                        fontWeight: "500",
                      }}
                    >
                      {x?.title}
                    </Typography>
                  </Box>
                </Box>
              </ScrollAnimation>
            );
          })}
        </Box>
      </Box>
    </Box >
  );
};

export default CounterSction;
