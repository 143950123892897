import BannerImage from "../../Component/BannerImage/BannerImage";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material";
import TruckCard from "../HomePage/Sections/Cards/TruckCard";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SearchSection from "../../Pages/HomePage/Sections/SearchSection";
import { API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import Noresult from "../../Component/Noresultfound/Noresult";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../Context/CompanyIdContext";
import FoodTruckEditModal from "../FoodTruckEdit/FoodTruckEditModal";
import { useInView } from "react-intersection-observer";

const PAGE_SIZE = 15;

const FoodTruckScreen = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();
  let { companyId } = useCompanyId();
  const [open, setOpen] = useState(false);
  const [truckData, setTruckData] = useState({});
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  companyId = parseData?.companyId


  const getData = async (
    count = 0,
    meal_ids = [],
    mealRef = [],
    diet_ids = [],
    dietRef = [],
    cat_ids = [],
    catRef = [],
    zip_code = "",
    zipRef = ""
  ) => {
    try
    {
      setLoading(true);
      if (
        meal_ids !== mealRef ||
        diet_ids !== dietRef ||
        cat_ids !== catRef ||
        zip_code !== zipRef
      )
      {
        setCount(0);
        setData([]);
      }
      const params = new URLSearchParams({
        count: count,
        offset: PAGE_SIZE,
      });
      if (meal_ids?.length > 0) params.append("meal_ids", meal_ids);
      if (diet_ids?.length > 0) params.append("diet_ids", diet_ids);
      if (cat_ids?.length > 0) params.append("cat_ids", cat_ids);
      if (zip_code !== "") params.append("zip_code", zip_code);
      // all truck api
      let res = await API_GET_OPEN(
        `${API_endpoints.getalltrucks}?${params.toString()}`
      );

      if (res?.s && res?.c)
      {
        setData((prev) => (count === 0 ? res?.r : [...prev, ...res?.r]));
        setCount(res?.c ?? 0);
      } else
      {
        setData([]);
        setCount(0);
      }
    } catch (error)
    {
      console.log(error);
    } finally
    {
      setLoading(false);
    }
  };

  const openModal = async (data) => {
    setOpen(true);
    setTruckData(data);
  };

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  let mealRef = useRef(location?.state?.formValues?.meal ?? []);
  let dietRef = useRef(location?.state?.formValues?.diet ?? []);
  let catRef = useRef(location?.state?.formValues?.categories ?? []);
  let zipRef = useRef(location?.state?.formValues?.zipCode ?? "");

  useEffect(() => {
    getData(
      data?.length,
      location?.state?.formValues?.meal ?? [],
      mealRef.current,
      location?.state?.formValues?.diet ?? [],
      dietRef.current,
      location?.state?.formValues?.categories ?? [],
      catRef.current,
      location?.state?.formValues?.zipCode ?? "",
      zipRef.current
    );
    mealRef.current = location?.state?.formValues?.meal;
    dietRef.current = location?.state?.formValues?.diet;
    catRef.current = location?.state?.formValues?.categories;
    zipRef.current = location?.state?.formValues?.zipCode;
  }, []);

  useEffect(() => {
    if (inView && !loading && data?.length < count)
    {
      getData(
        data?.length,
        location?.state?.formValues?.meal,
        mealRef.current,
        location?.state?.formValues?.diet,
        dietRef.current,
        location?.state?.formValues?.categories,
        catRef.current,
        location?.state?.formValues?.zipCode,
        zipRef.current
      );
      mealRef.current = location?.state?.formValues?.meal;
      dietRef.current = location?.state?.formValues?.diet;
      catRef.current = location?.state?.formValues?.categories;
      zipRef.current = location?.state?.formValues?.zipCode;
    }
  }, [inView, loading, count, data?.length, location?.state?.formValues]);

  return (
    <Box>
      {/* Banner Section */}
      <BannerImage
        path={{
          title: t("contact.fiesta"),
          home: t("Navbar.Home"),
          nav: t("foodTruck.title"),
        }}
      />
      <Box
        sx={{
          background: theme.palette.background.gd,
        }}
      >
        <SearchSection
          count={count}
          values={location?.state?.formValues}
          getData={getData}
        />
        <Box
          sx={{
            width: "90%",
            maxWidth: "1440px",
            m: "auto",
          }}
        >
          <Box sx={{ pb: 5 }}>
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
              {data?.length ? (
                <>
                  {data?.map((x, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        key={i}
                        sx={{
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <TruckCard
                          data={x}
                          openModal={openModal}
                          title={
                            x?.company_id === companyId
                              ? t("explore.edit")
                              : t("myBooking.booknow")
                          }
                        />
                      </Grid>
                    );
                  })}
                  <Box sx={{ width: "100%", textAlign: "center" }} ref={ref}>
                    {loading ? (
                      <CircularProgress sx={{ color: "#f57c00" }} />
                    ) : (
                      <Typography sx={{ color: "#f57c0088", mt: 1 }}>
                        {t("cateringList.noMoreData")}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : !loading ? (
                <Box>
                  <Typography
                    className="fontSnugle"
                    sx={{ textAlign: "center", fontSize: "22px" }}
                  >
                    {t("foodTruck.noData")}
                  </Typography>
                  <Noresult />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "350px",
                  }}
                >
                  <CircularProgress style={{ color: "#f57c00" }} />
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <FoodTruckEditModal
        setOpen={setOpen}
        open={open}
        data={truckData}
        getData={getData}
        compID={companyId}
      />
    </Box>
  );
};

export default FoodTruckScreen;
