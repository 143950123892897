import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cards from "./Cards";
import { DataListingComponent } from "../../../Component/DataGridComponent";
import { useEffect, useState } from "react";
import { API_GET2 } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { useCompanyId } from "../../../Context/CompanyIdContext";

const PAGE_SIZE_OPTIONS = [15];
const DashboardTab = () => {
  const { t } = useTranslation();
  const column = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowId",
      headerName: "#",
      width: 50,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile",
      headerName: `${t("ownerProfile.dash.grid.profile")}`,
      width: 100,
      headerAlign: "center",
      renderCell: (cell) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={API_endpoints.baseURL + cell?.row?.user_details?.profile_img}
            alt="Profile"
            height={40}
            width={40}
            style={{ borderRadius: "50%" }}
          />
        </Box>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: `${t("ownerProfile.dash.grid.name")}`,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography>{cell?.row?.user_details?.full_name}</Typography>
        </Box>
      ),
    },

    {
      headerClassName: "super-app-theme--header",
      field: "from_date",
      headerName: `${t("ownerProfile.dash.grid.booking")}`,
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "to_date",
      headerName: `${t("ownerProfile.dash.grid.eDate")}`,
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "booking_status",
      headerName: `${t("ownerProfile.payment.grid.status")}`,

      width: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        const {
          row: { booking_status },
        } = cell;
        return booking_status == 0 ? (
          <span style={{ color: "grey" }}>Pending</span>
        ) : booking_status === 1 ? (
          <span style={{ color: "green" }}>Approved</span>
        ) : (
          <span style={{ color: "red" }}>Rejected</span>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const { companyId } = useCompanyId();

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const getData = async (id, page = 0, pageSize = PAGE_SIZE_OPTIONS[0]) => {
    try
    {
      setIsLoading(true);
      let count = page * pageSize;
      let res = await API_GET2(
        `${API_endpoints.getbookingsbyuser}?company_id=${id}&count=${count}&offset=15`
      );

      if (res?.s)
      {
        const rowId = res?.r?.map((x, i) => {
          return {
            rowId: i + 1,
            ...x,
          };
        });
        setData(rowId);
        setTotalData(res?.c ?? 0);
      } else
      {
        setData([]);
        setTotalData(0);
      }
      setIsLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(companyId, page, rowsPerPage);
  }, [companyId, page, rowsPerPage]);

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {t("ownerProfile.dash.note")}
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #1A130C28", my: 1.5 }} />
      <Box >
        <Cards />
        <Box sx={{ my: 1.5 }}>
          <Typography>{t("ownerProfile.dash.latest")}</Typography>
        </Box>
        <Box sx={{ height: "400px" }}>
          <Box sx={{ height: "370px" }}>
            <DataListingComponent
              data={data ?? []}
              PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
              loading={IsLoading}
              totalData={totalData}
              page={page}
              Footer={true}
              columns={column}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTab;
