import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from "../../Assets/images/confirm.svg";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 12,
  p: 4,
  borderRadius: "12px",
  outline: "none",
};

export default function ConfirmationModal({
  btnImg,
  handleClick,
  cnfBtnTitle,
  status,
  open,
  setOpen,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              style={{
                height: "100px",
                width: "100px",
                objectFit: "contain",
              }}
              alt="Icon"
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              padding: "1rem 0",
              fontWeight: "600",
              fontSize: "1.3rem",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {`${t("cModal.note")} ${cnfBtnTitle}?`}
          </Typography>
          <Box
            className={"flexBox"}
            sx={{ justifyContent: "space-between", gap: "1rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.text.defaultDark}`,
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "18px",
                color: "#05001B99",
                fontWeight: "600",
                padding: "8px 14px",
                "&:hover": {
                  border: `1px solid ${theme.palette.text.defaultDark}`,
                  backgroundColor: "#fff",
                },
              }}
              onClick={handleClose}
            >
              {t("cModal.cancel")}
            </Button>
            <Button
              variant="outlined"
              sx={{
                mt: 1,
                width: "100%",
                textTransform: "none",
                borderRadius: "8px",
                outline: "none",
                border: "none",
                background: theme.palette.background.lightorange,
                fontSize: "18px",
                color: theme.palette.text.active,
                fontWeight: "600",
                padding: "8px 14px",
                "&:hover": {
                  backgroundColor: theme.palette.text.active,
                  color: theme.palette.background.main,
                },
              }}
              onClick={async () => {
                await handleClick(status);
                handleClose();
              }}
            >
              {cnfBtnTitle}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
