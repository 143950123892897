import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  CircularProgress,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../Component/SVG";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DragandDrop from "../../Component/ImageUploader/DragandDrop";
import { API_GET, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import ButtonComponent from "../../Component/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import { useCompanyId } from "../../Context/CompanyIdContext";
import i18next from "i18next";
const AddTruckScreen = () => {
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formikImage, setFormikImage] = useState();
  const [imageError, setImageError] = useState("");
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);

  const location = useLocation();
  const { companyId } = useCompanyId();
  let compID = location?.state?.id ?? companyId;
  const initial2 = {
    company_id: compID,
    truck_name: "",
    address: "",
    truck_cat_id: 0,
    // specifications: "",
    meal: [],
    diet: [],
    zip_code: "",
    description: "",
    size: "",
    electricity_type: "",
    operating_mode: "",
    is_water_required: "",
    work_area_radius: 0,
  };

  const validationSchema2 = Yup.object({
    truck_name: Yup.string().required(t("Login.required")),

    address: Yup.string()
      .required(t("Login.required"))
      .max(200, t("Login.required") + ": Maximum 200 characters"),

    truck_cat_id: Yup.number()
      .required(t("Login.required"))
      .notOneOf([0], t("Login.required")),

    // specifications: Yup.string().required(t("Login.required")),
    meal: Yup.array()
      .min(1, t("bookNowForm.required"))
      .required(t("bookNowForm.required")),
    diet: Yup.array()
      .min(1, t("bookNowForm.required"))
      .required(t("bookNowForm.required")),
    zip_code: Yup.string().required(t("Login.required")),
    work_area_radius: Yup.number()
      .required(t("Login.required"))
      .test("not-zero", t("Login.required"), (value) => value !== 0),

    description: Yup.string()
      .required(t("Login.required"))
      .max(200, t("Login.required") + ": Maximum 200 characters"),

    size: Yup.number()
      .required(t("Login.required"))
      .min(0, t("Login.required")),

    electricity_type: Yup.string().required(t("Login.required")),
    operating_mode: Yup.string().required(t("Login.required")),
    is_water_required: Yup.string().required(t("Login.required")),
  });

  const handleCategory = async () => {
    try
    {
      let res = await API_GET(API_endpoints.gettruckcat);
      res?.s && setCategories(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategory();
  }, []);

  const formik = useFormik({
    initialValues: initial2,
    validationSchema: validationSchema2,
    enableReinitialize: true,

    onSubmit: async (values) => {
      setLoading(true);
      if (!image)
      {
        setImageError(t("Login.required"));
        return;
      }

      try
      {
        let res = await API_POST(API_endpoints.addtruck, values);
        if (res?.s)
        {
          const mealData = new FormData()
          mealData.append('truck_id', res?.r?.id)
          mealData.append('meal_ids', values.meal)
          const dietData = new FormData()
          dietData.append('truck_id', res?.r?.id)
          dietData.append('diet_ids', values.diet)
          let mealResponse = await API_POST(
            API_endpoints?.addUpdateMealForFoodTruck, mealData
          );
          let dietRepsonse = await API_POST(
            API_endpoints?.addUpdateDietForFoodTruck, dietData
          );

          const d = new FormData();
          d.append("truck_id", res?.r?.id);
          d.append("image", image);
          let dataRes = await API_POST(API_endpoints.addtruckimage, d);
          if (mealResponse?.s && dietRepsonse?.s && dataRes?.s)
          {
            toast.success(res?.m);
            navigate(NavRoutes.MY_COMPANY);
          } else
          {
            toast.error(res?.m);
          }
        }
        setLoading(false);
      } catch (error)
      {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const handleCheckboxChange = (type) => (value) => (event) => {
    const { checked } = event.target;
    const updatedArray = checked
      ? [...formik.values[type], value]
      : formik.values[type]?.filter((item) => item !== value);

    formik.setFieldValue(type, updatedArray);
  };

  const renderCheckbox = (labelKey, value, type, key) => (
    <FormControlLabel
      key={key}
      control={
        <Checkbox
          id={labelKey}
          checked={formik.values[type]?.includes(value)}
          onChange={handleCheckboxChange(type)(value)}
          //   onBlur={() => formik.setFieldTouched(type, true)} // Manually trigger onBlur for Formik
          sx={{
            color: theme.palette.text.secondary,
            "&.Mui-checked": {
              color: theme.palette.text.active,
            },
          }}
        />
      }
      label={labelKey}
    />
  );

  const renderMealCheckboxes = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 4 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {meal?.length &&
          meal?.map((x, i) =>
            renderCheckbox(
              i18next.language === "de" ? x?.german : x?.meal_name,
              x?.id,
              "meal",
              i
            )
          )}
      </Box>
    </Box>
  );

  const renderDietCheckboxes = () => (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {diet?.length &&
        diet?.map((x, i) =>
          renderCheckbox(
            i18next.language === "de" ? x?.german : x?.diet_name,
            x?.id,
            "diet",
            i
          )
        )}
    </Box>
  );

  const handlemeal = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getmealcat);
      if (res?.s) setMeal(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handlediet = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getdietcat);
      if (res?.s)
      {
        setDiet(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handlemeal();
    handlediet();
  }, [i18next.language]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          width: "100%",
        }}
      >
        <Box sx={{ maxWidth: "1400px", m: "auto", width: "100%" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: theme.palette.text.active,
              }}
            >
              {t("becomePartner.step2.foodTruckDetails.title")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.image.title")}
              </Box>
              <DragandDrop
                setImage={setImage}
                setFormikImage={setFormikImage}
                apiPreview={
                  typeof formik.values.image === "string"
                    ? API_endpoints.baseURL + formik.values.image
                    : null
                }
              />{" "}
              {imageError && (
                <Typography color="error" variant="body2">
                  {imageError}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: { xs: 2, sm: 2 },
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.truckName.title")}
                </Box>

                <TextField
                  type="text"
                  id="truck_name"
                  name="truck_name"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.truck_name}
                  error={
                    formik.touched.truck_name &&
                    Boolean(formik.errors.truck_name)
                  }
                  helperText={
                    formik.touched.truck_name && formik.errors.truck_name
                  }
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.truckName.pl"
                  )}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1}>
                  <StarSVG />{" "}
                  {t("becomePartner.step2.foodTruckDetails.address.title")}
                </Box>

                <TextField
                  type="text"
                  id="address"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  variant="outlined"
                  placeholder={t(
                    "becomePartner.step2.foodTruckDetails.address.pl"
                  )}
                  multiline
                  fullWidth
                  rows={4}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.zipCode.title")}
              </Box>
              <TextField
                type="number"
                id="zip_code"
                name="zip_code"
                fullWidth
                inputProps={{ min: "0" }} // Prevent negative numbers via UI
                onChange={(e) => {
                  const { value } = e.target;

                  // Prevent negative numbers
                  if (value && Number(value) < 0)
                  {
                    e.target.value = ""; // Clear the input if the value is negative
                    return; // Prevent further processing
                  }

                  // Update formik state
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.zip_code}
                error={
                  formik.touched.zip_code && Boolean(formik.errors.zip_code)
                }
                helperText={formik.touched.zip_code && formik.errors.zip_code}
                variant="outlined"
                placeholder={t(
                  "becomePartner.step2.foodTruckDetails.zipCode.pl"
                )}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.area.title")}
              </Box>
              <FormControl
                fullWidth
                error={
                  formik.touched.work_area_radius &&
                  Boolean(formik.errors.work_area_radius)
                }
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Select
                  fullWidth
                  id="work_area_radius"
                  name="work_area_radius"
                  value={+formik.values.work_area_radius}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    color: theme.palette.text.select_input,
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      color: theme.palette.text.defaultDark,
                      display: "none",
                    }}
                    value={0}
                  >
                    {t("becomePartner.step2.foodTruckDetails.area.pl")}
                  </MenuItem>

                  {[
                    {
                      label: "50Km",
                      value: 50,
                    },
                    {
                      label: "100Km",
                      value: 100,
                    },
                    {
                      label: "250Km",
                      value: 250,
                    },
                    {
                      label: "500Km",
                      value: 500,
                    },
                    {
                      label: "Germany-wide",
                      value: -1,
                    },
                  ]?.map((e, i) => (
                    <MenuItem
                      style={{ color: theme.palette.text.defaultDark }}
                      key={i}
                      value={e.value}
                    >
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>

                {formik.touched.work_area_radius &&
                  formik.errors.work_area_radius && (
                    <FormHelperText>
                      {formik.errors.work_area_radius}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.category.title")}
              </Box>
              <FormControl
                fullWidth
                error={
                  formik.touched.truck_cat_id &&
                  Boolean(formik.errors.truck_cat_id)
                }
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Select
                  defaultValue={0}
                  fullWidth
                  id="truck_cat_id"
                  name="truck_cat_id"
                  value={formik.values.truck_cat_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    color: theme.palette.text.select_input,
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      color: theme.palette.text.defaultDark,
                      display: "none",
                    }}
                    value={0}
                  >
                    {t("becomePartner.step2.foodTruckDetails.category.title")}
                  </MenuItem>

                  {Array.isArray(categories) &&
                    categories?.length > 0 &&
                    categories?.map((e, i) => (
                      <MenuItem
                        style={{ color: theme.palette.text.defaultDark }}
                        key={i}
                        value={e.id}
                      >
                        {e.truck_category}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.truck_cat_id && formik.errors.truck_cat_id && (
                  <FormHelperText>{formik.errors.truck_cat_id}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              {/* <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.foodTruckDetails.specification.title")}
              </Box>
              <TextField
                type="text"
                id="specifications"
                name="specifications"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specifications}
                error={
                  formik.touched.specifications &&
                  Boolean(formik.errors.specifications)
                }
                helperText={
                  formik.touched.specifications && formik.errors.specifications
                }
                variant="outlined"
                placeholder={t(
                  "becomePartner.step2.foodTruckDetails.specification.pl"
                )}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              /> */}
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box py={1}>
              <StarSVG /> {t("becomePartner.step2.foodTruckDetails.desc.title")}
            </Box>

            <TextField
              type="text"
              id="description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              variant="outlined"
              placeholder={t("becomePartner.step2.foodTruckDetails.desc.pl")}
              multiline
              fullWidth
              rows={4}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
            }}
          >
            {/* Meals Section */}
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("bookNowForm.catering_Type")}
              </Box>
              <FormGroup row>{renderMealCheckboxes()}</FormGroup>
              {formik.touched.meal && formik.errors.meal && (
                <FormHelperText error>{formik.errors.meal}</FormHelperText>
              )}
            </Box>

            {/* Special Diets Section */}
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step3.spDiet.title")}
              </Box>
              <FormGroup row>{renderDietCheckboxes()}</FormGroup>
              {formik.touched.diet && formik.errors.diet && (
                <FormHelperText error>{formik.errors.diet}</FormHelperText>
              )}
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: theme.palette.text.active,
                mt: 2,
              }}
            >
              {t("becomePartner.step2.questions.title")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step2.questions.truckSize.title")}
              </Box>
              <TextField
                type="number"
                id="size"
                name="size"
                fullWidth
                inputProps={{ min: "0" }}
                onChange={(e) => {
                  const { value } = e.target;

                  if (value && Number(value) < 0)
                  {
                    e.target.value = "";
                    return;
                  }

                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.size}
                error={formik.touched.size && Boolean(formik.errors.size)}
                helperText={formik.touched.size && formik.errors.size}
                variant="outlined"
                placeholder={t("becomePartner.step2.questions.truckSize.pl")}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "12px",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.questions.electricity.title")}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="electricity_type"
                id="electricity_type"
                row
                value={formik.values.electricity_type}
                onChange={(e) => {
                  formik.setFieldValue("electricity_type", e.target.value);
                }}
              >
                <FormControlLabel
                  value="16A"
                  control={<Radio value="16A" />}
                  label={"16A"}
                />
                <FormControlLabel
                  value="32A"
                  control={<Radio />}
                  label={`32A`}
                />
                <FormControlLabel
                  value="230V"
                  control={<Radio />}
                  label={`230V`}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.electricity.other"
                  )}`}
                />
              </RadioGroup>
              {formik.touched.electricity_type &&
                formik.errors.electricity_type && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.electricity_type}
                  </Typography>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG />{" "}
                {t("becomePartner.step2.questions.operatingMode.title")}{" "}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                row
                value={formik.values.operating_mode}
                onChange={(e) => {
                  formik.setFieldValue("operating_mode", e.target.value);
                }}
                name="operating_mode"
              >
                <FormControlLabel
                  value="KWH"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.operatingMode.op1"
                  )}`}
                />
                <FormControlLabel
                  value="GAS"
                  control={<Radio />}
                  label={`${t(
                    "becomePartner.step2.questions.operatingMode.op2"
                  )}`}
                />
              </RadioGroup>
              {formik.touched.operating_mode &&
                formik.errors.operating_mode && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.operating_mode}
                  </Typography>
                )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box py={1}>
                <StarSVG /> {t("becomePartner.step2.questions.water.title")}{" "}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="female"
                row
                value={formik.values.is_water_required}
                onChange={formik.handleChange}
                name="is_water_required"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={`${t("becomePartner.step2.questions.water.op1")}`}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={`${t("becomePartner.step2.questions.water.op2")}`}
                />
              </RadioGroup>
              {formik.touched.is_water_required &&
                formik.errors.is_water_required && (
                  <Typography sx={{ fontSize: "12px" }} color="error">
                    {formik.errors.is_water_required}
                  </Typography>
                )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <ButtonComponent
              disabled={loading}
              onClick={formik.handleSubmit}
              label={
                loading ? (
                  <CircularProgress style={{ color: "#f57c00" }} />
                ) : (
                  t("addtruck.submit")
                )
              }
              sx={{
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddTruckScreen;
