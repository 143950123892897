import { initializeApp, } from "firebase/app";
import { getFirestore, } from "firebase/firestore";
import { getStorage, } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyA87zVPtF5hhLvSK3nJW3yRb-nvkOExcdM",
    authDomain: "catering-fiesta-web.firebaseapp.com",
    projectId: "catering-fiesta-web",
    storageBucket: "catering-fiesta-web.appspot.com",
    messagingSenderId: "491594110987",
    appId: "1:491594110987:web:6b725b6eab632b006b4f0e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const firebaseStorage = getStorage(app)
export { app, firebaseStorage, db }