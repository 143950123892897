import { Box, Typography } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneNumber = ({
  label,
  placeholder,
  name,
  value,
  errors,
  onChange,
  isLoading,
  country,
  theme,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
     

      <PhoneInput
        style={{
          outline: "none !important",
          color: theme.palette.text.defaultDark,
        }}
        className=""
        name={name}
        country={country}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputClass="inputClassCountry"
      />

      {errors ? (
        <Typography
          sx={{
            color: "red",
            fontWeight: "400",
            fontSize: { xs: "14px", sm: "14px" },
            marginTop: "5px",
          }}
        >
          {errors}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CustomPhoneNumber;
