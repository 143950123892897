import { Box, Typography, useTheme } from "@mui/material";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";

const MenuCard = ({ data }) => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const navigate = useNavigate()
  return (
    <Box sx={{ cursor: "pointer" }} onClick={() => navigate(NavRoutes.TRUCKS, {
      state: { formValues: { meal: [data?.id] } }
    })}>
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          height: "180px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          },
          "&:hover img": {
            transform: "scale(1.1)", // Scale the image slightly
          },
        }}
      >
        <img
          src={API_endpoints.baseURL + "/" + data?.image}
          alt={`${data?.meal_name}`}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 0,
            top: 0,
            left: 0,
            transition: "transform 0.3s ease-in-out", // Add smooth transition
          }}
        />
        <Typography
          sx={{
            color: theme.palette.text.defaultLight,
            fontSize: {
              xs: "20px",
              sm: "24px",
              lg: "26px",
              xl: "28px",
            },
            fontWeight: 600,
            width: "78%",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          {i18n?.language === "de" ? data?.german : data?.meal_name}
        </Typography>
      </Box>
    </Box>
  );
};

export default MenuCard;
