import { Box, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { API_endpoints } from "../../../API/API_Endpoints";
import DeleteMsgMenu from "./DeleteMsgMenu";
import { collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";

const ChatHeader = ({
    selectedChatUserDetails,
    setSelectedChatFirebaseId,
    setSelectedChatUserId,
    setSelectedChatUserDetails, selectedChatFirebaseId,
}) => {
    const handleBack = () => {
        setSelectedChatFirebaseId(null);
        setSelectedChatUserId("");
        setSelectedChatUserDetails({});
    };

    const handleDeleteChat = async () => {
        try
        {
            const res = await deleteDoc(doc(db, 'chat', selectedChatFirebaseId.toString()))
            handleBack()
        } catch (error)
        {
            console.log(error)
        }
    }
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
                p: 1,
                pt: 0,
                borderBottom: "1px solid #00000033",
                borderRadius: "7px",
                width: "100%",
            }}
        >
            <Box sx={{ display: { md: "none" } }}>
                <ChevronLeftRoundedIcon
                    onClick={() => handleBack()}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 1,
                    p: 1,
                    width: "100%",
                }}
            >
                <img
                    src={API_endpoints.baseURL + selectedChatUserDetails?.profile_img}
                    style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                    alt=""
                />
                <Typography
                    sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        textTransform: "capitalize",
                    }}
                >
                    {" "}
                    {selectedChatUserDetails?.full_name}
                </Typography>
            </Box>
            <DeleteMsgMenu handleDelete={handleDeleteChat} />
        </Box>
    );
};

export default ChatHeader;
