import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import booking_img from "../../Assets/images/cate1.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ButtonComponent from "../../Component/Button/Button";
import { API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { toast } from "react-toastify";
const BookingCard = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  console.log(data, "data");
  const handleCancel = async () => {
    try {
      let f = new FormData();
      f.append("booking_id", data.id);
      if (data?.booking_status == 0) {
        let res = await API_POST(API_endpoints.cancelbooking, f);
        if (res.s) {
          toast.success("Cancelled successfully");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.main,
        borderRadius: "18px",
        p: 3,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        gap: { xs: 0.5, md: 2 },
        height: { xs: "", md: "180px" },
        width: { xs: "100%", sm: "80%", md: "100%" },
        m: "auto",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "100%" },
          m: "auto",
          height: { xs: "120px", sm: "180px", md: "100%" },
        }}
      >
        <Box sx={{ height: "100%" }}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            src={booking_img}
            alt="preview"
          />
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "50px", md: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: "center",
            height: "100%",
            p: 1,
            py: { xs: 0, md: 4 },
            gap: 1,
            justifyContent: { xs: "space-between", md: "center" },
          }}
        >
          <Typography>Company Name</Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              textAlign: "center",
            }}
          >
            {data?.company_details?.company_name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto", md: "100%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: "center",
              height: { xs: "auto", md: "100%" },
              p: 1,
              py: { xs: 0, md: 4 },
              justifyContent: "space-between",
            }}
          >
            <Typography>Start Date</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: theme.palette.background.lightorange,
                p: { xs: 1, md: 1 },
                borderRadius: "8px",
                width: "fit-content",
              }}
            >
              <CalendarMonthIcon sx={{ color: theme.palette.text.active }} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "13px", sm: "14px", md: "16px" },
                }}
              >
                {data?.from_date}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto", md: "100%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: "center",
              height: { xs: "auto", md: "100%" },
              p: 1,
              py: { xs: 0, md: 4 },
              justifyContent: "space-between",
            }}
          >
            <Typography>End Date</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: theme.palette.background.lightorange,
                p: { xs: 1, md: 1 },
                borderRadius: "8px",
                width: "fit-content",
              }}
            >
              <CalendarMonthIcon sx={{ color: theme.palette.text.active }} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "13px", sm: "14px", md: "16px" },
                }}
              >
                {data?.to_date}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: { xs: "50px", md: "100%" },
          justifyContent: "center",
          alignItems: "center",
          mt: { xs: 1, md: 0 },
        }}
      >
        <ButtonComponent
          onClick={() => handleCancel(data)}
          label={
            data?.booking_status == 0
              ? "Cancel"
              : data?.booking_status == 1
              ? "Approved"
              : "Cancelled"
          }
          sx={{
            // display: data?.booking_status == -1 ? "none" : "block",
            border: "solid #FF00006B",
            color: "#FF00006B",
          }}
        />
      </Box>
    </Box>
  );
};

export default BookingCard;
