import BannerImage from "../../Component/BannerImage/BannerImage";
import {
    Box,
    Grid,
    useTheme,
    Typography,
    CircularProgress,
} from "@mui/material";
import TruckCard from "../HomePage/Sections/Cards/TruckCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SearchSection from "../../Pages/HomePage/Sections/SearchSection";
import { API_GET_OPEN } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import Noresult from "../../Component/Noresultfound/Noresult";
import { useTranslation } from "react-i18next";
import { useCompanyId } from "../../Context/CompanyIdContext";
import FoodTruckEditModal from "../FoodTruckEdit/FoodTruckEditModal";
import CatServiceCard from "../HomePage/Sections/Cards/CatServiceCard";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";

const PAGE_SIZE = 15;

const SearchResultScreen = () => {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [catererData, setCatererData] = useState([]);
    const location = useLocation();
    const { t } = useTranslation();
    const { companyId } = useCompanyId();
    const [open, setOpen] = useState(false);
    const [truckData, setTruckData] = useState({});
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [isMoreFoodTruck, setIsMoreFoodTruck] = useState(false);
    const [isMoreCaterer, setIsMoreCaterer] = useState(false);
    const navigate = useNavigate();

    const getData = async (
        count = 0,
        meal_ids = [],
        mealRef = [],
        diet_ids = [],
        dietRef = [],
        cat_ids = [],
        catRef = [],
        zip_code = "",
        zipRef = ""
    ) => {
        try
        {
            setLoading(true);
            if (
                meal_ids !== mealRef ||
                diet_ids !== dietRef ||
                cat_ids !== catRef ||
                zip_code !== zipRef
            )
            {
                setCount(0);
                setData([]);
            }
            const params = new URLSearchParams({
                count: count,
                offset: PAGE_SIZE,
            });
            if (meal_ids?.length > 0) params.append("meal_ids", meal_ids);
            if (diet_ids?.length > 0) params.append("diet_ids", diet_ids);
            if (cat_ids?.length > 0) params.append("cat_ids", cat_ids);
            if (zip_code !== "") params.append("zip_code", zip_code);
            // all truck api
            let res = await API_GET_OPEN(
                `${API_endpoints.getalltrucks}?${params.toString()}`
            );

            if (res?.s && res?.c)
            {
                setData(res?.r?.slice(0, 3));
                res?.c > 3 ? setIsMoreFoodTruck(true) : setIsMoreFoodTruck(false);
                setCount(res?.c ?? 0);
            } else
            {
                setData([]);
                setCount(0);
                setIsMoreFoodTruck(false);
            }
        } catch (error)
        {
            console.log(error);
        } finally
        {
            setLoading(false);
        }
    };

    const getCatererData = async (
        count = 0,
        meal_ids = [],
        mealRef = [],
        diet_ids = [],
        dietRef = [],
        cat_ids = [],
        catRef = [],
        zip_code = "",
        zipRef = ""
    ) => {
        try
        {
            setLoading(true);
            if (
                meal_ids !== mealRef ||
                diet_ids !== dietRef ||
                cat_ids !== catRef ||
                zip_code !== zipRef
            )
            {
                setCount(0);
                setData([]);
            }
            const params = new URLSearchParams({
                count: count,
                offset: PAGE_SIZE,
            });
            if (meal_ids?.length > 0) params.append("meal_ids", meal_ids);
            if (diet_ids?.length > 0) params.append("diet_ids", diet_ids);
            if (cat_ids?.length > 0) params.append("cat_ids", cat_ids);
            if (zip_code !== "") params.append("zip_code", zip_code);
            let res = await API_GET_OPEN(
                `${API_endpoints.getallcaterers}?${params.toString()}`
            );

            if (res?.s && res?.c)
            {
                setCatererData(res?.r?.slice(0, 3));
                res?.c > 3 ? setIsMoreCaterer(true) : setIsMoreCaterer(false);
                setCount(res?.c ?? 0);
            } else
            {
                setCatererData([]);
                setCount(0);
                setIsMoreCaterer(false);
            }
        } catch (error)
        {
            console.log(error);
        } finally
        {
            setLoading(false);
        }
    };

    const openModal = async (data) => {
        setOpen(true);
        setTruckData(data);
    };

    useEffect(() => {
        window.scrollTo({ behavior: "instant", top: 0 });
    }, []);

    let mealRef = useRef(location?.state?.formValues?.meal ?? []);
    let dietRef = useRef(location?.state?.formValues?.diet ?? []);
    let catRef = useRef(location?.state?.formValues?.categories ?? []);
    let zipRef = useRef(location?.state?.formValues?.zipCode ?? "");

    useEffect(() => {
        getData(
            0,
            location?.state?.formValues?.meal ?? [],
            mealRef.current,
            location?.state?.formValues?.diet ?? [],
            dietRef.current,
            location?.state?.formValues?.categories ?? [],
            catRef.current,
            location?.state?.formValues?.zipCode ?? "",
            zipRef.current
        );
        mealRef.current = location?.state?.formValues?.meal;
        dietRef.current = location?.state?.formValues?.diet;
        catRef.current = location?.state?.formValues?.categories;
        zipRef.current = location?.state?.formValues?.zipCode;
    }, []);

    useEffect(() => {
        getCatererData(
            0,
            location?.state?.formValues?.meal ?? [],
            mealRef.current,
            location?.state?.formValues?.diet ?? [],
            dietRef.current,
            location?.state?.formValues?.categories ?? [],
            catRef.current,
            location?.state?.formValues?.zipCode ?? "",
            zipRef.current
        );
        mealRef.current = location?.state?.formValues?.meal;
        dietRef.current = location?.state?.formValues?.diet;
        catRef.current = location?.state?.formValues?.categories;
        zipRef.current = location?.state?.formValues?.zipCode;
    }, []);

    return (
        <Box>
            {/* Banner Section */}
            <BannerImage
                path={{
                    title: t("contact.fiesta"),
                    home: t("Navbar.Home"),
                    nav: t("search.btn"),
                }}
            />
            <Box
                sx={{
                    background: theme.palette.background.gd,
                }}
            >
                <SearchSection
                    count={count}
                    values={location?.state?.formValues}
                    getData={getData}
                    getCatererData={getCatererData}
                />
                <Box
                    sx={{
                        width: "90%",
                        maxWidth: "1440px",
                        m: "auto",
                        minHeight: "300px",
                    }}
                >
                    {data?.length > 0 || catererData?.length > 0 ? (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Typography
                                    variant="h3"
                                    className="fontSnugle"
                                    sx={{
                                        color: theme.palette.text.dark,
                                        fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                                        textAlign: "start",
                                        display: data?.length > 0 ? "block" : "none",
                                    }}
                                >
                                    {t("foodTruck.title")}
                                </Typography>
                            </Box>

                            <Box sx={{ pb: 5, display: data?.length > 0 ? "block" : "none" }}>
                                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                                    {data?.length > 0 && (
                                        <>
                                            {data?.map((x, i) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={4}
                                                        key={i}
                                                        sx={{
                                                            transition: "transform 0.3s ease-in-out",
                                                        }}
                                                    >
                                                        <TruckCard
                                                            data={x}
                                                            openModal={openModal}
                                                            title={
                                                                x?.company_id === companyId
                                                                    ? t("explore.edit")
                                                                    : t("myBooking.booknow")
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                justifyContent="center"
                                                sx={{
                                                    pb: 5,
                                                    display: isMoreFoodTruck ? "flex" : "none",
                                                }}
                                            >
                                                <span
                                                    className="veiwMore"
                                                    style={{
                                                        fontWeight: "600",
                                                        color: theme.palette.text.active,
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        navigate(NavRoutes.TRUCKS, {
                                                            state: {
                                                                formValues: location?.state?.formValues,
                                                            },
                                                        })
                                                    }
                                                >
                                                    {t("explore.more")}
                                                </span>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Typography
                                    variant="h3"
                                    className="fontSnugle"
                                    sx={{
                                        color: theme.palette.text.dark,
                                        fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                                        textAlign: "start",
                                        display: catererData?.length > 0 ? "block" : "none",
                                    }}
                                >
                                    {t("cateringList.bannerTitle")}
                                </Typography>
                            </Box>
                            {/* <span
                                style={{
                                    fontWeight: "600",
                                    color: theme.palette.text.active,
                                    cursor: "pointer",
                                    display: catererData?.length > 0 ? "block" : "none",
                                }}
                            >
                                {t("cateringList.bannerTitle")}
                            </span> */}
                            <Box
                                sx={{
                                    pb: 2,
                                    display: catererData?.length > 0 ? "block" : "none",
                                }}
                            >
                                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                                    {catererData?.length > 0 && (
                                        <>
                                            {catererData?.map((x, i) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                    key={i}
                                                    sx={{
                                                        transition: "transform 0.3s ease-in-out",
                                                    }}
                                                >
                                                    <CatServiceCard data={x} />
                                                </Grid>
                                            ))}
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                justifyContent="center"
                                                sx={{ pb: 5, display: isMoreCaterer ? "flex" : "none" }}
                                            >
                                                <span
                                                    className="veiwMore"
                                                    style={{
                                                        fontWeight: "600",
                                                        color: theme.palette.text.active,
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        navigate(NavRoutes.CATERING_LIST, {
                                                            state: {
                                                                formValues: location?.state?.formValues,
                                                            },
                                                        })
                                                    }
                                                >
                                                    {t("explore.more")}
                                                </span>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        </>
                    ) : !loading ? (
                        <Box>
                            <Typography
                                className="fontSnugle"
                                sx={{ textAlign: "center", fontSize: "22px" }}
                            >
                                {t("foodTruck.noData")}
                            </Typography>
                            <Noresult />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "350px",
                            }}
                        >
                            <CircularProgress style={{ color: "#f57c00" }} />
                        </Box>
                    )}
                </Box>
            </Box>
            <FoodTruckEditModal
                setOpen={setOpen}
                open={open}
                data={truckData}
                getData={getData}
                compID={companyId}
            />
        </Box>
    );
};

export default SearchResultScreen;
