import React, { useEffect, useState } from "react";
import HomeSection from "./Sections/HomeSection";
import SearchSection from "./Sections/SearchSection";
import ExploreSection from "./Sections/ExploreSection";
import CateringSection from "./Sections/CateringSection";
import MenuSection from "./Sections/MenuSection";
import AboutSection from "./Sections/AboutSection";
import CateringServiceSection from "./Sections/CateringServicesSection";
import EventandcatSection from "./Sections/EventandcatSection";
import Subscription from "./Sections/Subscription";
import { API_GET2, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NewsletterBox from "./Sections/Newsletter";

const HomePage = () => {
  const userData = JSON.parse(localStorage.getItem("Fiesta"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [apiCalled, setApiCalled] = useState(false);
  const [NewsApiCalled, setNewsApiCalled] = useState(false);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionId = urlParams.get("subscription_id");
  const NewsLetterEmailSubscribe = urlParams.get("newsletter-subscribe");
  const NewsLetterEmailUnsubscribe = urlParams.get("newsletter-unsubscribe");
  const NewsLetterType = urlParams.get("type");

  const handleVerifySubscriptionPaymentStatus = async (sub_id) => {
    try
    {
      setApiCalled(true);
      const res = await API_GET2(
        `${API_endpoints.verifySubscription}?subscription_id=${sub_id}`
      );
      if (res.s)
      {
        if (res?.r?.status === "ACTIVE")
        {
          let fiesta = localStorage.getItem("Fiesta");
          fiesta = fiesta ? JSON.parse(fiesta) : {};
          fiesta.role = 2;
          localStorage.setItem("Fiesta", JSON.stringify(fiesta));
          window.dispatchEvent(
            new CustomEvent("userUpdate", { detail: { role: 2 } })
          );
          toast.success(t("becomePartner.step5.congrats"));
          navigate(NavRoutes.HOME);
        } else
        {
          toast.error(t("becomePartner.step5.error"));
          navigate(NavRoutes.HOME);
        }
      }
    } catch (error)
    {
      navigate(NavRoutes.HOME);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!apiCalled && subscriptionId)
    {
      handleVerifySubscriptionPaymentStatus(subscriptionId);
      setApiCalled(true);
    }
    // }, [subscriptionId, apiCalled]);
  }, []);

  const handleSubscribeNewsLeter = async (email, userType) => {
    try
    {
      const URL = NewsLetterEmailSubscribe
        ? API_endpoints.subscribeNewsLetter
        : API_endpoints.UnSubscribeNewsLetter;
      let res = await API_POST(URL, {
        email: email,
        type: userType ?? null,
      });

      if (res?.s)
      {
        setNewsApiCalled(true);
        toast.success(res?.m);
        navigate(NavRoutes.HOME);
      } else
      {
        toast.info(res?.m);
      }
    } catch (error)
    {
      console.log(error);
      navigate(NavRoutes.HOME);
    }
  };

  useEffect(() => {
    if (!NewsApiCalled)
    {
      if (NewsLetterEmailSubscribe && NewsLetterType)
      {
        handleSubscribeNewsLeter(NewsLetterEmailSubscribe, NewsLetterType);
      } else if (NewsLetterEmailUnsubscribe)
      {
        handleSubscribeNewsLeter(NewsLetterEmailUnsubscribe);
      }
    }
  }, [NewsApiCalled]);

  return (
    <>
      <HomeSection />
      <SearchSection />
      <ExploreSection />
      {userData && userData.role === 2 && <Subscription />}
      <CateringSection />
      <CateringServiceSection />
      <MenuSection />
      <AboutSection />
      <EventandcatSection />
      <NewsletterBox />
    </>
  );
};

export default HomePage;
