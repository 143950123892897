import { Box, Typography, useTheme, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import burger from "../../../Assets/images/home/8 Startseite Burger.webp";
import pizza from "../../../Assets/images/home/pasta.webp";
import icecream from "../../../Assets/images/home/6 Startseite Sweets & Candy.webp";
import bbq from "../../../Assets/images/home/7 Startseite Fingerfood & Snacks.webp";
import MenuCard from "./Cards/MenuCard";
import ScrollAnimation from "react-animate-on-scroll";

import sideimg1 from "../../../Assets/images/strip1.png";
import { Link } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
import { API_GET } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { useEffect } from "react";
const DEFAULT_CATEGORIES = [1, 6, 4, 13];
const MenuSection = ({ title }) => {
  const theme = useTheme();
  const { t ,} = useTranslation();
  const [meal, setMeal] = useState([]);

  const handleMeal = async () => {
    try {
      let res = await API_GET(API_endpoints.getmealcat);
      res?.s &&
        setMeal(
          res?.r
            ?.filter((x) => DEFAULT_CATEGORIES.includes(x?.id))
            .sort((x, y) => y?.id - x?.id)
        );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleMeal();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        position: "relative",
        flexDirection: "column",
        paddingY: 5,
        gap: 5,
      }}
    >
      <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
        <img src={sideimg1} alt="" height={40} />
      </Box>
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "50%", lg: "50%" },
          m: "auto",
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
            textAlign: "center",
          }}
        >
          {t("Menu.menutitle")}
        </Typography>
        {/* <Typography
          variant="h6"
          // className="fontSnugle"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "18px", md: "20px" },
            textAlign: "center",
            mt: "0.5rem",
          }}
        >
          {t("Menu.subtitle")}
        </Typography> */}
      </Box>

      <Box sx={{ width: { xs: "90%", xl: "80%" }, marginX: "auto" }}>
        <Grid
          container
          spacing={3}
          sx={{ mt: 2 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {meal?.length > 0 &&
            meal?.map((x, i) => {
              const isLeft = i < 2; // First two cards slide in from the left

              return (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2.5} key={i}>
                  <ScrollAnimation
                    animateIn={
                      isLeft ? "animate__fadeInLeft" : "animate__fadeInRight"
                    }
                    duration={1}
                  >
                    <MenuCard data={x} />
                  </ScrollAnimation>
                </Grid>
              );
            })}
          {/* Button Section */}
          <Grid item xs={12} className="flexCenter" sx={{ p: 1 }}>
            <Link to={NavRoutes.TRUCKS}>
              <span
                style={{
                  fontWeight: "600",
                  color: theme.palette.text.active,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                className="veiwMore"
              >
                {t("explore.more")}
              </span>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MenuSection;
