import { Box } from "@mui/material";
import ProfileCard from "./ProfileCard";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";
import { useEffect, useState } from "react";

const ChatListComponent = ({
  setSelectedChatFirebaseId,
  selectedChatFirebaseId,
  setSelectedChatUserId,
  setSelectedChatUserDetails,
}) => {
  const [usersList, setUsersList] = useState([]);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const dbRef = collection(db, "chat");
  const getUserQuery = query(
    dbRef,
    where("users", "array-contains", id),
    orderBy("sentAt", "desc")
  );
  useEffect(() => {
    const unsubscribe = onSnapshot(getUserQuery, (snapshot) => {
      const myData = [];
      snapshot.forEach((d) => {
        myData.push({
          id: d.id,
          ...d.data(),
        });
      });

      setUsersList(myData);
      // setIsChatLoading(false);
      return () => {
        unsubscribe();
      };
    });
  }, [selectedChatFirebaseId]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "start",
        alignItems: "center",
        height: "100%",
        overflow: "scroll",
        p: 1,
        background: "#F5F5F5",
      }}
    >
      {usersList.length > 0 ? (
        usersList?.map((data, index) => (
          <ProfileCard
            key={index}
            data={data}
            myId={id}
                selectedChatFirebaseId={selectedChatFirebaseId}
            userId={data?.users?.filter((x) => x !== id)[0]}
            setSelectedChatFirebaseId={setSelectedChatFirebaseId}
            setSelectedChatUserId={setSelectedChatUserId}
            setSelectedChatUserDetails={setSelectedChatUserDetails}
          />
        ))
      ) : (
        <Box
          className="flexCenter"
          sx={{ height: "100%", width: "100%", color: "grey" }}
        >
          No Data
        </Box>
      )}
    </Box>
  );
};

export default ChatListComponent;
