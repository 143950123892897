import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
export default function DeleteMsgMenu({  handleDelete, data }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeHandle = () => {
        setAnchorEl(null);
    };

    const DeleteMsg = (data) => {
        closeHandle();
        handleDelete(data);
    };

    return (
        <Box sx={{ ml: "10px" }}>
            <Box onClick={handleClick} sx={{ display: "flex", alignItems: "center" }}>
                <Box
                    className="displayModal"
                    sx={{
                        width: { xs: "20px", md: "25px" },
                        height: { xs: "20px", md: "25px" },
                        borderRadius: "15px",
                        boxSizing: "border-box",
                        cursor: "pointer",
                    }}
                >
                    <MoreVertIcon />
                </Box>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={closeHandle}
                sx={{
                    "& .MuiList-root": {
                        pt: 0,
                        pb: 0,
                    },
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "#ffffff",
                        color: "#000",
                        zIndex: 999999,
                        borderRadius: "10px",
                        p: 0,
                    },
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", color: "#000" }}>
                    <MenuItem sx={{ fontSize: "16px" }} onClick={() => DeleteMsg(data)}>
                        Unsend
                    </MenuItem>{" "}
                </Box>
                <Divider
                    sx={{
                        width: "80%",
                        bgcolor: "#eeeeee",
                        color: "#eeeeee",
                        border: "1px solid",
                        m: "auto",
                    }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MenuItem sx={{ fontSize: "16px" }} onClick={closeHandle}>
                        Cancel
                    </MenuItem>{" "}
                </Box>
            </Menu>
        </Box>
    );
}
