import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  useTheme,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import { API_GET, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { StarSVG, TruckSVG } from "../../../Component/SVG";
import cornerImg from "../../../Assets/images/Popular.svg";
import DiasImg from "../../../Assets/images/dis.svg";

const Step5 = forwardRef(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [tabIndex, setTabIndex] = useState(1);

  const handleSubmit = async (id) => {
    let fiesta = localStorage.getItem("Fiesta");
    fiesta = fiesta ? JSON.parse(fiesta) : {};
    try {
      setLoading(id);
      const f = new FormData();
      f.append("user_id", fiesta?.id);
      f.append("plan_id", id);
      const res = await API_POST(API_endpoints.planSubscribe, f);

      if (res?.s) {
        window.location.href = res?.r?.links[0]?.href;
      }

      setLoading(null);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMembershipList = async () => {
    try {
      const res = await API_GET(API_endpoints.getSubscriptionPlanList);
      res?.s && setData(res?.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMembershipList();
  }, []);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  const renderCards = () => {
    const filteredData =
      tabIndex === 0
        ? [data[0], data[2], data[4]]
        : [data[1], data[3], data[5]];

    return filteredData?.map((plan, index) => (
      <PlanCard
        key={index}
        data={plan}
        theme={theme}
        handleSubmit={handleSubmit}
        t={t}
        index={index}
        loading={loading === plan?.id}
      />
    ));
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight={600} padding="1rem">
        {t("becomePartner.step5.title")}
      </Typography>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <StarSVG />
          {/* <Typography variant="h6"> */}
          {t("becomePartner.step5.member.title")}
          {/* </Typography> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            gap: 1,
            pl: { sm: "150px" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 1,
              my: 2,
              boxShadow: "0px 0px 30px 0px #1A130C24",
              px: 1,
              py: 1,
              borderRadius: "30px",
              alignItems: "center",
            }}
          >
            <Box
              // variant={tabIndex === 0 ? "contained" : "outlined"}
              sx={{
                background: tabIndex === 0 && theme.palette.background.orange,
                color: tabIndex === 0 ? "white" : "#000",
                px: 2,
                py: 1,
                borderRadius: "30px",
                fontWeight: tabIndex === 0 ? "400" : "500",
                cursor: "pointer",
                transition: "all 0.7s ease",
              }}
              onClick={() => handleTabChange(0)}
            >
              {/* {t("becomePartner.step5.monthly")} */}
              Monthly
            </Box>
            <Box
              sx={{
                background: tabIndex === 1 && theme.palette.background.orange,
                color: tabIndex === 1 ? "white" : "#000",
                px: 2,
                fontWeight: tabIndex === 1 ? "400" : "500",
                py: 1,
                borderRadius: "30px",
                cursor: "pointer",
                transition: "all 0.7s ease",
              }}
              onClick={() => handleTabChange(1)}
            >
              {/* {t("becomePartner.step5.yearly")} */}
              Yearly
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              height: "100%",
              justifyContent: "center",
            }}
          >
            <img src={DiasImg} alt="" />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 5, md: 4 },
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          {renderCards()}
        </Box>
      </Box>
    </Box>
  );
});

export default Step5;

const PlanCard = ({ data, theme, handleSubmit, t, loading }) => {
  if (!data) return null;
  const background = [
    "", //for imdex management
    `linear-gradient(179.91deg, #FFF6ED 41.16%, #FFE6A5 128.42%)`,
    `linear-gradient(179.91deg, #FFF6ED 41.16%, #FFE6A5 128.42%)`,
    "linear-gradient(179.92deg, #DEE6F2 49.32%, #BADFFD 142.9%)",
    "linear-gradient(179.92deg, #DEE6F2 49.32%, #BADFFD 142.9%)",
    "linear-gradient(179.92deg, #E6FFF1 49.32%, #63FFAB 142.9%)",
    "linear-gradient(179.92deg, #E6FFF1 49.32%, #63FFAB 142.9%)",
  ];
  const color = [
    "", //for imdex management
    "#F57C00",
    "#F57C00",
    "#164FA1",
    "#164FA1",
    "#00BF58",
    "#00BF58",
  ];

  return (
    <Box
      className="flexBetween"
      sx={{
        p: 3,
        width: { xs: "100%", sm: "80%", lg: "333px" },
        background:
          data?.id === 1 || data?.id === 2
            ? background[data?.id]
            : data?.id === 3 || data?.id === 4
            ? background[data?.id]
            : (data?.id === 5 || data?.id === 6) && background[data?.id],
        borderRadius: "18px",
        position: "relative",
        flexDirection: "column",
        transition: "transform 0.4s ease, box-shadow 0.4s ease",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          transform: "scale(1.15)",
          // transform: "translateX(-20px)",
          zIndex: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: "-23px",
              m: "auto",
              width: "fir-content",
              background: "#fff",
              py: 1,
              px: 2,
              border: `1px solid ${color[data?.id]}28`,
              borderRadius: "10px",
              color: color[data?.id],
              fontSize: "20px",
              boxShadow: `1px 1px 5px ${color[data?.id]}28`,
            }}
          >
            {data?.name}
          </Typography>
        </Box>
        <img
          src={cornerImg}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            right: "0",
            width: "25%",
            borderTopRightRadius: "18px",
            display: data?.id == 4 ? "block" : "none",
          }}
        />
        <Box
          sx={{
            borderBottom: `2px solid ${color[data?.id]}28`,
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            gap: 1,
            px: 2,
          }}
        >
          <Typography
            sx={{
              color: color[data?.id],
              fontSize: "22px",
              fontWeight: "700",
            }}
          >
            {data?.price}€
          </Typography>
          <Typography
            sx={{
              color: color[data?.id],
              fontSize: "20px",
              // fontWeight: "700",
            }}
          >
            {data?.id === 1 || data?.id === 3 || data?.id === 5
              ? "/ month"
              : "/ year"}
          </Typography>
        </Box>
        <Box className="flexCenter" sx={{ mt: 3, mb: 1.5 }}>
          <TruckSVG color={color[data?.id]} alt="" />
        </Box>

        <Box sx={{ py: 1, minHeight: "90px" }}>
          <RenderListContent color={color[data?.id]} note={data?.description} />

          {/* <RenderListContent
          color={color[data?.id]}
          note={
            data?.truck_limit > 0
              ? `${data?.truck_limit === 1 ? "Single" : "Three"} Truck Management`
              : "Unlimited Truck Management"
          }
        /> */}
        </Box>
      </Box>

      <Box className="flexCenter" sx={{ py: 0.5 }}>
        <Button
          className={"fontSnugle"}
          onClick={() => handleSubmit(data?.plan_id)}
          type={"button"}
          sx={{
            position: "relative",
            borderRadius: "10px",
            height: "48px",
            padding: "4px 38px",
            textTransform: "none",
            fontSize: "18px",
            overflow: "hidden",
            background: color[data?.id],
            color: theme.palette.text.primary,
            zIndex: 1,
            "&::before": {
              content: '""',
              position: "absolute",
              width: "50%",
              height: "100px",
              top: -10,
              left: -10,
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              transition: "transform 0.6s ease",
              zIndex: -1,
              transform: "translateX(-100%) rotate(10deg)",
            },
            "&:hover::before": {
              transform: "translateX(350%)",
            },
          }}
        >
          {loading ? (
            <CircularProgress style={{ color: "#f57c00" }} />
          ) : (
            t("becomePartner.step5.subscribe")
          )}
        </Button>
      </Box>
    </Box>
  );
};

const RenderListContent = ({ color, note }) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 0.3,
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 0.7,
        pb: 0.5,
      }}
    >
      <DoneAllRoundedIcon
        style={{ color: color, fontSize: "18px", paddingTop: "3px" }}
      />
      <Typography sx={{ fontSize: "15px" }}>{note}</Typography>
    </Box>
  );
};
