import {
  Box,
  Typography,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/images/drag.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_endpoints } from "../../API/API_Endpoints";
import { API_POST } from "../../API/config";
import ButtonComponent from "../Button/Button";

export default function MultiCerti({ comp_id, certificates, handleUserData }) {
  const { t } = useTranslation();
  const [newTitle, setNewTitle] = useState("");
  const [newFile, setNewFile] = useState(null);
  const theme = useTheme();
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  comp_id = parseData?.companyId

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [], // Accept only image files
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const validFile = acceptedFiles[0];
        if (validFile.size > 5 * 1024 * 1024) {
          toast.error("File is too large. Max size is 5MB.");
          return;
        }
        setNewFile(validFile);
      }
    },
  });

  const handleSubmit = async () => {
    if (!newFile || !newTitle) {
      toast.error("Pleas upload a Certificate with title");
      return;
    }

    try {
      if (!newFile.id) {
        const formData = new FormData();
        formData.append("image", newFile);
        formData.append("name", newTitle);
        formData.append("company_id", comp_id);
        formData.append("type", 2);
        let res = await API_POST(API_endpoints.addIandC, formData);
        if (res.s) {
          handleUserData();
          // toast.success("Certificate uploaded successfully!");
          setNewFile(null);
          setNewTitle("");
        }
      }
    } catch (error) {
      toast.error("Failed to upload certificate");
    }
  };

  // Delete certificate from API
  const handleDeleteCertificate = async (certificateId) => {
    try {
      const f = new FormData();
      f.append("id", certificateId);
      f.append("status", 0);
      let res = await API_POST(`${API_endpoints.deleteImage}`, f);

      if (res.s) {
        handleUserData();
        toast.success("Certificate deleted successfully!");
      }
    } catch (error) {
      toast.error("Failed to delete certificate");
    }
  };

  return (
    <div>
      {/* Dropzone for adding a new image */}
      <Box
        p={2}
        m={"auto"}
        width={"100%"}
        maxWidth={"540px"}
        border={"1px dashed #F57C00"}
        borderRadius="10px"
        sx={{ background: `#FFFAF5` }}
      >
        <div
          {...getRootProps()}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <input {...getInputProps()} />
          {newFile ? (
            <img
              src={URL.createObjectURL(newFile)} // Use the uploaded file for the preview
              alt="uploaded-preview"
              style={{
                width: "100%",
                height: "90px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          ) : (
            <img
              src={upload}
              alt="upload-placeholder"
              width="100px"
              height="100px"
            />
          )}
          <Typography variant="body1" textAlign="center">
            {t("becomePartner.step2.foodTruckDetails.image.drag")}
          </Typography>
        </div>
      </Box>

      {/* Static Input for Title */}
      <Box
        mt={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <TextField
          fullWidth
          sx={{ color: "#000", maxWidth: "540px" }}
          value={newTitle} // Bind value to newTitle state
          onChange={(e) => setNewTitle(e.target.value)} // Update state on change
          placeholder={t("becomePartner.step4.certi.pl")}
          variant="outlined"
          size="small"
          error={newFile && !newTitle.trim()}
          helperText={
            newFile &&
            !newTitle.trim() &&
            `${t("becomePartner.step4.certi.Required")}`
          }
          InputProps={{
            sx: {
              borderRadius: "8px",
              color: theme.palette.text.defaultDark,
              "&:hover": {
                color: theme.palette.text.active,
                bgcolor: theme.palette.background.inputBg,
                borderRadius: "8px",
              },
            },
          }}
        />
      </Box>

      {/* Submit Button */}
      <Box mt={2} textAlign="center">
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!newTitle.trim() || !newFile}
          label={t("Upload")}
          sx={{
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>

      {/* Displaying already uploaded certificates */}
      <Box
        mt={2}
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent={"center"}
      >
        {certificates?.map((certificate) => (
          <Box
            key={certificate.id}
            width="100px"
            height="150px"
            position="relative"
          >
            <img
              src={API_endpoints.baseURL + "/" + certificate.image}
              alt="uploaded-preview"
              style={{
                width: "100%",
                height: "90px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
            <Typography variant="body2" textAlign="center">
              {certificate.name}
            </Typography>
            <IconButton
              onClick={() => handleDeleteCertificate(certificate.id)}
              sx={{
                position: "absolute",
                top: 0,
                right: "-10px",
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <DeleteIcon sx={{ fontSize: "15px" }} />
            </IconButton>
          </Box>
        ))}
      </Box>
    </div>
  );
}
