import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_GET2, API_GET_USERID } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { useCompanyId } from "../../Context/CompanyIdContext";
import ButtonComponent from "../../Component/Button/Button";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import TruckCard from "../HomePage/Sections/Cards/TruckCard";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FoodTruckEditModal from "../FoodTruckEdit/FoodTruckEditModal";
const MyCompanyDetails = () => {
  const [data, setData] = useState();
  const theme = useTheme();
  const { companyID, renderTrigger } = useCompanyId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [companyimg, setCompanyimg] = useState([]);
  const [certi, setCerti] = useState([]);
  const [active, setActive] = useState(0);
  const [truckData, setTruckData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [usedTruck, setUsedTruck] = useState(0);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
 
  const dataBoxStyle = {
    background: "#DEE6F252",
    width: "100%",
    height: "Hug(55px)px",
    padding: "14px",
    gap: "10px",
    borderRadius: "12px",
    color: "#164FA1",
    fontWeight: "600",
  };
  const openModal = async (data) => {
    setOpen(true);
    setTruckData(data);
  };

  const handleUserData = async () => {
    try {
      setLoading(true);
      let res2 = await API_GET_USERID(API_endpoints.usergetbyid, id);
      setUserData(res2?.r?.company_details);
      getCompanyDetails(res2?.r?.company_details?.id);
      setUsedTruck(res2?.r?.company_details?.food_truck?.length);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCompanyDetails = async (companyID) => {
    companyID = parseData?.companyId
    setLoading(true);
    try {
      let res = await API_GET2(
        `${API_endpoints.getcompanybyid}?company_id=${companyID}`
      );
      if (res?.s) {
        setData(res?.r);
        setCompanyimg(res?.r?.image?.filter((e) => e?.type === 1));
        setCerti(res?.r?.image?.filter((e) => e?.type === 2));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, [renderTrigger]);

  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          <CircularProgress style={{ color: "#f57c00" }} />
        </Box>
      ) : (
        <Box sx={{ background: "#fff", maxWidth: "1400px", m: "auto" }}>
          <Box
            sx={{
              display: "flex",
              gap: 5,
              justifyContent: "space-between",
              p: 5,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box>
                  <img
                    src={
                      companyimg &&
                      API_endpoints.baseURL + "/" + companyimg[active]?.image
                    }
                    style={{
                      borderRadius: "12px",
                      width: "100%",
                      maxHeight: "500px",
                    }}
                    alt="preview"
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {companyimg.length > 1 &&
                    companyimg?.map((x, i) => {
                      return (
                        <Box
                          onClick={() => setActive(i)}
                          sx={{ width: "120px", height: "120px" }}
                        >
                          <img
                            key={i}
                            src={API_endpoints.baseURL + "/" + x.image}
                            alt=""
                            style={{
                              borderRadius: "12px",
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              <Box
                sx={{ mt: 3, display: certi?.length > 0 ? "block" : "none" }}
              >
                <Typography
                  sx={{
                    color: theme.palette.text.active,
                    fontSize: "18px",
                    mb: 1,
                  }}
                >
                  {t("myCompDetails.certificate")}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {certi &&
                    certi?.map((x, i) => {
                      return (
                        <Box key={i} sx={{ width: "120px", height: "160px" }}>
                          <img
                            src={API_endpoints.baseURL + "/" + x?.image}
                            alt="preview"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "12px",
                            }}
                          />
                          <Typography
                            sx={{ fontSize: "12px", textAlign: "center" }}
                          >
                            {x?.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              {/* <Box> */}{" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 5 },
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: { xs: 2, sm: 5 },
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("myCompDetails.meals")}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1.5,
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {data?.meal?.map((x, i) => (
                        <Box
                          key={i}
                          sx={{ ...dataBoxStyle, width: "fit-content" }}
                        >
                          <Typography>{x?.meal_name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column" },
                gap: { xs: 2, sm: 2 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column" },
                  gap: { xs: 2, sm: 2 },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: { xs: 2, sm: 2 },
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("myCompDetails.company")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.company_name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("myCompDetails.city")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.city}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("myCompDetails.code")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.postal_code}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box py={1} sx={{ fontWeight: "500" }}>
                      {t("myCompDetails.link")}
                    </Box>
                    <Box sx={dataBoxStyle}>
                      <Typography>{data?.website_link}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box py={1} sx={{ fontWeight: "500" }}>
                    {t("myCompDetails.about")}
                  </Box>
                  <Box
                    sx={{
                      ...dataBoxStyle,
                      height: "160px",
                      overflow: "scroll",
                    }}
                  >
                    <Typography>{data?.caterer?.about}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box py={1} sx={{ fontWeight: "500" }}>
                  {t("myCompDetails.diets")}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1.5,
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.diet?.map((x, i) => (
                    <Box key={i} sx={{ ...dataBoxStyle, width: "fit-content" }}>
                      <Typography>{x?.diet_name}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{}} className="flexCenter">
            <ButtonComponent
              onClick={() =>
                navigate(NavRoutes.OWNER_PROFILE, {
                  state: { id: companyID, editCompany: 2 },
                })
              }
              label={t("myCompDetails.edit")}
              sx={{
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
                width: "100%",
                maxWidth: "240px",
              }}
            />
          </Box>
          <Box
            sx={{
              background: "#fff",
              p: 5,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  // px: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "20px" }} fontWeight={600}>
                  {t("myCompDetails.myTrucks")}
                </Typography>
                <ButtonComponent
                  onClick={() =>
                    navigate(NavRoutes.ADD_TRUCK, { state: { id: companyID } })
                  }
                  label={t("myCompDetails.add")}
                  sx={{
                    background: theme.palette.background.btn,
                    color: theme.palette.text.primary,
                    display:
                      userData?.subscription?.status == "ACTIVE" &&
                      (userData?.subscription?.truck_limit == -1 ||
                        usedTruck < userData?.subscription?.truck_limit) &&
                      moment(userData?.subscription?.end_time).isAfter(moment())
                        ? "block"
                        : "none",
                  }}
                />
              </Box>

              <Box>
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                  {data?.food_truck?.length ? (
                    data.food_truck.map((x, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={i}
                        sx={{
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >
                        <TruckCard
                          data={{ ...x, avg_rating: data?.avg_rating }}
                          title={t("myCompDetails.edit")}
                          openModal={openModal}
                        />
                      </Grid>
                    ))
                  ) : (
                    <div> {t("myCompDetails.noData")}</div>
                  )}{" "}
                </Grid>
              </Box>
            </Box>
          </Box>
          <FoodTruckEditModal
            open={open}
            setOpen={setOpen}
            data={truckData}
            getData={getCompanyDetails}
            compID={companyID}
            type={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default MyCompanyDetails;
