import Typography from "@mui/material/Typography";
import {
  Modal,
  Box,
  useTheme,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import { toast } from "react-toastify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  width: "calc(100% - 30px)",
};

export default function UnSubscribeNewsLetterModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError("");
    setEmail("");
  };
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [userType, setUserType] = useState("");
  //   const [radioError, setRadioError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleRemoveSubscription = async () => {
    const emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";
    if (!email)
    {
      setError(t("footer.newsletter.errorMessage"));
      return;
    }
    // else if (!userType) {
    //   setRadioError(t("Login.required"));
    //   return;
    // }
    else if (!new RegExp(emailRegex).test(email))
    {
      setError(t("footer.newsletter.invalidEmailMessage"));
      return;
    }

    setError("");
    // setRadioError("");
    setLoading(true);

    try
    {
      let res = await API_POST(API_endpoints.RequestUnSubscribeNewsLetter, {
        email: email,
        type: userType,
      });

      if (res?.s)
      {
        toast.success(res?.m);
        setEmail("");
        setUserType("");
        handleClose();
      } else
      {
        setError(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    } finally
    {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Typography
        className="link"
        onClick={handleOpen}
        sx={{
          color: theme.palette.text.main,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            color: theme.palette.text.active,
            textDecoration: "none",
          },
        }}
      >
        {t("footer.newsletter.switchToUnsubscribe")} <ArrowRightAltIcon />
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: theme.palette.text.error,
            }}
          >
            {t("footer.newsletter.unsubscribeTitle")}
          </Typography>
          {/* <RadioGroup
            row
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value);
              setRadioError("");
            }}
            sx={{
              mt: 1,
            }}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  style={{
                    color:
                      userType === "1"
                        ? theme.palette.text.active
                        : theme.palette.text.main,
                  }}
                />
              }
              label={t("footer.newsletter.customer")}
              sx={{
                color:
                  userType === "1"
                    ? theme.palette.text.active
                    : theme.palette.text.main,
              }}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  style={{
                    color:
                      userType === "2"
                        ? theme.palette.text.active
                        : theme.palette.text.main,
                  }}
                />
              }
              label={t("footer.newsletter.company")}
              sx={{
                color:
                  userType === "2"
                    ? theme.palette.text.active
                    : theme.palette.text.main,
              }}
            />
          </RadioGroup> */}
          {/* {radioError && (
            <FormHelperText error>{radioError}</FormHelperText> // Display radio error below radio group
          )} */}
          <Box sx={{ display: "flex", maxWidth: 500, mt: 1 }}>
            <TextField
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={email}
              error={!!error}
              variant="outlined"
              placeholder={t("footer.newsletter.placeholder")}
              helperText={error}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: theme.palette.text.main,
                  bgcolor: theme.palette.background.main,
                  borderRadius: "50px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      className="fontSnugle"
                      variant="contained"
                      onClick={handleRemoveSubscription}
                      disabled={loading}
                      sx={{
                        bgcolor: theme.palette.background.btn,
                        color: "#fff",
                        borderRadius: "20px",
                        px: 3,
                        textTransform: "none",
                        "&:hover": { bgcolor: theme.palette.text.error },
                      }}
                    >
                      {t("footer.newsletter.unsubscribeButton")}
                    </Button>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "25px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                  },
                },
              }}
              fullWidth
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
