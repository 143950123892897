import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { API_GET } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import i18next from "i18next";

const TermsAndConditionsScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try
    {
      setLoading(true);
      const res = await API_GET(API_endpoints.GetTerms);
      if (res?.s)
      {
        setData(res?.r);
        setLoading(false);
      }
    } catch (error)
    {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [i18next.language]);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  return (
    <Box sx={{ background: theme.palette.background.gd }}>
      <Box
        sx={{
          width: "90%",
          maxWidth: "1440px",
          m: "auto",
          pb: 5,
        }}
      >
        <Box className="flexCenter" sx={{ height: "150px" }}>
          <Typography
            sx={{ fontSize: "32px", textAlign: "center" }}
            className="fontSnugle"
          >
            {t("TC.title")}
          </Typography>
        </Box>
        <Box
          sx={{
            p: { xs: 2, md: 5 },
            borderRadius: "25px",
            background: "#fff",
            minHeight: "300px",
            width: "90%",
            m: "auto",
          }}
        >
          {loading ? (
            <Box className='flexCenter' sx={{ height: "100%", minHeight: "300px", }}>
              <CircularProgress style={{ color: "#f57c00" }} />
            </Box>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  i18next.language === "de" ? data?.german : data?.content,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsScreen;
