import {
  Box,
  TextField,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  Autocomplete,
  Paper,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { API_GET, API_GET_OPEN } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Component/Loader/Loader";
import i18next from "i18next";
import { StarSVG } from "../../../Component/SVG";

const SearchSection = ({ values, getData, getCatererData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const mealRef = useRef([]);
  const dietRef = useRef([]);
  const catRef = useRef([]);
  const zipRef = useRef("");

  const formik = useFormik({
    initialValues: {
      zipCode: "",
      meal: [],
      diet: [],
      categories: [],
    },

    validationSchema: Yup.object({
      meal: Yup.array()
        .min(0, `${t("search.validate.food")}`)
        .nullable(),

      zipCode: Yup.number()
        .typeError(`${t("search.validate.code")}`)
        .nullable(),

      diet: Yup.array()
        .min(0, `${t("search.validate.veg")}`)
        .nullable(),
    }).test("at-least-one", `${t("search.atleastone")}`, function (value) {
      const { meal, zipCode, diet } = value;

      const isMealFilled = meal && meal?.length > 0;
      const isDietFilled = diet && diet?.length > 0;
      const isZipCodeFilled =
        zipCode !== null && zipCode !== undefined && zipCode !== "";

      return isMealFilled || isDietFilled || isZipCodeFilled;
    }),

    onSubmit: async (values) => {
      setLoading(true);

      try {
        mealRef.current = values.meal;
        dietRef.current = values.diet;
        catRef.current = values.categories;
        zipRef.current = values.zipCode;

        if (location.pathname === NavRoutes.SearchScreen) {
          getData(
            0,
            values.meal,
            mealRef.current,
            values.diet,
            dietRef.current,
            values.categories,
            catRef.current,
            values.zipCode,
            zipRef.current
          );
          getCatererData(
            0,
            values.meal,
            mealRef.current,
            values.diet,
            dietRef.current,
            values.categories,
            catRef.current,
            values.zipCode,
            zipRef.current
          );
          navigate(NavRoutes.SearchScreen, {
            state: {
              formValues: values,
            },
          });
        } else {
          if (location.pathname === NavRoutes.TRUCKS) {
            getData(
              0,
              values.meal,
              mealRef.current,
              values.diet,
              dietRef.current,
              values.categories,
              catRef.current,
              values.zipCode,
              zipRef.current
            );
            navigate(NavRoutes.TRUCKS, {
              state: {
                formValues: values,
              },
            });
          } else {
            if (location.pathname === NavRoutes.CATERING_LIST) {
              getData(
                0,
                values.meal,
                mealRef.current,
                values.diet,
                dietRef.current,
                values.categories,
                catRef.current,
                values.zipCode,
                zipRef.current
              );
              navigate(NavRoutes.CATERING_LIST, {
                state: {
                  formValues: values,
                },
              });
            } else {
              navigate(NavRoutes.SearchScreen, {
                state: {
                  formValues: values,
                },
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching trucks:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleMeal = async () => {
    try {
      let res = await API_GET(API_endpoints.getmealcat);
      res?.s && setMeal(res?.r);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiet = async () => {
    try {
      let res = await API_GET(API_endpoints.getdietcat);
      res?.s && setDiet(res?.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleMeal();
    handleDiet();
  }, [i18next?.language]);

  useEffect(() => {
    if (values) {
      formik.setValues(values);
    }
  }, [values]);
  return (
    <Box
      sx={{
        width: "100%",
        background:
          location.pathname === NavRoutes.HOME
            ? theme.palette.background.section
            : "transparent",
        py: 3,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          width: "90%",
          maxWidth: "1400px",
          marginX: "auto",
          boxShadow: "0px 0px 20px 0px #17120D14",
          borderRadius: "14px",
          padding: 3,
          my: 3,
          bgcolor: theme.palette.background.section,
        }}
      >
        {/* Left section */}
        <Grid className="removeDefault" item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={formik.touched.meal && Boolean(formik.errors.meal)}
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Autocomplete
                  multiple
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                  id="meals"
                  name="meal"
                  options={meal || []}
                  getOptionLabel={(option) =>
                    i18next.language === "de" ? option.german : option.meal_name
                  }
                  value={
                    meal.filter((m) => formik.values.meal?.includes(m.id)) || []
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "meal",
                      newValue.map((m) => m.id)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("search.input3")}
                      error={formik.touched.meal && Boolean(formik.errors.meal)}
                      helperText={formik.touched.meal && formik.errors.meal}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: theme.palette.text.defaultDark,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.strip,
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.background.strip,
                        },
                        "& .MuiFormHelperText-root": {
                          color: theme.palette.text.error,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "12px",
                            borderColor: `1px solid ${theme.palette.background.strip}`,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={
                          i18next?.language === "de"
                            ? option?.german
                            : option?.meal_name
                        }
                        {...getTagProps({ index })}
                        sx={{
                          color: theme.palette.text.defaultDark,
                          "& .MuiChip-deleteIcon": {
                            color: "black",
                          },
                        }}
                      />
                    ))
                  }
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.strip,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      bgcolor: theme.palette.background.main,
                      color: theme.palette.text.defaultDark,
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          bgcolor: theme.palette.background.inputBgHover,
                          color: theme.palette.text.active,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={formik.touched.diet && Boolean(formik.errors.diet)}
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Autocomplete
                  multiple
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                  id="diets"
                  name="diet"
                  options={diet || []}
                  getOptionLabel={(option) =>
                    i18next.language === "de" ? option.german : option.diet_name
                  }
                  value={
                    diet.filter((m) => formik.values.diet?.includes(m.id)) || []
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "diet",
                      newValue.map((m) => m.id)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("search.input4")}
                      error={formik.touched.diet && Boolean(formik.errors.diet)}
                      helperText={formik.touched.diet && formik.errors.diet}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: theme.palette.text.defaultDark,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.strip,
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.background.strip,
                        },
                        "& .MuiFormHelperText-root": {
                          color: theme.palette.text.error,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "12px",
                            borderColor: `1px solid ${theme.palette.background.strip}`,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={
                          i18next?.language === "de"
                            ? option?.german
                            : option?.diet_name
                        }
                        {...getTagProps({ index })}
                        sx={{
                          color: theme.palette.text.defaultDark,
                          "& .MuiChip-deleteIcon": {
                            color: "black",
                          },
                        }}
                      />
                    ))
                  }
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.strip,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      bgcolor: theme.palette.background.main,
                      color: theme.palette.text.defaultDark,
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          bgcolor: theme.palette.background.inputBgHover,
                          color: theme.palette.text.active,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            {/* Zip code input */}
            <Grid item xs={12} md={6}>
              <TextField
                placeholder={t("search.input2")}
                value={formik.values.zipCode}
                type="number"
                name="zipCode"
                id="zipCode"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                InputProps={{
                  sx: {
                    borderRadius: "12px",
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Submit button section */}
        <Grid
          item
          xs={12}
          md={3}
          className="removeDefault"
          container
          justifyContent={{ xs: "center", md: "end" }}
          alignItems={"start"}
          flexDirection={{ xs: "row-reverse", md: "row" }}
          gap={0.5}
        >
          <ButtonComponent
            label={loading ? <Loader /> : t("search.btn")}
            sx={{
              background:
                location.pathname === NavRoutes.HOME
                  ? theme.palette.background.btn
                  : theme.palette.background.brown,
              color: theme.palette.text.primary,
              width: "200px",
              mt: { xs: 1, md: 0 },
            }}
            onClick={formik.handleSubmit}
          />

          {(formik.values.zipCode ||
            formik.values.meal?.length > 0 ||
            formik.values.diet?.length > 0 ||
            formik.values.categories?.length > 0) && (
            <ButtonComponent
              label={t("search.clear")}
              sx={{
                background: theme.palette.background.card,
                color: theme.palette.text.brown,
                border: `1px solid ${theme.palette.text.brown}`,
                width: "200px",
                mt: { xs: 1, md: 0 },
              }}
              onClick={() => {
                navigate(location.pathname, { replace: true, state: {} });
                formik.resetForm();

                location.pathname === NavRoutes.TRUCKS ||
                location.pathname === NavRoutes.CATERING_LIST
                  ? getData(0)
                  : location.pathname === NavRoutes.SearchScreen &&
                    getData(0) &&
                    getCatererData(0);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchSection;
