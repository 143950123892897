import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_GET, API_GET_OPEN, API_GET_USERID } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { API_POST } from "../../../../API/config";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { toast } from "react-toastify";
import * as Yup from "yup";

const OwnerMealsAndDietTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [meal, setMeal] = useState([]);
  const [diet, setDiet] = useState([]);
  const [loading, setloading] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [ComapnyID, setComapnyID] = useState("");
  const userData = JSON.parse(localStorage.getItem("Fiesta"));

  const formik = useFormik({
    initialValues: {
      selectedMeals: [],
      selectedDiets: [],
      id: "",
      user_id: "",
    },
    validationSchema: Yup.object().shape({
      selectedMeals: Yup.array().min(1, t("Login.required")),
      selectedDiets: Yup.array().min(1, t("Login.required")),
    }),

    onSubmit: async (values) => {
      try
      {
        setloading(true);
        // add meal
        let f = new FormData();
        f.append("meal_ids", values.selectedMeals);
        f.append("company_id", ComapnyID);
        f.append("user_id", values.user_id);
        const res = await API_POST(API_endpoints.addMealData, f);

        // add diet
        let d = new FormData();
        d.append("diet_ids", values.selectedDiets);
        d.append("company_id", ComapnyID);
        d.append("user_id", values.user_id);
        const resDiet = await API_POST(API_endpoints.addDietData, d);
        if (res.s && resDiet.s)
        {
          toast.success(res?.m);
        }
        setloading(false);
      } catch (error)
      {
        setloading(false);
        console.error(error);
      }
    },
  });

  const getCompanyId = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res?.s)
      {
        const id = res?.r?.company_details?.id;
        getData(id);
        setComapnyID(id);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  const getData = async (id) => {
    try
    {
      setIsLoading(true);
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );
      if (res?.s)
      {
        formik.setValues({
          selectedDiets: res?.r?.diet.map((x) => x?.status === 1 && x?.diet_id),
          selectedMeals: res?.r?.meal.map((x) => x?.status === 1 && x?.meal_id),
          user_id: res?.r?.user_id,
          id: res?.r?.id,
        });
        setIsLoading(false);
      }
    } catch (error)
    {
      setIsLoading(false);
      console.log(error);
    }
  };

  const renderCheckbox = (labelKey, value, type) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            id={labelKey}
            checked={formik?.values[type]?.includes(value)}
            onChange={handleCheckboxChange(type)(value)}
            sx={{
              color: theme.palette.text.secondary,
              "&.Mui-checked": {
                color: theme.palette.text.active,
              },
            }}
          />
        }
        label={labelKey}
      />
    );
  };
  const handleCheckboxChange = (type) => (value) => (event) => {
    const { checked } = event.target;
    const updatedArray = checked
      ? [...formik.values[type], value]
      : formik.values[type].filter((item) => item !== value);

    formik.setFieldValue(type, updatedArray);
  };

  const renderMealCheckboxes = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 4 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {meal.map(({ meal_name, id }) =>
          renderCheckbox(meal_name, id, "selectedMeals")
        )}
      </Box>
    </Box>
  );

  const renderDietCheckboxes = () => (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {diet.map(({ diet_name, id }) =>
        renderCheckbox(diet_name, id, "selectedDiets")
      )}
    </Box>
  );

  const handlemeal = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getmealcat);
      setMeal(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handlediet = async () => {
    try
    {
      let res = await API_GET(API_endpoints.getdietcat);
      setDiet(res.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handlemeal();
    handlediet();
  }, []);
  return (
    <>
      {IsLoading ? (
        <Box sx={{ minHeight: "300px" }} className="flexCenter">
          <CircularProgress style={{ color: "#f57c00" }} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
            }}
          >
            {/* Meals Section */}
            <Box sx={{ width: "100%" }}>
              <Box pb={1}>{t("ownerProfile.meal.categoryclass")}</Box>
              <FormGroup row>{renderMealCheckboxes()}</FormGroup>
              {formik.touched.selectedMeals && formik.errors.selectedMeals && (
                <FormHelperText error>
                  {formik.errors.selectedMeals}
                </FormHelperText>
              )}
            </Box>

            {/* Special Diets Section */}
            <Box sx={{ width: "100%" }}>
              <Box pb={1}>{t("ownerProfile.meal.updatediet")}</Box>
              <FormGroup row>{renderDietCheckboxes()}</FormGroup>
              {formik.touched.selectedDiets && formik.errors.selectedDiets && (
                <FormHelperText error>
                  {formik.errors.selectedDiets}
                </FormHelperText>
              )}
            </Box>
          </Box>
          <Box className="flexCenter" sx={{ mt: 5 }}>
            <ButtonComponent
              disabled={loading}
              type="button"
              label={
                loading ? <Loader /> : `${t("ownerProfile.profile.update")}`
              }
              onClick={formik.handleSubmit}
              sx={{
                width: { xs: "80%", sm: "130px" },
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default OwnerMealsAndDietTab;
