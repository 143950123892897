import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { API_GET_OPEN, API_GET_USERID, API_POST } from "../../../../API/config";
import { API_endpoints } from "../../../../API/API_Endpoints";
import ButtonComponent from "../../../../Component/Button/Button";
import Loader from "../../../../Component/Loader/Loader";
import { toast } from "react-toastify";

const OwnerCatererTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const [Isloading, setIsLoading] = useState(true);
  const [cateTYPE, setCateTYPE] = useState(null);
  const userData = JSON.parse(localStorage.getItem("Fiesta"));

  const catType = [
    "Bussiness Caterer",
    "Food Trucks",
    "Barista / Bartender",
    "Other",
  ];

  const initial1 = {
    type: null,
    about: "",
    id: 0,
    user_id: 0,
  };

  const validationSchema1 = Yup.object({
    about: Yup.string().required(t("Login.required")),
  });

  const formik = useFormik({
    initialValues: initial1,
    validationSchema: validationSchema1,
    onSubmit: async (values) => {
      setloading(true);
      try
      {
        const res = await API_POST(API_endpoints.updatecaterer, values);
        if (res.s)
        {
          toast.success(res.m);
        } else
        {
          toast.error(res.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      } finally
      {
        setloading(false);
      }
    },
  });

  const getCompanyId = async () => {
    try
    {
      setIsLoading(true);
      let res = await API_GET_USERID(API_endpoints.usergetbyid, userData?.id);
      if (res.s)
      {
        const id = res?.r?.company_details?.id;
        getData(id);
      }
      setIsLoading(false);
    } catch (error)
    {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  const getData = async (id) => {
    try
    {
      setIsLoading(true);
      let res = await API_GET_OPEN(
        `${API_endpoints.getcompanybyid}?company_id=${id}`
      );

      console.log(res?.r, 'caterere')
      formik.setValues({
        type: res?.r?.caterer?.caterer_type,
        about: res?.r?.caterer?.about,
        user_id: res?.r?.user_id,
        id: res?.r?.caterer?.id,
      });
      setCateTYPE(res?.r?.caterer?.caterer_type - 1);
      setIsLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsLoading(false);
    }
  };

  return Isloading ? (
    <Box sx={{ minHeight: "300px" }} className="flexCenter">
      <CircularProgress style={{ color: "#f57c00" }} />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box pb={1}>{t("ownerProfile.caterer.typeofcaterer")}</Box>
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#DEE6F252",
                width: "100%",
                height: "Hug(55px)px",
                padding: "14px",
                gap: "10px",
                borderRadius: "12px",
                // color: "#164FA1",
                fontWeight: "600",
              }}
            >
              <Typography>{catType[cateTYPE]}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box pb={1}> {t("ownerProfile.caterer.moreaboutcompany")}</Box>
          <TextField
            type="text"
            id="about"
            name="about"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.about}
            error={formik.touched.about && Boolean(formik.errors.about)}
            helperText={formik.touched.about && formik.errors.about}
            variant="outlined"
            placeholder={t("becomePartner.step2.yourSelf.pl")}
            multiline
            fullWidth
            rows={5}
            InputProps={{
              sx: {
                borderRadius: "12px",
                color: theme.palette.text.defaultDark,
                "&:hover": {
                  color: theme.palette.text.active,
                  bgcolor: theme.palette.background.inputBg,
                  borderRadius: "12px",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box className="flexCenter" sx={{ mt: 5 }}>
        <ButtonComponent
          disabled={loading}
          type="button"
          label={loading ? <Loader /> : `${t("ownerProfile.profile.update")}`}
          onClick={formik.handleSubmit}
          sx={{
            width: { xs: "80%", sm: "130px" },
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>
    </>
  );
};

export default OwnerCatererTab;
