import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { API_GET } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import {
  Box,
  Typography,
  Divider,
  Stack,
  Link,
  useTheme,
  CircularProgress,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";

const ImprintScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try
    {
      setLoading(true);
      const res = await API_GET(API_endpoints.getFooterContent);
      res?.s && setData(res?.r);
    } catch (error)
    {
      console.log(error);
    } finally
    {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  return (
    <Box sx={{ background: theme.palette.background.gd }}>
      <Box
        sx={{
          width: "90%",
          maxWidth: "1440px",
          m: "auto",
          pb: 5,
        }}
      >
        <Box className="flexCenter" sx={{ height: "150px" }}>
          <Typography
            sx={{ fontSize: "32px", textAlign: "center" }}
            className="fontSnugle"
          >
            {t("footer.Imprint")}
          </Typography>
        </Box>
        <Box
          sx={{
            px: { xs: 2, md: 5 },
            borderRadius: "25px",
            width: "90%",
            m: "auto",
            minHeight: "300px",
          }}
        >
          {loading ? (
            <Box
              className="flexCenter"
              sx={{ height: "100%", minHeight: "300px" }}
            >
              <CircularProgress style={{ color: "#f57c00" }} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 3,
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                boxShadow: 1,
                maxWidth: 500,
                backgroundColor: "#fff",
                m: "auto",
              }}
            >
              <Typography
                variant="h4"
                className="fontSnugle"
                sx={{ fontWeight: "500", mb: 2, textAlign: "center" }}
              >
                {t("imprint.title")}
              </Typography>

              <Stack spacing={2}>
                  <Typography variant="subtitle1" fontWeight="600">
                    {t("imprint.add")}
                  </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <BusinessIcon style={{ color: "#f57c00" }} fontSize="small" />
                  <Typography variant="body1" sx={{ fontWeight: "500" }}>
                    PROCiTY, Marketing & Events
                    <br />
                    Inhaber:Tomas Sanchez
                    <br />
                    Ernst-Rheinwald-Str. 8<br />
                    75365 Calw
                  </Typography>
                </Stack>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.vatId")}
                </Typography>
                <Typography variant="body2">DE281689630</Typography>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.representedBy")}
                </Typography>
                <Typography variant="body2">Tomas Sanchez</Typography>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.contact")}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <EmailIcon style={{ color: "#f57c00" }} fontSize="small" />
                  <Link
                    href="mailto:fiestabooking@online.de"
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.text.black,
                    }}
                  >
                    fiestabooking@online.de
                  </Link>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <PhoneIcon style={{ color: "#f57c00" }} fontSize="small" />
                  <Link
                    href="tel:+491718382171"
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.text.black,
                    }}
                  >
                    +49 1718382171
                  </Link>
                </Stack>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.professionalLiabilityInsurance")}
                </Typography>
                <Typography variant="body2">
                  Württembergische Versicherung AG
                  <br />
                  Robert-Bosch-Str. 21
                  <br />
                  75365 Calw
                  <br />
                  Geltungsbereich: Deutschland
                </Typography>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.euDisputeResolution")}
                </Typography>
                <Typography variant="body2">
                  Die Europäische Kommission stellt eine Plattform zur
                  Online-Streitbeilegung (OS) bereit:
                </Typography>
                <Link
                  href="https://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.text.black,
                  }}
                >
                  https://ec.europa.eu/consumers/odr/
                </Link>
                <Typography variant="body2">
                  Unsere E-Mail-Adresse finden Sie oben im Impressum.
                </Typography>

                <Divider />

                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.consumerDisputeResolution")}
                </Typography>
                <Typography variant="body2">
                  Wir sind nicht bereit oder verpflichtet, an
                  Streitbeteiligungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </Typography>

                <Divider />
                <Typography variant="subtitle1" fontWeight="600">
                  {t("imprint.disclaimer")}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 0.2,
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="600">
                    {t("imprint.imageRights")}:
                  </Typography>{" "}
                  <Link
                    href="https://stock.adobe.com/de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.text.black,
                    }}
                  >
                    https://stock.adobe.com/de/
                  </Link>
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ImprintScreen;
