import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import bgImg from "../../../Assets/images/home/1HomePage.webp";
import { useTranslation } from "react-i18next";
import HomeInquiryFormModal from "./HomeInquiryFormModal";
import ButtonComponent from "../../../Component/Button/Button";

const HomeSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <Box
      sx={{
        height: "calc(100vh)",
        width: "100%",
        backgroundImage: `url(${bgImg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          background: "#1A130C61",
          // background: "#1A130CA3",

          height: "100%",
          width: "100%",
        }}
        className="flexCenter"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", sm: "90%", md: "90%", lg: "40%" },
            p: 3,
            // gap: 1,
            maxWidth: "1400px",
          }}
        >
          <Box
            sx={{
              gap: { xs: "5px", md: 1, lg: 2 },
              width: {
                xs: "100%",
                sm: "70%",
                md: "50%",
                lg: "100%",
                xl: "85%",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
              // border: "solid red"
              // px: 5,
            }}
          >
            <span
              style={{
                background: theme.palette.background.orange,
                width: "80%",
                height: "1px",
              }}
            ></span>

            <Typography
              variant="h6"
              className="fontSnugle"
              sx={{
                color: theme.palette.text.active,
                fontSize: { xs: "16px", sm: "22px", md: "20px", lg: "18px" },
                width: { md: "700px" },
                textAlign: "center",
              }}
            >
              {t("section1.SubHeadline")}
            </Typography>
            <span
              style={{
                background: theme.palette.background.orange,
                width: "80%",
                height: "1px",
              }}
            ></span>
          </Box>

          <Typography
            variant="h1"
            className="fontSnugle"
            sx={{
              color: theme.palette.text.primary,
              fontSize: { xs: "36px", sm: "55px", md: "69px", xl: "95px" },
              textAlign: "center",
            }}
          >
            {t("section1.Headline")}
          </Typography>
          <Typography
            className="fontSnugle"
            sx={{
              color: theme.palette.text.primary,
              fontSize: { xs: "16px", sm: "20px", md: "30px", xl: "34px" },
              textAlign: "center",
            }}
          >
            {t("section1.event")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "100%", sm: "60%", md: "50%", lg: "100%" },
            }}
          >
            <Typography
              className="fontSnugle"
              sx={{
                color: theme.palette.text.greyText,
                fontSize: { xs: "16px", sm: "16px", md: "18px", xl: "22px" },
                textAlign: "center",
              }}
            >
              {t("section1.note")}
            </Typography>
          </Box>

          <Box sx={{ pt: "0.5rem" }}>
            <ButtonComponent
              label={`${t("section1.button")}`}
              onClick={() => handleOpen()}
              sx={{
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
            <HomeInquiryFormModal setOpen={setOpen} open={open} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSection;
