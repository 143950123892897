import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material";
import { theme } from "../Theme/Theme";
import Login from "../Pages/Auth/Login/Login";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import HomePage from "../Pages/HomePage/HomePage";
import Register from "../Pages/Auth/Register/Register";
import BecomeAPartnerScreen from "../Pages/BecomeAPartner/BecomeAPartnerScreen";
import { NavRoutes } from "../Component/Navbar/NavRoutes";
import User from "../Pages/User/User";
import AboutUsScreen from "../Pages/AboutUs/AboutUsScreen";
import FoodTruckScreen from "../Pages/FoodTruck/FoodTruckScreen";
import FoodTruckDetailScreen from "../Pages/FoodTruckDetails/FoodTruckDetailScreen";
import ChatScreen from "../Pages/Chat/ChatScreen";
import ReachUsScreen from "../Pages/ReachUs/ReachUsScreen";
import PrivateRoute from "./PrivateRouter";
import NotFoundPageScreen from "../Pages/NotFoundPage/NotFoundPageScreen";
import Faq from "../Pages/FAQ/FAQScreen";
import TruckOwnerProfileScreen from "../Pages/TruckOwnerProfile/TruckOwnerProfileScreen";
import PrivacyPolicyScreen from "../Pages/PrivacyPolicy/PrivacyPolicyScreen";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import { CompanyIdProvider } from "../Context/CompanyIdContext";
import CateringListScreen from "../Pages/CateringListScreen/CateringListScreen";
import AddTruckScreen from "../Pages/MyFoodTruck/AddTruckScreen";
import MyCompanyDetails from "../Pages/MyBusiness/MyCompanyDetails";
import ImprintScreen from "../Pages/Imprint/ImprintScreen";
import RenewSubscriptionScreen from "../Pages/TruckOwnerProfile/MyPayment/RenewSubscriptionScreen";
import SearchResultScreen from "../Pages/SearchResult/SearchResult";

const AllRoutes = () => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") ?? "light"
  );

  const {
    palette: { primary, mode, secondary },
  } = useTheme();

  const routes = [
    { path: NavRoutes.PROFILE, component: <User />, isPrivate: true },
    {
      path: NavRoutes.TRUCKS,
      component: <FoodTruckScreen />,
      isPrivate: false,
    },
    { path: NavRoutes.ABOUT, component: <AboutUsScreen />, isPrivate: false },
    {
      path: NavRoutes.PRIVACY,
      component: <PrivacyPolicyScreen />,
      isPrivate: false,
    },
    {
      path: NavRoutes.Imprint,
      component: <ImprintScreen />,
      isPrivate: false,
    },
    {
      path: NavRoutes.TERMS,
      component: <TermsAndConditions />,
      isPrivate: false,
    },
    {
      path: NavRoutes.OWNER_PROFILE,
      component: <TruckOwnerProfileScreen />,
      isPrivate: true,
    },
    {
      path: NavRoutes.SearchScreen,
      component: <SearchResultScreen />,
      isPrivate: false,
    },
    {
      path: `${NavRoutes.TRUCK_DETAILS}/:id`,
      component: <FoodTruckDetailScreen />,
      isPrivate: false,
    },
    { path: NavRoutes.CHAT, component: <ChatScreen />, isPrivate: true },
    // {
    //   path: NavRoutes.MY_FOOD_TRUCK,
    //   component: <MyFoodTruckScreen />,
    //   isPrivate: true,
    // },
    {
      path: NavRoutes.MY_COMPANY,
      component: <MyCompanyDetails />,
      isPrivate: true,
    },
    {
      path: NavRoutes.Renew,
      component: <RenewSubscriptionScreen />,
      isPrivate: true,
    },
    {
      path: NavRoutes.CATERING_LIST,
      component: <CateringListScreen />,
      isPrivate: false,
    },
    {
      path: NavRoutes.ADD_TRUCK,
      component: <AddTruckScreen />,
      isPrivate: true,
    },
    { path: NavRoutes.REACH, component: <ReachUsScreen />, isPrivate: false },
    { path: NavRoutes.LOGIN, component: <Login />, isPrivate: false },
    { path: NavRoutes.REGISTER, component: <Register />, isPrivate: false },
    { path: NavRoutes.HOME, component: <HomePage />, isPrivate: false },
    { path: NavRoutes.FAQ, component: <Faq />, isPrivate: false },
    {
      path: NavRoutes.PARTNER,
      component: <BecomeAPartnerScreen />,
      isPrivate: true,
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme(themeMode)}>
        <CompanyIdProvider>
          <Navbar themeMode={themeMode} changeTheme={(e) => setThemeMode(e)} />
          <Routes>
            {routes.map(({ path, component, isPrivate }) => (
              <Route
                key={path}
                path={path}
                element={
                  isPrivate ? (
                    <PrivateRoute>{component}</PrivateRoute>
                  ) : (
                    component
                  )
                }
              />
            ))}
            <Route path="*" element={<NotFoundPageScreen />} />
          </Routes>
          <Footer mode={mode} secondary={secondary} primary={primary} />
        </CompanyIdProvider>
      </ThemeProvider>
    </>
  );
};

export default AllRoutes;
