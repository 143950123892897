import React from 'react'
import { Box } from "@mui/material";
import notFound from '../../Assets/images/404.svg'

const NotFoundPageScreen = () => {
    return (
        <Box sx={{ background: 'linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)', px: 5, height: 'calc(100vh - 350px)', width: '100%', }} className='flexCenter'>

            <img src={notFound} style={{ maxWidth: "500px" }} height={'100%'} width={'100%'} alt="" />
        </Box>
    )
}

export default NotFoundPageScreen