import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { API_endpoints } from "../../../API/API_Endpoints";
import { API_POST } from "../../../API/config";
import UnSubscribeNewsLetterModal from "../../../Component/Footer/UnsubscribeModal";

const NewsletterBox = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [radioError, setRadioError] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleSubscriptionToggle = async () => {
    const emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";
    if (!email)
    {
      setError(t("footer.newsletter.errorMessage"));
      return;
    } else if (!userType)
    {
      setRadioError(t("Login.required"));
      return;
    } else if (!new RegExp(emailRegex).test(email))
    {
      setError(t("footer.newsletter.invalidEmailMessage"));
      return;
    }

    setError("");
    setRadioError("");
    setLoading(true);

    try
    {
      let res = await API_POST(API_endpoints.RequestSubscribeNewsLetter, {
        email: email,
        type: userType,
      });

      if (res?.s)
      {
        toast.success(res?.m);
        setEmail("");
        setUserType("");
      } else
      {
        setError(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    } finally
    {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.card,
        padding: { xs: 4, sm: 5 },
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",
          borderRadius: 2,
          gap: 0.5,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            color: theme.palette.text.list,
            fontSize: { xs: "28px", sm: "30px", md: "32px", xl: "35px" },
          }}
        >
          {t("footer.newsletter.title")}
        </Typography>
        <Typography
          variant="body1"
          textAlign={"start"}
          sx={{
            color: `${theme.palette.text.list}70`,
            fontSize: "18px",
          }}
        >
          {t("footer.newsletter.subtitle")}
        </Typography>
        <RadioGroup
          row
          value={userType}
          onChange={(e) => {
            setUserType(e.target.value);

            setRadioError("");
          }}
          sx={{
            mt: 1,
            fontSize: "10px",
          }}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                style={{
                  color:
                    userType === "1"
                      ? theme.palette.text.active
                      : theme.palette.text.main,
                }}
              />
            }
            label={t("footer.newsletter.customer")}
            sx={{
              color:
                userType === "1"
                  ? theme.palette.text.active
                  : theme.palette.text.main,
            }}
          />
          <FormControlLabel
            value="2"
            control={
              <Radio
                style={{
                  color:
                    userType === "2"
                      ? theme.palette.text.active
                      : theme.palette.text.main,
                }}
              />
            }
            label={t("footer.newsletter.company")}
            sx={{
              color:
                userType === "2"
                  ? theme.palette.text.active
                  : theme.palette.text.main,
            }}
          />
        </RadioGroup>
        {radioError && (
          <FormHelperText error>{radioError}</FormHelperText> // Display radio error below radio group
        )}
        <Box sx={{ display: "flex", maxWidth: 500, mt: 1 }}>
          <TextField
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            value={email}
            error={!!error}
            variant="outlined"
            placeholder={t("footer.newsletter.placeholder")}
            helperText={error}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: theme.palette.text.main,
                bgcolor: theme.palette.background.main,
                borderRadius: "50px",
                width: { md: "500px" },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className="fontSnugle"
                    variant="contained"
                    onClick={handleSubscriptionToggle}
                    disabled={loading}
                    sx={{
                      bgcolor: theme.palette.background.btn,
                      color: "#fff",
                      borderRadius: "20px",
                      px: 3,
                      textTransform: "none",
                      "&:hover": { bgcolor: "#155ab6" },
                    }}
                  >
                    {t("footer.newsletter.subscribeButton")}
                  </Button>
                </InputAdornment>
              ),
              sx: {
                borderRadius: "25px",
                color: theme.palette.text.defaultDark,
                "&:hover": {
                  color: theme.palette.text.active,
                  bgcolor: theme.palette.background.inputBg,
                },
              },
            }}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            alignItems: "center",
            maxWidth: 500,
          }}
        >
          {/* <Typography
            className="link"
            // onClick={() => {
            //   // setIsSubscribed(!isSubscribed);
            //   setError("Please enter to email Unsubscribe Newsletter");
            // }}
            sx={{
              color: theme.palette.text.main,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",

              "&:hover": {
                color: theme.palette.text.active,
                textDecoration: "none",
              },
            }}
          >
            {t("footer.newsletter.switchToUnsubscribe")}
            <ArrowRightAltIcon />
          </Typography> */}
          <UnSubscribeNewsLetterModal />
        </Box>
      </Box>
    </Box>
  );
};

export default NewsletterBox;
