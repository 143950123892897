import { Box } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import loader from "../../Assets/images/Fast Food Truck.json";

function Noresult() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: loader,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <Lottie options={defaultOptions} height={400} width={400} />
    </Box>
  );
}

export default Noresult;