import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import { API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";

const Step1 = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let id = parseData?.id;
  const formik = useFormik({
    initialValues: {
      company_name: "",
      city: "",
      postal_code: "",
      website_link: "",
      user_id: id,
      id: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required(
        t("becomePartner.step1.vali.Required")
      ),
      city: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      postal_code: Yup.string()
        .trim()
        .required(t("becomePartner.step1.vali.Required")),
      website_link: Yup.string()
        .trim()
        .url(t("becomePartner.step1.vali.InvalidURL"))
        .required(t("becomePartner.step1.vali.Required")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try
      {
        let res;
        if (values.id)
        {
          res = await API_POST(API_endpoints.updatecompany, values);
          const id = res.r.id;
          formik.setFieldValue("id", id);
          localStorage.setItem("Fiesta_compID", id);
        } else
        {
          res = await API_POST(API_endpoints.addcompany, values);
          if (res && res.r && res.r.id)
          {
            const id = res.r.id;
            formik.setFieldValue("id", id);
            localStorage.setItem("Fiesta_compID", id);
          }
        }
        if (res?.s)
        {
          setLoading(false);
          return true;
        } else
        {
          setLoading(false);
          return false;
        }
      } catch (error)
      {
        setLoading(false);
        console.log(error);
        return false;
      }
    },
  });

  const handleUserData = async () => {
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      setLoading(false);
      formik.setFieldValue(
        "company_name",
        res?.r?.company_details?.company_name
      );
      formik.setFieldValue("city", res?.r?.company_details?.city);
      formik.setFieldValue("id", res?.r?.company_details?.id);
      formik.setFieldValue("postal_code", res?.r?.company_details?.postal_code);
      formik.setFieldValue(
        "website_link",
        res?.r?.company_details?.website_link
      );
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);


  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  return (
    <Box component="form" id="step1-form" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          fontSize: { xs: "24px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step1.title")}
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          height: loading ? "200px" : "",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "#f57c00" }} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box pb={0.5}>
                  <StarSVG /> {t("becomePartner.step1.company_name")}
                </Box>

                <TextField
                  type="text"
                  id="company_name"
                  name="company_name"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      value.trimStart() !== value &&
                      value.trimStart() !== value
                    )
                    {
                      e.target.value = value.trimStart();
                      e.target.value = value.trimEnd();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_name}
                  error={
                    formik.touched.company_name &&
                    Boolean(formik.errors.company_name)
                  }
                  helperText={
                    formik.touched.company_name && formik.errors.company_name
                  }
                  variant="outlined"
                  placeholder={t("becomePartner.step1.pl_company_name")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box pb={0.5}>
                  <StarSVG /> {t("becomePartner.step1.city")}
                </Box>

                <TextField
                  type="text"
                  id="city"
                  name="city"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      value.trimStart() !== value &&
                      value.trimStart() !== value
                    )
                    {
                      e.target.value = value.trimStart();
                      e.target.value = value.trimEnd();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  variant="outlined"
                  placeholder={t("becomePartner.step1.pl_city")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box pb={0.5}>
                  <StarSVG /> {t("becomePartner.step1.post_Code")}
                </Box>

                <TextField
                  type="number"
                  id="postal_code"
                  name="postal_code"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;

                    // Prevent negative numbers
                    if (value && Number(value) < 0)
                    {
                      e.target.value = ""; // Clear the input if the value is negative
                      return; // Prevent further processing
                    }

                    // Trim whitespace
                    e.target.value = value.trim();

                    // Update formik state
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.postal_code}
                  error={
                    formik.touched.postal_code &&
                    Boolean(formik.errors.postal_code)
                  }
                  helperText={
                    formik.touched.postal_code && formik.errors.postal_code
                  }
                  variant="outlined"
                  placeholder={t("becomePartner.step1.pl_post_Code")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box pb={0.5}>
                  <StarSVG /> {t("becomePartner.step1.weblink")}
                </Box>

                <TextField
                  type="text"
                  id="website_link"
                  name="website_link"
                  fullWidth
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      value.trimStart() !== value &&
                      value.trimStart() !== value
                    )
                    {
                      e.target.value = value.trimStart();
                      e.target.value = value.trimEnd();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.website_link}
                  error={
                    formik.touched.website_link &&
                    Boolean(formik.errors.website_link)
                  }
                  helperText={
                    formik.touched.website_link && formik.errors.website_link
                  }
                  variant="outlined"
                  placeholder={t("becomePartner.step1.pl_weblink")}
                  InputProps={{
                    sx: {
                      borderRadius: "12px",
                      color: theme.palette.text.defaultDark,
                      "&:hover": {
                        color: theme.palette.text.active,
                        bgcolor: theme.palette.background.inputBg,
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default Step1;
