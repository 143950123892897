import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import about_left from "../../../Assets/images/home/9 Startseite U╠êber Fiesta Catering.webp";
import ButtonComponent from "../../../Component/Button/Button";
import forkspoon from "../../../Assets/images/about_Homepage/about_fork.png";
import about_bottom from "../../../Assets/images/home/10 Startseite U╠êber Fiesta Catering.webp";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
const AboutSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        flexDirection: "column",
        paddingY: { xs: 4, md: 5 },
        maxWidth: "1400px",
        m: "auto",
      }}
    >
      <Box
        sx={{
          width: "90%",
          marginX: "auto",
          position: "relative",
          p: { xs: 0.5, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            position: "absolute",
            bottom: 10,
            right: 10,
            width: "150px",
            height: "190px",
            overflow: "hidden",
            borderRadius: "8px",
          }}
        >
          <img
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
            src={about_bottom}
            alt="About Bottom"
            className="scaleImg"
          />
        </Box>
        <Grid
          container
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={5.5} lg={4.5}>
            <ScrollAnimation animateIn="animate__slideInLeft">
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "300px", sm: "400px" },
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={about_left}
                  alt="About Left"
                  className="scaleImg"
                />
              </Box>
            </ScrollAnimation>
          </Grid>

          {/* Text content */}
          <Grid item xs={12} md={6} lg={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                variant="h3"
                className="fontSnugle"
                sx={{
                  color: theme.palette.text.dark,
                  fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                  py: 1,
                }}
              >
                {t("About.title")}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.subHead,
                  fontSize: { xs: "18px", md: "20px" },
                  mt: "0.5rem",
                }}
              >
                {t("About.subtitle")}
              </Typography>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "start", md: "start" },
                }}
              >
                {["list1", "list2", "list3", "list4"].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "25px", height: "25px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={forkspoon}
                        alt="Icon"
                      />
                    </Box>
                    <Typography sx={{ textAlign: "start" }}>
                      {t(`About.${item}`)}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: { xs: "center", md: "start" },
                }}
              >
                <ButtonComponent
                  type="button"
                  label={t("About.button")}
                  onClick={() => navigate(NavRoutes.TRUCKS)}
                  sx={{
                    width: { xs: "80%", sm: "55%", md: "65%", lg: "40%" },
                    mt: 2,
                    background: theme.palette.background.btn,
                    color: theme.palette.text.primary,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutSection;
