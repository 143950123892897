    export const CateringList_EN_Trans = {
        bannerTitle:'Catering Services',
        noData:'No Record Found',
        noMoreData:'No more data'
    }

export const CateringList_DE_Trans = {
    bannerTitle: 'Catering-Dienste',
    noData: 'Keine Datensätze gefunden',
    noMoreData: 'Keine weiteren Daten'
};

export const CateringList_NL_Trans = {
    bannerTitle: 'Cateringdiensten',
    noData: 'Geen gegevens gevonden'
}

export const CateringList_ES_Trans = {
    bannerTitle: 'Servicios de Catering',
    noData: 'No se encontraron registros'
};
