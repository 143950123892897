import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { Box, useTheme, FormHelperText, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import MultiImageUpload from "../../../Component/ImageUploader/MultiImageUploader";
import { API_GET_USERID, API_POST } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import MultiCerti from "../../../Component/ImageUploader/MultiCertficate";

const Step4 = forwardRef((props, ref) => {
  const compID = localStorage.getItem("Fiesta_compID");
  const theme = useTheme();
  const { t } = useTranslation();
  // const [comp_id, setCompId] = useState(0);
  const [images, setImages] = useState([]);
  const [certificate, setcertificate] = useState([]);
  const [loading, setLoading] = useState(true);
  let parseData = JSON.parse(localStorage.getItem("Fiesta"));
  let companyId = parseData?.companyId;
  const formik = useFormik({
    initialValues: {
      images: [],
      certificates: [],
    },
    validationSchema: Yup.object({
      images: Yup.array().min(1, "required"),
      // certificates: Yup.array().min(1, "At least one certificate is required."),
    }),
    onSubmit: async (values) => {
      try
      {
        setLoading(true);
        const imagesArray = Array.isArray(values.images)
          ? values.images
          : [values.images];
        for (let i = 0; i < imagesArray.length; i++)
        {
          if (!imagesArray[i].id)
          {
            let f = new FormData();
            f.append("company_id", compID ?? companyId);
            f.append("type", 1);
            f.append("image", imagesArray[i]);
            await API_POST(API_endpoints.addIandC, f);
          }
        }

        setLoading(false);
      } catch (error)
      {
        setLoading(false);
        console.error(error);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);
  const handleImageChange = (newFiles) => {
    formik.setFieldValue("images", [...formik.values.images, ...newFiles]);
  };

  const handleCertificateChange = (newFiles) => {
    const updatedCertificates = newFiles.map((file, index) => ({
      file,
      title: formik.values.certificates[index]?.title || "",
    }));

    formik.setFieldValue("certificates", [
      ...formik.values.certificates,
      ...updatedCertificates,
    ]);
  };

  useImperativeHandle(ref, () => ({
    get Formik() {
      return formik;
    },
  }));

  const handleUserData = async () => {
    let parseData = JSON.parse(localStorage.getItem("Fiesta"));
    let id = parseData?.id;
    if (!id) return;
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res?.s)
      {
        setLoading(false);

        if (
          res?.r?.company_details?.image &&
          Array.isArray(res?.r?.company_details?.image)
        )
        {
          let imagesArray = [];
          let certificatesArray = [];

          res?.r?.company_details?.image?.forEach((e) => {
            if (e?.type === 1)
            {
              imagesArray.push(e);
            } else if (e?.type === 2)
            {
              certificatesArray.push(e);
            }
          });
          setImages(imagesArray);
          setcertificate(certificatesArray);
          formik.setFieldValue("images", imagesArray);
          formik.setFieldValue("certificates", certificatesArray);
          localStorage.setItem("Fiesta_compID", res?.r?.company_details?.id);
        }
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleCertificateData = async () => {
    let parseData = JSON.parse(localStorage.getItem("Fiesta"));
    let id = parseData?.id;
    if (!id) return;
    try
    {
      let res = await API_GET_USERID(API_endpoints.usergetbyid, id);
      if (res.s)
      {
        setLoading(false);
      }
      if (
        res?.r?.company_details?.image &&
        Array.isArray(res.r.company_details.image)
      )
      {
        let certificatesArray = [];

        res.r.company_details.image.forEach((e) => {
          if (e.type === 2)
          {
            certificatesArray.push(e);
          }
        });
        setcertificate(certificatesArray);
        formik.setFieldValue("certificates", certificatesArray);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);



  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
        }}
      >
        {t("becomePartner.step4.title")}
      </Box>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          height: loading ? "200px" : "",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress style={{ color: "#f57c00" }} />
          </Box>
        ) : (
          <Box>
            <Box>
              <Box py={1}>
                <Box>
                  <StarSVG /> {t("becomePartner.step4.image.title")}
                </Box>
              </Box>
              <MultiImageUpload
                apiPreviews={images ?? []}
                formik={formik}
                setImages={handleImageChange}
                imgTitle={false}
                max={3}
                handleUserData={handleUserData}
              />
              {formik.touched.images && formik.errors.images && (
                <FormHelperText error>{formik.errors.images}</FormHelperText>
              )}
            </Box>

            <Box>
              <Box py={1}>
                <Box>
                  <StarSVG /> {t("becomePartner.step4.certi.title")}
                </Box>
                <Box
                  sx={{
                    pl: "1.35rem",
                    color: theme.palette.text.subHead,
                    my: 0.5,
                    fontSize: "15px",
                  }}
                >
                  {t("becomePartner.step4.certi.note")}
                </Box>
              </Box>
              <MultiCerti
                setImages={handleCertificateChange}
                imgTitle={true}
                max={5}
                certificates={certificate}
                formik={formik}
                handleUserData={handleCertificateData}
                comp_id={compID}
              />
              {formik.touched.certificates && formik.errors.certificates && (
                <FormHelperText error>
                  {formik.errors.certificates}
                </FormHelperText>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default Step4;
