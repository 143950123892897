import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/images/add_profile_pic.png";
import { toast } from "react-toastify";
import pencil from "../../Assets/images/Profile/pencil.png";

export default function SingleImage({
  setImage,
  helperText,
  name,
  apiPreview,
  autoSubmit,
}) {
  const [Preview, setPreviewimg] = useState();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (file.size <= 5 * 1024 * 1024) {
          setTimeout(() => {
            autoSubmit && autoSubmit();
          }, 50);
          const img = new Image();
          img.onload = () => {
            const url = URL.createObjectURL(file);
            setPreviewimg(url);
            setImage(name || "cover", file);
            URL.revokeObjectURL(img.src);
          };
          img.src = URL.createObjectURL(file);
        } else {
          toast.error("Selected file exceeds 5 MB limit.");
        }
      }
    },
  });

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Box
        m={"auto"}
        width={"100%"}
        height={"100%"}
        maxWidth={"540px"}
        className="flexCenter"
        sx={{ p: 1 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {apiPreview ? (
            <div>
              <figure
                style={{
                  position: "relative",
                  display: "inline-block",
                  margin: 0,
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  zIndex: 1,
                }}
              >
                <img
                  src={apiPreview}
                  alt="preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    bottom: "5px",
                    zIndex: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <img
                    src={pencil}
                    alt="Edit"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </figure>
            </div>
          ) : Preview ? (
            <figure
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                position: "relative",
                display: "inline-block",
              }}
            >
              <img
                src={Preview}
                alt="preview.."
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "5px",
                  zIndex: 2,
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                }}
              >
                <img
                  src={pencil}
                  alt="Edit"
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
            </figure>
          ) : (
            <>
              <figure>
                <img
                  src={upload}
                  alt="logo..."
                  width="120px"
                  height="120px"
                  draggable="false"
                  style={{ objectFit: "contain", borderRadius: "10px" }}
                />
              </figure>
              <Box
                sx={{
                  display: { xs: "grid", lg: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  placeItems: "center",
                  ":hover": {
                    opacity: "0.72",
                  },
                }}
              ></Box>
            </>
          )}
        </div>
      </Box>
      <Typography ml={2} color="#d32f2f" fontSize="0.75rem">
        {helperText}
      </Typography>
    </div>
  );
}
