import { Box, useTheme, Typography } from "@mui/material";
import ButtonComponent from "../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import BookNowModal from "./BookNow/BookNowModal";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../Component/Navbar/NavRoutes";
import { toast } from "react-toastify";
const DetailsSideBar = ({ data, userID, myId, truck_id }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();


  async function handleSendMessage() {
    let chatID = "";
    let DirectID;

    try
    {
      const q = query(
        collection(db, "chat"),
        where("users", "array-contains-any", [userID, myId])
      );
      const querySnapshot = await getDocs(q);
      let chatIsExist = false;
      querySnapshot.forEach((doc) => {
        const chatData = doc.data();
        DirectID = doc?.id;
        if (
          chatData?.users?.includes(Number(userID)) &&
          chatData?.users?.includes(Number(myId))
        )
        {
          chatIsExist = true;
          chatID = doc?.id;
          return;
        }
      });
      if (!chatIsExist)
      {
        const docRef = await addDoc(collection(db, "chat"), {
          sentAt: serverTimestamp(),
          last_msg: "",
          users: [userID, myId],
          unReadCount: {
            [userID]: 0,
            [myId]: 0,
          },
        });
        navigate(NavRoutes.CHAT, {
          state: { userID, DirectID },
        });
      } else
      {
        navigate(NavRoutes.CHAT, {
          state: { userID, DirectID },
        });
      }
    } catch (error)
    {
      console.log(error);
    }
  }

  const databox = [
    {
      title: t("explore.address"),
      details: data?.address?.slice(0, 150),
    },
    // {
    //   title: " City",
    //   details: "kahabr nathi",
    // },
    {
      title: t("explore.postalcode"),
      details: data?.zip_code,
    },
    {
      title: t("explore.size"),
      details: data?.size,
    },
    {
      title: t("explore.power"),
      details: data?.electricity_type,
    },
  ];

  const redirect = () => {
    toast.warn("Login required.");
    navigate(NavRoutes.LOGIN);
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.main,
        borderRadius: "8px",
        m: 1,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "start",
      }}
    >
      {databox?.map((x, i) => (
        <Box key={i} sx={{}}>
          <Typography
            variant="h6"
            sx={{ color: theme.palette.text.darkOrange, fontSize: "16px" }}
          >
            {x.title}
          </Typography>
          <Typography
            sx={{ color: theme.palette.text.black, fontSize: "15px" }}
          >
            {x.details}
          </Typography>
        </Box>
      ))}



      <Box className="flexCenter" sx={{ width: "100%" }}>
        <BookNowModal company_id={data?.company_id} truck_id={truck_id} />
      </Box>
      <Box className="flexCenter" sx={{ width: "100%" }}>
        <ButtonComponent
          type="button"
          label={t("explore.sendmessage")}
          onClick={() => myId ? handleSendMessage() : redirect()}
          sx={{
            width: { xs: "100%", sm: "60%", md: "100%" },
            border: `1px solid ${theme.palette.background.strip}`,
            fontSize: "16px",
            color: theme.palette.text.main,
          }}
        />
      </Box>
    </Box>
  );
};

export default DetailsSideBar;
